import {
  headersMiddleware,
  overrideMiddleware,
} from '@aspectus/resource'
// import { storage } from '@/utils/session'
// import {
//   LS_ACCESS_TOKEN_STORAGE_NAME,
// } from '@/const'

export const HEADERS_MIDDLEWERE = headersMiddleware(overrideMiddleware({
  'Accept-Language': window.language,
}))
