import {
  createUpdater,
  createReceiver,
  createSender,
  createOptionsGetter,
} from '@/resource/resource'

import {
  prefixAPI,
} from '@/resource/api'

const MODEL = 'export-request'

const EXPORT_REQUEST_LINK = prefixAPI('/create/', MODEL)
const EXPORT_REQUEST_DETAILS_LINK = prefixAPI('/{id}/', MODEL)
const EXPORT_REQUEST_UPDATE_LINK = prefixAPI('/update/{id}/', MODEL)
const EXPORT_REQUEST_CANCEL_LINK = prefixAPI('/cancel/{id}/', MODEL)
const EXPORT_REQUEST_COMPLETE_LINK = prefixAPI('/complete/{id}/', MODEL)
const EXPORT_REQUEST_LIST_LINK = prefixAPI('/list/{?limit,status,offset,id,search,warehouse,export_date_after,export_date_before}', MODEL)

export const exportRequestResource = createSender(EXPORT_REQUEST_LINK)
export const exportRequestUpdateResource = createUpdater(EXPORT_REQUEST_UPDATE_LINK)
export const exportRequestCancelResource = createUpdater(EXPORT_REQUEST_CANCEL_LINK)
export const exportRequestCompleteResource = createUpdater(EXPORT_REQUEST_COMPLETE_LINK)
export const exportRequestListResource = createReceiver(EXPORT_REQUEST_LIST_LINK)
export const exportRequestDetailsResource = createReceiver(EXPORT_REQUEST_DETAILS_LINK)
export const exportRequestListOptionsResource = createOptionsGetter(EXPORT_REQUEST_LIST_LINK)
export const exportRequestUpdateOptionsResource = createOptionsGetter(EXPORT_REQUEST_UPDATE_LINK)
