import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ls-form ls-form--space_none ls-form--modal" }
const _hoisted_2 = { class: "ls-form__title ls-form__title--space-bottom_sm" }
const _hoisted_3 = { class: "ds-caption ds-caption--size_lg ds-caption--medium" }
const _hoisted_4 = { class: "ls-form__wrapper" }
const _hoisted_5 = { class: "error-label" }
const _hoisted_6 = { class: "g-row g-row--appearance_spaced g-row--space_xl-lg g-row--justify_end" }
const _hoisted_7 = { class: "g-cell g-cols g-cols--12" }
const _hoisted_8 = { class: "ds-table-wrapper ds-table-wrapper--overflow_none" }
const _hoisted_9 = { class: "g-cell g-cols g-cols--narrow" }
const _hoisted_10 = {
  class: "ls-button ls-button--main ls-button--green ls-button--full_width",
  type: "submit"
}
const _hoisted_11 = { class: "ls-button__icon" }
const _hoisted_12 = { class: "ls-button__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VeeError = _resolveComponent("VeeError")!
  const _component_d_control_input = _resolveComponent("d-control-input")!
  const _component_ui_table = _resolveComponent("ui-table")!
  const _component_i_save = _resolveComponent("i-save")!
  const _component_VeeForm = _resolveComponent("VeeForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString($setup.actionTypes[$setup.props.action].title), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_VeeForm, { onSubmit: $setup.request }, {
        default: _withCtx(({ errors, validate }) => [
          _createVNode(_component_VeeError, { name: "nonFieldErrors" }, {
            default: _withCtx(({ message }) => [
              _createElementVNode("div", _hoisted_5, _toDisplayString(message), 1)
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                ($setup.object)
                  ? (_openBlock(), _createBlock(_component_ui_table, {
                      key: 0,
                      class: "ds-table--width_full",
                      "table-fields": $setup.rentObjectsTableFields,
                      items: $setup.object.orderObjects
                    }, {
                      "table-body-cell-object": _withCtx(({ item, index }) => [
                        (item.section)
                          ? (_openBlock(), _createBlock(_component_d_control_input, _mergeProps({
                              key: 0,
                              class: $setup.objectField.classes
                            }, $setup.objectField, {
                              key: `object_${index}`,
                              ref: `object_${index}`,
                              name: `object_${index}`,
                              options: $setup.freeObjects[item.type.id] || [],
                              modelValue: item.section,
                              "onUpdate:modelValue": ($event: any) => ((item.section) = $event),
                              onSelect: ($event: any) => ($setup.disableChosenValue($setup.freeObjects[item.type.id]))
                            }), null, 16, ["class", "name", "options", "modelValue", "onUpdate:modelValue", "onSelect"]))
                          : (_openBlock(), _createBlock(_component_d_control_input, _mergeProps({
                              key: 1,
                              class: $setup.objectField.classes
                            }, $setup.objectField, {
                              key: `object_${index}`,
                              ref: `object_${index}`,
                              name: `object_${index}`,
                              options: $setup.freeObjects[item.type.id] || [],
                              modelValue: item.rentObject,
                              "onUpdate:modelValue": ($event: any) => ((item.rentObject) = $event),
                              onSelect: ($event: any) => ($setup.disableChosenValue($setup.freeObjects[item.type.id]))
                            }), null, 16, ["class", "name", "options", "modelValue", "onUpdate:modelValue", "onSelect"]))
                      ]),
                      _: 1
                    }, 8, ["items"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("button", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_i_save)
                ]),
                _createElementVNode("span", _hoisted_12, _toDisplayString($setup.$t('Сохранить')), 1)
              ])
            ])
          ])
        ]),
        _: 1
      })
    ])
  ]))
}