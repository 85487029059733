import { RouteRecordRaw } from 'vue-router';
import { route } from '@/router/utils'

const textPage = () => import('./text-page.vue')

// NAMES
export const TEXT_PAGE_NAME = 'cabinet:terms-and-conditions'

// URLs
export const TEXT_PAGE_URL = ''

export function createRoutes(): RouteRecordRaw[] {
  return [
    route(TEXT_PAGE_URL, textPage, TEXT_PAGE_NAME, {
      meta: {
        breadcrumb: 'text-page',
      }
    }),
  ]
}
