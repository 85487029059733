/* eslint-disable prefer-object-spread */
import { mergeContext, makeF } from '@aspectus/vue-utils';
import PermissionsAccessState from './PermissionsAccessState';

export default makeF(
  (h, context) => h(
    context.props.checker,
    mergeContext(context.data, {
      scopedSlots: {
        default: ({ hasAccess }) => (
          hasAccess ? context.scopedSlots.default({ hasAccess }) : [h(null)]
        ),
      },
    })
  ),
  {
    checker: {
      default: () => PermissionsAccessState,
    },
  },
  'permissions-available'
);
