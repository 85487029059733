import {
  createSender,
  createOptionsGetter,
  createReceiver,
  createUpdater,
} from '@/resource/resource'

import {
  prefixAPI,
} from '@/resource/api'

const MODEL = 'order'
const CLIENTS_MODEL = 'client'
const WAREHOUSE_MODEL = 'warehouse'
const SLOT_MODEL = 'slot'

const ORDER_CREATE_LINK = prefixAPI('/create/', MODEL)
const ORDER_UPDATE_LINK = prefixAPI('/update/{id}/', MODEL)
const ORDER_EXTEND_LINK = prefixAPI('/renew/{id}/', MODEL)
const ORDER_CANCEL_LINK = prefixAPI('/cancel/{id}/', MODEL)
const ORDER_VIEW_LINK = prefixAPI('/{id}/', MODEL)
const ORDER_FIND_LINK = prefixAPI('/find/', MODEL)
const ORDER_ACCEPT_PAYMENT = prefixAPI('/accept-payment/', MODEL)
const ORDER_COUNT_PERIOD_PRICE_LINK = prefixAPI('/count-period-price/', MODEL)
const ORDERS_LIST_LINK = prefixAPI(
  '/list/{?limit,offset,search,warehouse,client,sections,id,payment_status,condition_status,slot_condition_status,slot_arrival_date_after,slot_arrival_date_before,pickup_date}',
  MODEL)

const ORDER_NOTIFICATION_LINK = prefixAPI('/send-notification-mail-to-client/', MODEL)

const CLIENTS_FIND_LINK = prefixAPI('/find/', CLIENTS_MODEL)

const WAREHOUSE_FIND_LINK = prefixAPI('/find/', WAREHOUSE_MODEL)
const WAREHOUSE_FIND_FREE_OBJECTS_LINK = prefixAPI('/find-free-objects/', WAREHOUSE_MODEL)

const SLOT_IMPLEMENT_LINK = prefixAPI('/implement/{id}/', SLOT_MODEL)
// const SLOT_COMPLETE_LINK = prefixAPI('/complete/{id}/', SLOT_MODEL)
const SLOT_CANCEL_LINK = prefixAPI('/cancel/{id}/', SLOT_MODEL)
const PLACE_CARGO_LINK = prefixAPI('/place-cargo/', SLOT_MODEL)
const REPLACE_CARGO_LINK = prefixAPI('/replace-cargo/', SLOT_MODEL)

export const clientsFindResource = createSender(CLIENTS_FIND_LINK)

export const slotImplementResource = createUpdater(SLOT_IMPLEMENT_LINK)
// export const slotCompleteResource = createUpdater(SLOT_COMPLETE_LINK)
export const slotCancelResource = createUpdater(SLOT_CANCEL_LINK)
export const placeSlotResource = createSender(PLACE_CARGO_LINK)
export const replaceSlotResource = createSender(REPLACE_CARGO_LINK)

export const warehouseFindResource = createSender(WAREHOUSE_FIND_LINK)
export const warehouseFindFreeObjectsResource = createSender(WAREHOUSE_FIND_FREE_OBJECTS_LINK)

export const orderCreateResource = createSender(ORDER_CREATE_LINK)
export const orderUpdateResource = createUpdater(ORDER_UPDATE_LINK)
export const orderExtendResource = createSender(ORDER_EXTEND_LINK)
export const orderAcceptPayment = createSender(ORDER_ACCEPT_PAYMENT)
export const orderCancelResource = createUpdater(ORDER_CANCEL_LINK)
export const orderFindResource = createSender(ORDER_FIND_LINK)
export const orderCountPeriodResource = createSender(ORDER_COUNT_PERIOD_PRICE_LINK)
export const orderOptionsResource = createOptionsGetter(ORDER_CREATE_LINK)
export const orderExtendOptionsResource = createOptionsGetter(ORDER_EXTEND_LINK)
export const ordersListResource = createReceiver(ORDERS_LIST_LINK)
export const orderViewResource = createReceiver(ORDER_VIEW_LINK)
export const ordersListOptionsResource = createOptionsGetter(ORDERS_LIST_LINK)

export const orderNotificationResource = createSender(ORDER_NOTIFICATION_LINK)
