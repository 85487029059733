<template>
<svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.5 11H2.25V16.25H7.5V11Z" stroke="#5F7D95" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.5 2.75H2.25V8H7.5V2.75Z" stroke="#5F7D95" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.5 3.5H15.75" stroke="#5F7D95" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.5 7.25H15.75" stroke="#5F7D95" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.5 11.75H15.75" stroke="#5F7D95" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.5 15.5H15.75" stroke="#5F7D95" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>