import { RouteRecordRaw } from 'vue-router';
import { route } from '@/router/utils'
import { accessPermission } from '@access/permissions'
import { allPermissions } from '@access/index'

const {
  STAFF: {
    TRANSACTION: {
      VIEW_LIST
    },
  },
} = allPermissions

const transactionsList = () => import('../transactions/views/List.vue')

// NAMES
export const CLIENT_TRANSACTIONS_LIST_PAGE_NAME = 'cabinet:client-transactions-list'
export const CLIENT_CASH_TRANSACTIONS_LIST_PAGE_NAME = 'cabinet:client-cash-transactions-list'

// URLs
export const CLIENT_TRANSACTIONS_LIST_PAGE_URL = 'all/'
export const CLIENT_CASH_TRANSACTIONS_LIST_PAGE_URL = 'cash/'

export function createRoutes(): RouteRecordRaw[] {
  return [
    route(CLIENT_TRANSACTIONS_LIST_PAGE_URL, transactionsList, CLIENT_TRANSACTIONS_LIST_PAGE_NAME, {
      meta: {
        breadcrumb: 'transactions',
        cash: false,
        ...accessPermission(VIEW_LIST)
      }
    }),
    route(CLIENT_CASH_TRANSACTIONS_LIST_PAGE_URL, transactionsList, CLIENT_CASH_TRANSACTIONS_LIST_PAGE_NAME, {
      meta: {
        breadcrumb: 'cash-transactions',
        cash: true,
        ...accessPermission(VIEW_LIST)
      }
    }),
  ]
}
