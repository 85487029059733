import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "ls-action-bar-wrapper" }
const _hoisted_2 = { class: "g-row g-row--space_sm g-row--space_xl-xl g-row--appearance_spaced g-row--justify_center" }
const _hoisted_3 = ["id", "onClick"]
const _hoisted_4 = { class: "ls-action-bar-element__pop-up" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_main_button = _resolveComponent("main-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.barData, (item) => {
        return (_openBlock(), _createElementBlock(_Fragment, null, [
          (item.condition && item.condition())
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["g-cell g-cols", $setup.isClient ? 'g-cols--12' : 'g-cols--narrow'])
              }, [
                ($setup.isClient)
                  ? (_openBlock(), _createBlock(_component_main_button, {
                      key: 0,
                      class: "ls-button ls-button--main ls-button--green-trans ls-button--trans ls-button--full_width",
                      onClick: ($event: any) => ($setup.currentAction(item))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.title), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
                  : (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      class: _normalizeClass(["ls-action-bar-element-wrapper", {"action-bar-transparent" : item.hidden && item.hidden()}])
                    }, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["ls-action-bar-element", item.action == 'cancel' ? 'dark' : item.action == 'qr_simple' ? 'accent' : '']),
                        id: item.action,
                        onClick: ($event: any) => (item.hidden && item.hidden() ? () => {} : $setup.currentAction(item))
                      }, [
                        _createElementVNode("div", {
                          class: _normalizeClass(["ls-action-bar-element__icon", item.action == 'cancel' ? 'dark' : item.action == 'qr_simple' ? 'accent' : ''])
                        }, [
                          (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon), { class: "button_img" }))
                        ], 2),
                        _createElementVNode("div", _hoisted_4, _toDisplayString(item.title), 1)
                      ], 10, _hoisted_3)
                    ], 2))
              ], 2))
            : _createCommentVNode("", true)
        ], 64))
      }), 256))
    ])
  ]))
}