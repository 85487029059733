import {
  createSender,
} from '@/resource/resource'

import {
  prefixAPI,
} from '@/resource/api'

const MODEL = 'insurance'

const INSURANCE_CREATE_LINK = prefixAPI('/create/', MODEL)
const INSURANCE_DETAILS_LINK = prefixAPI('/{id}/', MODEL)
const INSURANCE_UPDATE_LINK = prefixAPI('/update/{id}/', MODEL)
const INSURANCE_DELETE_LINK = prefixAPI('/delete/{id}/', MODEL)
const INSURANCE_LIST_LINK = prefixAPI('/list/{?limit,offset,id}', MODEL)

// export const HIJACK_LINK = prefixAPI('hijack/{id}/')
export const HIJACK_LINK = 'hijack/{id}/'

export const hiJackResource = createSender(HIJACK_LINK)