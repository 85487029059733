import {
  createSender,
} from '@/resource/resource'

import {
  prefixAPI,
  API_BASE_URL,
} from '@/resource/api'

const SIMPLE_MODEL = 'auth'
const MODEL = 'auth/token'
const PASSWORD_MODEL = 'auth/password'

// const AUTHENTICATE_LINK = prefixAPI('/login/', MODEL)

const REFRESH_TOKEN_LINK = prefixAPI('/refresh/', MODEL)
const OBTAIN_LINK = prefixAPI('/obtain/', MODEL)
const RECOVER_LINK = prefixAPI('/recover/', PASSWORD_MODEL)
const PASSWORD_SET_LINK = prefixAPI('/set/', PASSWORD_MODEL)
const PASSWORD_CHANGE_LINK = prefixAPI('/change/', PASSWORD_MODEL)

const LOGOUT_LINK = prefixAPI('/logout/', SIMPLE_MODEL)

export const refreshToken = createSender(REFRESH_TOKEN_LINK)
export const authResource = createSender(OBTAIN_LINK)
export const logoutResource = createSender(LOGOUT_LINK)
export const passwordReset = createSender(RECOVER_LINK)
export const passwordChange = createSender(PASSWORD_CHANGE_LINK)
export const newPasswordSet = createSender(PASSWORD_SET_LINK)
