import { createApp, App } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import { createPinia } from 'pinia'
import { createApplication } from '@/app'
import { 
  createHashlink,
  checkDescriptionBlockHeight,
  addBlockWrapper,
  changeAllArchorLinks
} from '@utils/helpers'
// import 'vue-toast-notification/dist/theme-sugar.css';
import './styles/index.sass'


export const app = createApplication({
  createApp, createRouter, createWebHistory, createPinia,
})

window.app = app

const { $t } = app.config.globalProperties
declare global {
  interface Window {
    USER_PERMISSIONS: any
    ALL_PERMISSIONS: any
    app: App
    googleAutocompleteAppended: boolean
  }
}

document.addEventListener("DOMContentLoaded", function() {
  changeAllArchorLinks()
  // createHashlink('tariff-try-button', 'request')
  checkDescriptionBlockHeight()
  addBlockWrapper('js-about-company', 'table', 'table-wrapper')
})