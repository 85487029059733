import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createBlock as _createBlock, normalizeClass as _normalizeClass, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "ls-form ls-form--space_none ls-form--modal" }
const _hoisted_2 = { class: "ls-form__title ls-form__title--space-bottom_sm" }
const _hoisted_3 = { class: "ds-caption ds-caption--size_lg ds-caption--medium" }
const _hoisted_4 = { class: "ls-form__wrapper" }
const _hoisted_5 = { class: "error-label" }
const _hoisted_6 = { class: "g-row g-row--appearance_spaced g-row--space_sm g-row--space_xl-xl" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "ls-button__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VeeError = _resolveComponent("VeeError")!
  const _component_d_control_input = _resolveComponent("d-control-input")!
  const _component_order_pay_modal = _resolveComponent("order-pay-modal")!
  const _component_VeeForm = _resolveComponent("VeeForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString($setup.$t('Deposit')), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_VeeForm, { onSubmit: $setup.request }, {
        default: _withCtx(({ errors, validate }) => [
          _createVNode(_component_VeeError, { name: "nonFieldErrors" }, {
            default: _withCtx(({ message }) => [
              _createElementVNode("div", _hoisted_5, _toDisplayString(message), 1)
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", {
              class: _normalizeClass(["g-cell g-cols g-cols--12", {'disabled' : $setup.paymentCreated}])
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.fields, (field) => {
                return (_openBlock(), _createBlock(_component_d_control_input, _mergeProps({
                  key: field.name,
                  class: field.classes || '',
                  component: field.component,
                  "input-label": field.label,
                  ref_for: true
                }, field, {
                  ref_for: true,
                  ref: field.name,
                  modelValue: $setup.form[field.name],
                  "onUpdate:modelValue": ($event: any) => (($setup.form[field.name]) = $event),
                  placeholder: field.placeholder || '',
                  readonly: $setup.paymentCreated
                }), null, 16, ["class", "component", "input-label", "modelValue", "onUpdate:modelValue", "placeholder", "readonly"]))
              }), 128))
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["g-cell g-cols g-cols--12", {'disabled' : !$setup.form.amount}])
            }, [
              ($setup.clientId)
                ? (_openBlock(), _createBlock(_component_order_pay_modal, {
                    key: 0,
                    ref: 'orderPayRef',
                    order: $setup.order,
                    "is-deposit": true,
                    "is-client": $setup.isClient,
                    price: $setup.form.amount,
                    "client-id": $setup.clientId,
                    promise: $setup.props.promise,
                    close: $setup.props.close
                  }, {
                    default: _withCtx(({ getPay, payValidate }) => [
                      _createElementVNode("button", {
                        class: "ls-button ls-button--main ls-button--green ls-button--full_width",
                        onClick: _withModifiers(($event: any) => ($setup.makeDeposit(getPay, validate, payValidate, $setup.request)), ["prevent"])
                      }, [
                        _createElementVNode("span", _hoisted_8, _toDisplayString($setup.$t('Оплатить')), 1)
                      ], 8, _hoisted_7)
                    ]),
                    _: 2
                  }, 1032, ["order", "is-client", "price", "client-id", "promise", "close"]))
                : _createCommentVNode("", true)
            ], 2)
          ])
        ]),
        _: 1
      })
    ])
  ]))
}