import { App } from 'vue'
import FileUploader from './FileUploader.vue'

const register = (app: App<Element>): void => {
  app
  .component('FileUploader', FileUploader)
}

export default {
  register,
}
