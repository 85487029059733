import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "ls-form ls-form--space_none ls-form--modal" }
const _hoisted_2 = { class: "ls-form__title ls-form__title--space-bottom_sm" }
const _hoisted_3 = { class: "ds-caption ds-caption--size_lg ds-caption--medium" }
const _hoisted_4 = { class: "ls-form__wrapper" }
const _hoisted_5 = { class: "g-row g-row--appearance_spaced g-row--space_2xl-lg g-row--justify_center g-row--justify_between-sm" }
const _hoisted_6 = { class: "g-cell g-cols g-cols--12" }
const _hoisted_7 = { class: "client-selector" }
const _hoisted_8 = {
  key: 0,
  class: "client-selector__decor"
}
const _hoisted_9 = { class: "client-selector__element" }
const _hoisted_10 = { class: "client-selector__element-title" }
const _hoisted_11 = { class: "ds-caption ds-caption--size_2xs ds-caption--bold" }
const _hoisted_12 = { class: "client-selector__element-value" }
const _hoisted_13 = { class: "ds-caption ds-caption--medium" }
const _hoisted_14 = { class: "g-cell g-cols g-cols--6 g-cols--narrow-sm" }
const _hoisted_15 = { class: "ls-button__text" }
const _hoisted_16 = { class: "g-cell g-cols g-cols--6 g-cols--narrow-sm" }
const _hoisted_17 = { class: "ls-button__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString($setup.$t('Клиент')), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.titles, (title, key, index) => {
              return (_openBlock(), _createElementBlock(_Fragment, null, [
                (index !== 0 && index !== $setup.titles.length + 1)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("p", _hoisted_11, _toDisplayString(title), 1)
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("p", _hoisted_13, _toDisplayString($setup.props.client[key]), 1)
                  ])
                ])
              ], 64))
            }), 64))
          ])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("button", {
            class: "ls-button ls-button--main ls-button--grey-trans",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => ($setup.send(false)), ["prevent"]))
          }, [
            _createElementVNode("span", _hoisted_15, _toDisplayString($setup.$t('Отмена')), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("button", {
            class: "ls-button ls-button--main ls-button--green",
            onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => ($setup.send(true)), ["prevent"]))
          }, [
            _createElementVNode("span", _hoisted_17, _toDisplayString($setup.$t('Выбрать')), 1)
          ])
        ])
      ])
    ])
  ]))
}