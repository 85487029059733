import {
  createReceiver,
  createUpdater,
  createSender,
  createRemover,
  createOptionsGetter,
} from '@/resource/resource'

import {
  prefixAPI,
} from '@/resource/api'

const MODEL = 'company'

const COMPANY_DETAIL_LINK = prefixAPI('/detail/', MODEL)
const COMPANY_AUTH_CREDENTIALS_CREATE = prefixAPI('/auth-credentials/create/', MODEL)
const COMPANY_AUTH_CREDENTIALS_DELETE = prefixAPI('/auth-credentials/delete/{id}/', MODEL)
export const COMPANY_UPDATE_LINK = prefixAPI('/update/', MODEL)

export const getCompanyDetail = createReceiver(COMPANY_DETAIL_LINK)
export const updateCompanyDetail = createUpdater(COMPANY_UPDATE_LINK)
export const companyAuthCredentialsCreate = createSender(COMPANY_AUTH_CREDENTIALS_CREATE)
export const companyAuthCredentialsDelete = createRemover(COMPANY_AUTH_CREDENTIALS_DELETE)

const ALL_COMPANIES_LIST_LINK = prefixAPI('/list/{?limit,offset,status,tariff_plan}', MODEL)
const USER_COMPANY_DETAILS_LINK = prefixAPI('/{id}/', MODEL)
const USER_COMPANY_CREATE_LINK = prefixAPI('/create/', MODEL)
export const USER_COMPANY_EDIT_LINK = prefixAPI('/update/{id}/', MODEL)

export const getAllCompaniesList = createReceiver(ALL_COMPANIES_LIST_LINK)
export const getAllCompaniesListOptions = createOptionsGetter(ALL_COMPANIES_LIST_LINK)
export const getUserCompanyDetail = createReceiver(USER_COMPANY_DETAILS_LINK)
export const userCompanyCreate = createSender(USER_COMPANY_CREATE_LINK)
export const userCompanyEdit = createUpdater(USER_COMPANY_EDIT_LINK)

export const getCompanyLogoUpdateLink = (companyId) => {
  const LINK = prefixAPI(`/update/${companyId}/`, MODEL)
  return LINK
}