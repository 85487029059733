/* eslint-disable */
import {
  prefixLanguage,
} from '@/utils/urls'

// префикс апи
export const API_BASE_URL = '/api/v1/'

// export const API_EXTERNAL_URL = 'https://hq-vpn-main.webcase-dev.com/api/v2/'
// export const API_EXTERNAL_URL = window.externalApi

export const prefixAPI = (url, model = '', base = API_BASE_URL) => base + model + url
