import type { Router } from 'vue-router'
import { defineStore } from '@/modules/pinia-hot'

import {
  notificationsCountResource,
  notificationsListResource,
} from '@/services/notifications.service'

import executeWithRefresh from '@/modules/refresher'

const id = 'notifications'

export const useNotifications = defineStore(id, {
  state: () => ({
    unreadCount: '',
    allList: '',
  }),
  getters: {
  },
  actions: {
    async getUnread() {
      const data = (await executeWithRefresh(notificationsCountResource))
      this.unreadCount = data[0]?.flags['73232']
    },
  },
})
