export const LS_ACCESS_TOKEN_STORAGE_NAME = 'LS:ACCESS_TOKEN_STORAGE'
export const LS_REFRESH_TOKEN_STORAGE_NAME = 'LS:REFRESH_TOKEN_STORAGE'
export const LS_DEVICE_UUID_STORAGE_NAME = 'LS:DEVICE_UUID_STORAGE'

export const LS_LANGUAGE_COOKIE_NAME = 'LS:CHOSEN_LANGUAGE'

export const LS_CURRENT_PAYMENT_ID_NAME = 'LS:CURRENT_PAYMENT_ID'
export const LS_CURRENT_PAYMENT_URL_NAME = 'LS:CURRENT_PAYMENT_URL'

export const LS_USER_SAVED_NAME = 'LS:USER_SAVED_EMAIL'
export const LS_IS_AUTH_STORAGE_NAME = 'LS:IS_AUTH_STORAGE_NAME'

export const LS_WAREHOUSE_ID_STORAGE_NAME = 'LS:WAREHOUSE_ID'
export const LS_WAREHOUSE_TYPE_STORAGE_NAME = 'LS:WAREHOUSE_TYPE'

