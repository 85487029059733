import {
  createSender,
  createRemover,
  createReceiver,
  createUpdater,
} from '@/resource/resource'

import {
  prefixAPI,
} from '@/resource/api'

const MODEL = 'external-api/action'

const ACTION_CREATE_LINK = prefixAPI('/create/', MODEL)
const ACTIONS_LIST_LINK = prefixAPI('/list/{?limit,offset}', MODEL)
const ACTION_DETAILS_LINK = prefixAPI('/{id}/', MODEL)
const ACTION_UPDATE_LINK = prefixAPI('/update/{id}/', MODEL)
const ACTION_DELETE_LINK = prefixAPI('/delete/{id}/', MODEL)
const ACTION_SEND_DATA_LINK = prefixAPI('/send-data/', MODEL)

export const actionCreateResource = createSender(ACTION_CREATE_LINK)
export const actionSendDataResource = createSender(ACTION_SEND_DATA_LINK)
export const actionsListResource = createReceiver(ACTIONS_LIST_LINK)
export const actionDetailsResource = createReceiver(ACTION_DETAILS_LINK)
export const actionUpdateResource = createUpdater(ACTION_UPDATE_LINK)
export const actionDeleteResource = createRemover(ACTION_DELETE_LINK)
