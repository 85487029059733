import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "ls-form ls-form--demo-request" }
const _hoisted_2 = { class: "ls-form__title ls-form__title--space-bottom_sm" }
const _hoisted_3 = { class: "ds-caption ds-caption--size_lg ds-caption--medium" }
const _hoisted_4 = { class: "ls-form__wrapper" }
const _hoisted_5 = { class: "g-row g-row--appearance_spaced g-row--space_md g-row--space_2xl-xl g-row--justify_end g-row--align_center" }
const _hoisted_6 = { class: "error-label" }
const _hoisted_7 = { class: "g-cell g-cols g-cols--12 g-cols--6-lg" }
const _hoisted_8 = { class: "ls-button__text" }
const _hoisted_9 = {
  key: 0,
  class: "g-cell g-cols g-cols--12"
}
const _hoisted_10 = { class: "ds-panel ds-panel--space_sm ds-panel--space_2xl-lg" }
const _hoisted_11 = { class: "ds-panel__element ds-panel__element--offset_bottom" }
const _hoisted_12 = { class: "ds-panel ds-panel--space_sm ds-panel--space_lg-lg" }
const _hoisted_13 = { class: "ds-panel__element ds-panel__element--offset_top" }
const _hoisted_14 = { class: "done-icon-wrapper" }
const _hoisted_15 = { class: "done-icon-wrapper__image" }
const _hoisted_16 = { class: "ds-panel__element ds-panel__element--offset_top" }
const _hoisted_17 = { class: "ds-caption ds-caption--bold ds-caption--lh_1 ds-caption--appearance_center" }
const _hoisted_18 = { class: "ds-panel__element ds-panel__element--offset_top" }
const _hoisted_19 = { class: "ds-caption ds-caption--lh_1 ds-caption--appearance_center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VeeError = _resolveComponent("VeeError")!
  const _component_d_control_input = _resolveComponent("d-control-input")!
  const _component_i_done = _resolveComponent("i-done")!
  const _component_VeeForm = _resolveComponent("VeeForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString($setup.$t('Request demo')), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_VeeForm, {
        onSubmit: $setup.request,
        ref: "formRef"
      }, {
        default: _withCtx(({ errors, validator, resetForm }) => [
          _createElementVNode("div", _hoisted_5, [
            (!_ctx.isSended)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(_component_VeeError, { name: "nonFieldErrors" }, {
                    default: _withCtx(({ message }) => [
                      _createElementVNode("div", _hoisted_6, _toDisplayString(message), 1)
                    ]),
                    _: 1
                  }),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.fields, (field) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: field.name,
                      class: _normalizeClass(["g-cell g-cols", field.cols])
                    }, [
                      _createVNode(_component_d_control_input, _mergeProps({
                        class: field.classes || '',
                        component: field.component,
                        "input-label": field.label,
                        ref_for: true
                      }, field, {
                        ref_for: true,
                        ref: field.name,
                        modelValue: $setup.form[field.name],
                        "onUpdate:modelValue": ($event: any) => (($setup.form[field.name]) = $event),
                        placeholder: field.placeholder || '',
                        "internal-search": "false",
                        onOpen: ($event: any) => ($setup.getCountriesList(field)),
                        onSearchChange: ($event: any) => ($setup.getSearche($event, field))
                      }), null, 16, ["class", "component", "input-label", "modelValue", "onUpdate:modelValue", "placeholder", "onOpen", "onSearchChange"])
                    ], 2))
                  }), 128)),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("button", {
                      class: _normalizeClass(["ls-button ls-button--main ls-button--green ls-button--full_width ls-button--height_sm", {'disabled' : $setup.isLoad}]),
                      type: "submit"
                    }, [
                      _createElementVNode("span", _hoisted_8, _toDisplayString($setup.$t('SEND')), 1)
                    ], 2)
                  ]),
                  (_ctx.isSended)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _createElementVNode("div", _hoisted_10, [
                          _createElementVNode("div", _hoisted_11, [
                            _createElementVNode("div", _hoisted_12, [
                              _createElementVNode("div", _hoisted_13, [
                                _createElementVNode("div", _hoisted_14, [
                                  _createElementVNode("div", _hoisted_15, [
                                    _createVNode(_component_i_done)
                                  ])
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_16, [
                                _createElementVNode("p", _hoisted_17, _toDisplayString($setup.$t('Ваш заказ успешно создан.')), 1)
                              ]),
                              _createElementVNode("div", _hoisted_18, [
                                _createElementVNode("p", _hoisted_19, _toDisplayString($setup.$t('В ближайшее время наш менеджер свяжеться с Вами.')), 1)
                              ])
                            ])
                          ])
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ], 64))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 512)
    ])
  ]))
}