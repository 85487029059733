import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "ls-form ls-form--space_none ls-form--modal" }
const _hoisted_2 = { class: "ls-form__title ls-form__title--space-bottom_sm" }
const _hoisted_3 = { class: "ds-caption ds-caption--size_lg ds-caption--medium" }
const _hoisted_4 = { class: "ls-form__wrapper" }
const _hoisted_5 = { class: "g-row g-row--appearance_spaced g-row--space_sm g-row--space_lg-xl g-row--justify_end g-row--align_center" }
const _hoisted_6 = { class: "error-label" }
const _hoisted_7 = { class: "g-cell g-cols g-cols--12" }
const _hoisted_8 = {
  class: "ls-button ls-button--main ls-button--green ls-button--full_width",
  type: "submit"
}
const _hoisted_9 = { class: "ls-button__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VeeError = _resolveComponent("VeeError")!
  const _component_d_control_input = _resolveComponent("d-control-input")!
  const _component_VeeForm = _resolveComponent("VeeForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString($setup.$t('Добавление транзакции')), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_VeeForm, { onSubmit: $setup.request }, {
        default: _withCtx(({ errors }) => [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_VeeError, { name: "nonFieldErrors" }, {
              default: _withCtx(({ message }) => [
                _createElementVNode("div", _hoisted_6, _toDisplayString(message), 1)
              ]),
              _: 1
            }),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.fields, (field, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["g-cell g-cols g-cols--12", field.cols]),
                key: `${field.name}_of_row_${index}`
              }, [
                (_openBlock(), _createBlock(_component_d_control_input, _mergeProps({
                  class: field.class || '',
                  "input-label": field.label,
                  modelValue: $setup.form[field.name],
                  "onUpdate:modelValue": ($event: any) => (($setup.form[field.name]) = $event),
                  ref_for: true
                }, field, {
                  key: `${field.name}_${index}`,
                  ref_for: true,
                  ref: `${field.name}_${index}`,
                  name: `${field.name}_${index}`
                }), null, 16, ["class", "input-label", "modelValue", "onUpdate:modelValue", "name"]))
              ], 2))
            }), 128)),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("button", _hoisted_8, [
                _createElementVNode("span", _hoisted_9, _toDisplayString($setup.$t('Создать')), 1)
              ])
            ])
          ])
        ]),
        _: 1
      })
    ])
  ]))
}