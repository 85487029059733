import {
  createSender,
  createOptionsGetter,
  createReceiver,
} from '@/resource/resource'

import {
  prefixAPI,
} from '@/resource/api'

const MODEL = 'rent-period'

const RENT_PERIOD_CREATE_LINK = prefixAPI('/create/', MODEL)
const RENT_PERIOD_DELETE_LINK = prefixAPI('/deactivate/', MODEL)
const RENT_PERIODS_LIST_LINK = prefixAPI('/list/{?limit,offset,type,search}', MODEL)

export const rentPeriodsCreateResource = createSender(RENT_PERIOD_CREATE_LINK)
export const rentPeriodDeleteResource = createSender(RENT_PERIOD_DELETE_LINK)
export const rentPeriodsListResource = createReceiver(RENT_PERIODS_LIST_LINK)
export const rentPeriodsOptionsResource = createOptionsGetter(RENT_PERIOD_CREATE_LINK)
