<template>
<svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6 5H15.75" stroke="#5F7D95" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6 9.5H15.75" stroke="#5F7D95" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6 14H15.75" stroke="#5F7D95" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2.25 5H2.25667" stroke="#5F7D95" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2.25 9.5H2.25667" stroke="#5F7D95" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2.25 14H2.25667" stroke="#5F7D95" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>