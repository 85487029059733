<template>
  <div class="date-container">
    <div class="month-year">
      <button class="month-button month-button-prve" @click.stop.prevent="changeMonth(-1)"><i class="month-arrow month-arrow-prev"></i></button>
      <span class="calendar-month">{{dayOfMonth.format('MMMM')}} {{dayOfMonth.format('YYYY')}}</span>
      <span style="font-size:14px;" v-if="isShowLunarButton">
        <input type="checkbox" class="checkbox" v-model="isLunar" id="show-lunar">
        <label for="show-lunar" class="check-lunar">{{trLunar}}</label>
      </span>
      <button class="month-button month-button-next" @click.stop.prevent="changeMonth(1)"><i class="month-arrow month-arrow-next"></i></button>
    </div>
    <div class="week-days">
      <div class="week-days-wrapper" v-for="day in weekDays" :key="day.id">
        <div class="week-days-item">
          <p>
            {{day}}
          </p>
        </div>
      </div>
    </div>
    <div class="days">
      <day-cell
        v-for="(day, index) in days"
        :key="`${day} ${index}`"
        :showLunar="isLunar"
        :isSelected="isSelected(day)"
        :day="day"
        :lang="lan"
        :cellSeletedClass="cellSeletedClass"
        :color="customCellsData && hasCustomCell(day, 'className')"
        :cellGroupName="customCellsData && hasCustomCell(day, 'groupName')"
        @dayClick="handleDayClick"
      ></day-cell>
    </div>
  </div>
</template>
<script type="text/ecmascript-6">
  import moment from 'moment'
  import DayCell from './DayCell.vue'
  import Transformer from './lunar'
  import Translation from './lang'

  export default {
    name: 'lunar-calendar',
    components: { DayCell },
    props: {
      firstDayOfWeek: {
        type: Number,
        default: function () {
          return moment.localeData().firstDayOfWeek()
        }
      },
      disableDaysBeforeToday: {
        type: Boolean
      },
      disabledFunc: {
        type: Function,
        default: null
      },
      defaultDate: {
        type: String
      },
      showLunar: {
        type:Boolean,
        default: false
      },
      showLunarButton: {
        type:Boolean,
        default: true
      },
      lang: {
        type: String,
        default: 'ko'
      },
      dateLang: {
        type: String,
        default: 'en'
      },
      cellSeletedClass: {
        type: String,
      },
      customCells: {
        type: Array,
      },
    },
    mixins: [Transformer],
    data () {
      return {
        weekDays: [],
        days: [],
        dayOfMonth: moment(),
        date: this.defaultDate ? moment(this.defaultDate, 'YYYY-MM-DD') : moment(),
        lunarDate: null,
        isLunar: this.showLunar,
        isShowLunarButton: this.showLunarButton,
        lan: this.lang,
        dateLan: this.dateLang,
        trLunar: Translation.translations[this.lang].lunar,
        trDate: Translation.translations[this.dateLang].days,
        customCellsData: this.customCells,
        cellGroupName: '',
      }
    },
    watch: {
      customCells(val) {
        this.customCellsData = val
      },
      defaultDate (val) {
        this.date = moment(val, 'YYYY-MM-DD')
        this.lunarDate = moment(this.solar2lunar(this.date._d).day, 'YYYY-MM-DD')
        this.resetDayOfMonth()
        this.$emit('change', this.date, this.lunarDate, this.isLunar)
      },
      showLunarButton (val) {
        this.isShowLunarButton = val
      },
      lang (val) {
        this.lan = val
        this.trLunar = Translation.translations[this.lan].lunar
      },
      dateLang (val) {
        this.dateLan = val
        this.trDate = Translation.translations[this.dateLan].days
        this.initWeekDays()
      },
      isLunar (val) {
        this.$emit('change', this.date, this.lunarDate, val)
      }
    },
    created () {
      this.initWeekDays()
      this.initDays()
      this.resetDayOfMonth()
      this.lunarDate = moment(this.solar2lunar(this.date._d).day, 'YYYY-MM-DD')
    },
    methods: {
      hasCustomCell (day, type) {
        const result = this.customCellsData.map((cell) => {
          const parseCell = Object.assign(cell)
          const formatDate = day.dayMoment.format('YYYY-MM-DD')

          if (parseCell.days.includes(formatDate)) {
            if (type === "className" && parseCell.color) {
              return parseCell.color
            } else if (type === "groupName" && parseCell.groupName) {
              return parseCell.groupName
            }
          } else {
            return
          }
        });
        return result.join(" ");
      },
      resetDayOfMonth () {
        if (this.date.format('YYYY-MM') !== this.dayOfMonth.format('YYYY-MM')) {
          let _diff = Number(this.date.diff(this.dayOfMonth, 'months'))
          _diff = _diff <= 0 ? _diff - 1 : _diff
          this.dayOfMonth.add(_diff, 'months')
          this.initDays()
        }
      },
      initWeekDays () {
        const days = this.trDate
        const dow = this.firstDayOfWeek
        this.weekDays = []
        for (let i = dow; i < 7 + dow; i++) {
          let day = i % 7
          this.weekDays.push(days[day])
        }
      },
      initDays () {
        this.days = []
        const firstDayOfWeek = this.firstDayOfWeek
        const startOfMonth = this.dayOfMonth.startOf('month').isoWeekday()
        const monthNumber = this.dayOfMonth.month()
        const dayCount = this.dayOfMonth.daysInMonth()
        const lastMonth = this.dayOfMonth.clone().month(monthNumber - 1)
        const lastMonthDayCount = lastMonth.daysInMonth()
        const nextMonth = this.dayOfMonth.clone().month(monthNumber + 1)
        const diff = (Math.abs(firstDayOfWeek - (startOfMonth + 7)) % 7)
        for (let i = diff - 1; i >= 0; i--) {
          const dayMoment = lastMonth.clone().date(lastMonthDayCount - i)
          this.days.push({dayMoment, isPassive: true})
        }
        for (let i = 1; i <= dayCount; i++) {
          const dayMoment = this.dayOfMonth.clone().date(i)
          let isPassive = this.isPassive(dayMoment)
          this.days.push({ dayMoment, isPassive })
        }
        const remainingCells = 42 - this.days.length // 42cells = 7days * 6rows
        for (let i = 1; i <= remainingCells; i++) {
          const dayMoment = nextMonth.clone().date(i)
          this.days.push({ dayMoment, isPassive: true })
        }
      },
      isSelected (day) {
        if (!day.dayMoment) return
        return day.dayMoment.format('YYYY-MM-DD') === this.date.format('YYYY-MM-DD')
      },
      isPassive (dayMoment) {
        let {disableDaysBeforeToday, disabledFunc} = this
        if (disabledFunc) { return disabledFunc(dayMoment) }
        let _today = moment()
        if (disableDaysBeforeToday && Number(dayMoment.diff(_today, 'days')) <= -1) {
          return true
        }
      },
      handleDayClick (day, lunar) {
        this.date = day.dayMoment
        let lunarDate = moment(lunar, "YYYY-MM-DD")
        this.lunarDate = lunarDate
        this.$emit('change', day.dayMoment, lunarDate, this.isLunar)
      },
      changeMonth (delta) {
        this.dayOfMonth.add(delta, 'months')
        this.customCellsData = []
        this.initDays ()
        this.$emit('changeMonth', this.dayOfMonth)
      }
    },
    mounted() {
      this.$emit('change', moment())
      // this.$emit('changeMonth', this.dayOfMonth)
    },
  }
</script>