import { RouteRecordRaw } from 'vue-router';
import { route } from '@/router/utils'
import { allPermissions } from '@access/index'
import { accessPermission } from '@access/permissions'

const clientList = () => import('./views/List.vue')
const clientDetail = () => import('./views/details/Details.vue')
const clientCreate = () => import('./views/Create.vue')


// NAMES
export const CLIENTS_LIST_PAGE_NAME = 'cabinet:clients-list'
export const CLIENT_CREATE_PAGE_NAME = 'cabinet:clients-create'
export const CLIENT_UPDATE_PAGE_NAME = 'cabinet:clients-update'
export const CLIENT_VIEW_PAGE_NAME = 'cabinet:client-detail'

// URLs
export const CLIENTS_UPDATE_PAGE_URL = 'edit/:id/'
export const CLIENTS_LIST_PAGE_URL = ''
export const CLIENT_CREATE_PAGE_URL = 'create/'
export const CLIENT_VIEW_PAGE_URL = 'view/:id/'

const {
  STAFF: {
    CLIENT: {
      VIEW,
      CREATE,
      VIEW_LIST,
      EDIT,
    },
  },
} = allPermissions

export function createRoutes(): RouteRecordRaw[] {
  return [
    route(CLIENTS_LIST_PAGE_URL, clientList, CLIENTS_LIST_PAGE_NAME, {
      meta: {
        breadcrumb: 'clients',
        ...accessPermission(VIEW_LIST)
      }
    }),
    route(CLIENT_CREATE_PAGE_URL, clientCreate, CLIENT_CREATE_PAGE_NAME, {
      meta: {
        editable: true,
        isNew: true,
        breadcrumb: 'client-create',
        ...accessPermission(CREATE)
      },
    }),
    route(CLIENTS_UPDATE_PAGE_URL, clientCreate, CLIENT_UPDATE_PAGE_NAME, {
      meta: {
        editable: true,
        isNew: false,
        breadcrumb: 'client-update',
        ...accessPermission(EDIT)
      },
    }),
    route(CLIENT_VIEW_PAGE_URL, clientDetail, CLIENT_VIEW_PAGE_NAME, {
      meta: {
        breadcrumb: 'clients',
        redirect: CLIENTS_LIST_PAGE_NAME,
        ...accessPermission(VIEW)
      },
    }),
  ]
}
