import { RouteRecordRaw } from 'vue-router';
import { route } from '@/router/utils'
import { allPermissions } from '@access/index'

const ordersList = () => import('../orders/views/List.vue')
const orderDetails = () => import('../orders/views/Details.vue')
import { accessPermission } from '@access/permissions'

// NAMES
export const CLIENT_ORDERS_LIST_PAGE_NAME = 'cabinet:client-orders-list'
export const CLIENT_ORDER_VIEW_PAGE_NAME = 'cabinet:client-order'

// URLs
export const CLIENT_ORDERS_LIST_PAGE_URL = ':date?/list/'
export const CLIENT_ORDER_VIEW_PAGE_URL = 'view/:id'

const {
  STAFF: {
    ORDER: {
      VIEW: ORDER_VIEW,
      CREATE: ORDER_CREATE,
    },
  },
} = allPermissions

export function createRoutes(): RouteRecordRaw[] {
  return [
    route(CLIENT_ORDERS_LIST_PAGE_URL, ordersList, CLIENT_ORDERS_LIST_PAGE_NAME, {
      meta: {
        breadcrumb: 'orders',
        ...accessPermission(ORDER_VIEW)
      }
    }),
    route(CLIENT_ORDER_VIEW_PAGE_URL, orderDetails, CLIENT_ORDER_VIEW_PAGE_NAME, {
      meta: {
        editable: false,
        isNew: false,
        toPay: false,
        breadcrumb: 'order',
        redirect: CLIENT_ORDERS_LIST_PAGE_NAME,
        ...accessPermission(ORDER_VIEW)
      },
    }),
  ]
}
