import { RouteRecordRaw } from 'vue-router';
import { route } from '@/router/utils'
import { allPermissions } from '@access/index'
import { accessPermission } from '@access/permissions'

const Statistics = () => import('./Statistics.vue')

export const TARIFFS_STATISTICS_PAGE_NAME = 'cabinet:tariffs-statistics'

export const TARIFFS_STATISTICS_PAGE_URL = ''


const {
  STAFF: {
    COMPANY: {
      VIEW,
      VIEW_LIST,
      CREATE,
      EDIT
    },
  },
} = allPermissions

export function createRoutes(): RouteRecordRaw[] {
  return [
    route(TARIFFS_STATISTICS_PAGE_URL, Statistics, TARIFFS_STATISTICS_PAGE_NAME, {
      meta: {
        breadcrumb: 'statistics',
        ...accessPermission(VIEW_LIST)
      },
    }),
  ]
}
