<template>
<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.75 2.5V15C18.75 16.375 17.625 17.5 16.25 17.5H2.5V9.52499C3.4125 10.6125 4.81253 11.2875 6.36253 11.25C7.62503 11.225 8.7625 10.7375 9.6125 9.925C10 9.6 10.325 9.18749 10.575 8.73749C11.025 7.97499 11.275 7.07497 11.25 6.13747C11.2125 4.67497 10.5625 3.3875 9.55002 2.5H18.75Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M27.5 17.5V21.25C27.5 23.325 25.825 25 23.75 25H22.5C22.5 23.625 21.375 22.5 20 22.5C18.625 22.5 17.5 23.625 17.5 25H12.5C12.5 23.625 11.375 22.5 10 22.5C8.625 22.5 7.5 23.625 7.5 25H6.25C4.175 25 2.5 23.325 2.5 21.25V17.5H16.25C17.625 17.5 18.75 16.375 18.75 15V6.25H21.05C21.95 6.25 22.775 6.73751 23.225 7.51251L25.3625 11.25H23.75C23.0625 11.25 22.5 11.8125 22.5 12.5V16.25C22.5 16.9375 23.0625 17.5 23.75 17.5H27.5Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10 27.5C11.3807 27.5 12.5 26.3807 12.5 25C12.5 23.6193 11.3807 22.5 10 22.5C8.61929 22.5 7.5 23.6193 7.5 25C7.5 26.3807 8.61929 27.5 10 27.5Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M20 27.5C21.3807 27.5 22.5 26.3807 22.5 25C22.5 23.6193 21.3807 22.5 20 22.5C18.6193 22.5 17.5 23.6193 17.5 25C17.5 26.3807 18.6193 27.5 20 27.5Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M27.5 15V17.5H23.75C23.0625 17.5 22.5 16.9375 22.5 16.25V12.5C22.5 11.8125 23.0625 11.25 23.75 11.25H25.3625L27.5 15Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.25 6.13749C11.275 7.07499 11.025 7.97501 10.575 8.73751C10.325 9.18751 9.99997 9.60003 9.61247 9.92503C8.76247 10.7375 7.625 11.225 6.3625 11.25C4.8125 11.2875 3.41247 10.6125 2.49997 9.52502C2.32497 9.33752 2.17498 9.12503 2.03748 8.91253C1.54998 8.17503 1.27497 7.30006 1.24997 6.36256C1.21247 4.78756 1.91246 3.35002 3.03746 2.41252C3.88746 1.71252 4.96244 1.27502 6.13744 1.25002C7.44994 1.22502 8.64999 1.70002 9.54999 2.50002C10.5625 3.38752 11.2125 4.67499 11.25 6.13749Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.2998 6.28754L5.56232 7.48749L8.17477 4.96246" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>