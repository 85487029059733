import { RouteRecordRaw } from 'vue-router';
import { route } from '@/router/utils'
import { allPermissions } from '@access/index'
import { accessPermission } from '@access/permissions'

const  invoicesList = () => import('./views/List.vue')
const invoiceCreate = () => import('./views/Create.vue')
// const rentUnitEdit = () => import('./views/RentUnitEdit.vue')
// const storageFeeEdit = () => import('./views/StorageFeeEdit.vue')

// NAMES
export const INVOICES_TEMPLATE_LIST_PAGE_NAME = 'cabinet:invoices-templates-list'
export const INVOICE_TEMPLATE_CREATE_PAGE_NAME = 'cabinet:invoices-template-create'
export const INVOICE_TEMPLATE_UPDATE_PAGE_NAME = 'cabinet:invoices-template-update'

// URLs
export const INVOICE_TEMPLATE_LIST_PAGE_URL = ':date?/list/'
export const INVOICE_TEMPLATE_CREATE_PAGE_URL = 'create/'
export const INVOICE_TEMPLATE_UPDATE_PAGE_URL = 'edit/:id/'
// export const PARNTNERSHIP_APP_CREATE_PAGE_URL = 'create/'
// export const PARNTNERSHIP_APP_UPDATE_PAGE_URL = 'edit/:id?/'

const {
  STAFF: {
    COMPANY: {
      VIEW,
      VIEW_LIST,
      CREATE,
      EDIT
    },
  },
} = allPermissions

export function createRoutes(): RouteRecordRaw[] {
  return [
    route(INVOICE_TEMPLATE_LIST_PAGE_URL, invoicesList, INVOICES_TEMPLATE_LIST_PAGE_NAME, {
      meta: {
        breadcrumb: 'invoice-templates',
        ...accessPermission(VIEW_LIST)
      }
    }),
    route(INVOICE_TEMPLATE_CREATE_PAGE_URL, invoiceCreate, INVOICE_TEMPLATE_CREATE_PAGE_NAME, {
      meta: {
        editable: true,
        isNew: true,
        breadcrumb: 'invoice-templates',
        redirect: INVOICES_TEMPLATE_LIST_PAGE_NAME,
        ...accessPermission(CREATE, EDIT)
      },
    }),
    route(INVOICE_TEMPLATE_UPDATE_PAGE_URL, invoiceCreate, INVOICE_TEMPLATE_UPDATE_PAGE_NAME, {
      meta: {
        editable: true,
        isNew: false,
        breadcrumb: 'invoice-templates',
        redirect: INVOICES_TEMPLATE_LIST_PAGE_NAME,
        ...accessPermission(VIEW, EDIT)
      },
    }),
  ]
}
