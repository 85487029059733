import { $vfm } from 'vue-final-modal'

/* eslint-disable */
export const useModalOpener:(component: any) => any = params => $vfm.show({
  component: 'UiModalsContainer',
}, { 
  ...params,
})

export function useConfirmWithPromise(info: object ) {
  return new Promise((res, rej) => {
    const pr = (arg: any) => new Promise((resolve, reject) => {
      if (true == arg) {
        resolve(arg)
      } else {
        reject(new Error('...'))
      }
    }).then(result => {
      res(result)
    }, err => {
      rej()
    })
    useModalOpener({
      component: 'UiConfirmModal',
      info,
      promise: pr,
    })
  })
}
