import { RouteRecordRaw } from 'vue-router';
import { route } from '@/router/utils'
import { allPermissions } from '@access/index'
import { accessPermission } from '@access/permissions'

const Statistics = () => import('./Statistics.vue')

export const STATISTICS_PAGE_NAME = 'cabinet:statistics'

export const STATISTICS_PAGE_URL = ''


const {
  STAFF: {
    STATISTICS: { VIEW: STATISTICS_VIEW }
  },
} = allPermissions

export function createRoutes(): RouteRecordRaw[] {
  return [
    route(STATISTICS_PAGE_URL, Statistics, STATISTICS_PAGE_NAME, {
      meta: {
        breadcrumb: 'statistics',
        ...accessPermission(STATISTICS_VIEW)
      },
    }),
  ]
}
