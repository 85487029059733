import { RouteRecordRaw } from 'vue-router';
import { route } from '@/router/utils'
import { allPermissions } from '@access/index'
import { accessPermission } from '@access/permissions'

const transactionsList = () => import('./views/List.vue')

const { STAFF: { TRANSACTION: { VIEW_LIST, CREATE } } } = allPermissions

// NAMES
export const TRANSACTIONS_LIST_PAGE_NAME = 'cabinet:transactions-list'
export const CASH_TRANSACTIONS_LIST_PAGE_NAME = 'cabinet:cash-transactions-list'

// URLs
export const TRANSACTIONS_LIST_PAGE_URL = 'all/'
export const CASH_TRANSACTIONS_LIST_PAGE_URL = 'cash/'

export function createRoutes(): RouteRecordRaw[] {
  return [
    route(TRANSACTIONS_LIST_PAGE_URL, transactionsList, TRANSACTIONS_LIST_PAGE_NAME, {
      meta: {
        breadcrumb: 'transactions',
        cash: false,
        ...accessPermission(VIEW_LIST)
      }
    }),
    route(CASH_TRANSACTIONS_LIST_PAGE_URL, transactionsList, CASH_TRANSACTIONS_LIST_PAGE_NAME, {
      meta: {
        breadcrumb: 'cash-transactions',
        cash: true,
        ...accessPermission(VIEW_LIST)
      }
    }),
  ]
}
