import { App } from 'vue'
import AuthForm from './AuthForm.vue'
import PasswordResetForm from './PasswordResetForm.vue'
import NewPasswordForm from './NewPasswordForm.vue'
import AddStoragesType from './AddStoragesType.vue'
import AddRentObjectType from './AddRentObjectType.vue'
import AuthDataChange from './AuthDataChange.vue'
import AddSections from './AddSections.vue'
import AddRentalPeriod from './AddRentalPeriod.vue'
import ClientSelector from './ClientSelector.vue'
import OrdersFindInfo from './OrdersFindInfo.vue'
import RentObjectsList from './RentObjectsList.vue'
import SlotsObjectsList from './SlotsObjectsList.vue'
import EditOrderRentObjects from './EditOrderRentObjects.vue'
import OrderPayModal from "./OrderPayModal.vue";
import OrderExtend from "./OrderExtend.vue"
import AppForExportCreate from "./AppForExportCreate.vue"
import EditExportDate from "./EditExportDate.vue"
import AddTransactions from "./AddTransactions.vue"
import AddPaymentMethod from "./AddPaymentMethod.vue"
import OverduePayment from "./OverduePayment.vue"
import AddExternalApi from "./AddExternalApi.vue"
import DemoRequestForm from "./DemoRequestForm.vue"
import DepositModal from "./DepositModal.vue"
import QrAmountModal from "./QrAmountModal.vue"
import EditCompanyStatusModal from "./EditCompanyStatusModal.vue"
import EditTariffStatusModal from "./EditTariffStatusModal.vue"
import TariffDescriptionModal from "./TariffDescriptionModal.vue"

const register = (app: App<Element>): void => {
  app
  .component('TariffDescriptionModal', TariffDescriptionModal)
  .component('EditTariffStatusModal', EditTariffStatusModal)
  .component('EditCompanyStatusModal', EditCompanyStatusModal)
  .component('QrAmountModal', QrAmountModal)
  .component('DepositModal', DepositModal)
  .component('DemoRequestForm', DemoRequestForm)
  .component('AddPaymentMethod', AddPaymentMethod)
  .component('AddExternalApi', AddExternalApi)
  .component('OverduePayment', OverduePayment)
  .component('AddTransactions', AddTransactions)
  .component('SlotsObjectsList', SlotsObjectsList)
  .component('EditExportDate', EditExportDate)
  .component('AppForExportCreate', AppForExportCreate)
  .component('OrderExtend', OrderExtend)
  .component('OrderPayModal', OrderPayModal)
  .component('EditOrderRentObjects', EditOrderRentObjects)
  .component('RentObjectsList', RentObjectsList)
  .component('OrdersFindInfo', OrdersFindInfo)
  .component('ClientSelector', ClientSelector)
  .component('AddRentalPeriod', AddRentalPeriod)
  .component('AddSections', AddSections)
  .component('AuthForm', AuthForm)
  .component('AuthDataChange', AuthDataChange)
  .component('PasswordResetForm', PasswordResetForm)
  .component('NewPasswordForm', NewPasswordForm)
  .component('AddStoragesType', AddStoragesType)
  .component('AddRentObjectType', AddRentObjectType)
}

export default {
  register,
}
