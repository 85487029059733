import { RouteRecordRaw } from 'vue-router';
import { route } from '@/router/utils'
import { allPermissions } from '@access/index'
import { accessPermission } from '@access/permissions'

const storagesList = () => import('./views/List.vue')
const storageDetail = () => import('./views/Create.vue')
const rentUnitEdit = () => import('./views/RentUnitEdit.vue')
const storageFeeEdit = () => import('./views/StorageFeeEdit.vue')

// NAMES
export const STORAGES_LIST_PAGE_NAME = 'cabinet:storages-list'
export const STORAGE_CREATE_PAGE_NAME = 'cabinet:storage-create'
export const STORAGE_UPDATE_PAGE_NAME = 'cabinet:storage-update'
export const STORAGE_VIEW_PAGE_NAME = 'cabinet:storage'
export const RENT_UNIT_EDIT_PAGE_NAME = 'cabinet:storage-rent-unit-update'
export const STORAGE_FEE_EDIT_PAGE_NAME = 'cabinet:storage-storage-fee-update'

// URLs
export const STORAGES_LIST_PAGE_URL = ''
export const STORAGE_CREATE_PAGE_URL = 'create/'
export const STORAGE_UPDATE_PAGE_URL = 'edit/:id?/'
export const STORAGE_VIEW_PAGE_URL = 'view/:id/'
export const RENT_UNIT_EDIT_PAGE_URL = 'edit/:id?/rent-unit/:scheme_id?/'
export const STORAGE_FEE_EDIT_PAGE_URL = 'edit/:id/storage-fee/:scheme_id/'

const {
  STAFF: {
    WAREHOUSE: {
      VIEW: WAREHOUSE_VIEW,
      VIEW_LIST: WAREHOUSE_VIEW_LIST,
      EDIT: WAREHOUSE_EDIT,
      CREATE: WAREHOUSE_CREATE,
      DELETE: WAREHOUSE_DELETE,
    },
  },
} = allPermissions

console.log('WAREHOUSE_VIEW', WAREHOUSE_VIEW_LIST);


export function createRoutes(): RouteRecordRaw[] {
  return [
    route(STORAGES_LIST_PAGE_URL, storagesList, STORAGES_LIST_PAGE_NAME, {
      meta: {
        breadcrumb: 'storages',
        ...accessPermission(WAREHOUSE_VIEW_LIST)
      }
    }),
    route(STORAGE_CREATE_PAGE_URL, storageDetail, STORAGE_CREATE_PAGE_NAME, {
      meta: {
        editable: true,
        isNew: true,
        breadcrumb: 'storage-create',
        ...accessPermission(WAREHOUSE_CREATE)
      },
    }),
    route(STORAGE_UPDATE_PAGE_URL, storageDetail, STORAGE_UPDATE_PAGE_NAME, {
      meta: {
        editable: true,
        isNew: false,
        breadcrumb: 'storage-update',
        redirect: STORAGES_LIST_PAGE_NAME,
        ...accessPermission(WAREHOUSE_EDIT)
      },
    }),
    route(STORAGE_VIEW_PAGE_URL, storageDetail, STORAGE_VIEW_PAGE_NAME, {
      meta: {
        editable: false,
        isNew: false,
        breadcrumb: 'storages',
        redirect: STORAGES_LIST_PAGE_NAME,
        ...accessPermission(WAREHOUSE_VIEW)
      },
    }),

    route(RENT_UNIT_EDIT_PAGE_URL, rentUnitEdit, RENT_UNIT_EDIT_PAGE_NAME, {
      props: true,
      meta: {
        breadcrumb: 'storages'
      },
    }),
    route(STORAGE_FEE_EDIT_PAGE_URL, storageFeeEdit, STORAGE_FEE_EDIT_PAGE_NAME),
  ]
}
