import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "ls-cabinet" }
const _hoisted_2 = { class: "ls-cabinet__sidebar" }
const _hoisted_3 = { class: "ls-cabinet__main" }
const _hoisted_4 = { class: "ls-cabinet__header" }
const _hoisted_5 = { class: "ls-cabinet__content relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loader = _resolveComponent("loader")!
  const _component_sidebar = _resolveComponent("sidebar")!
  const _component_main_header = _resolveComponent("main-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_company_provider = _resolveComponent("company-provider")!

  return (_openBlock(), _createBlock(_component_company_provider, null, {
    default: _withCtx(() => [
      ($setup.loading)
        ? (_openBlock(), _createBlock(_component_loader, {
            key: 0,
            class: "full"
          }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_sidebar)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_main_header)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_router_view)
          ])
        ])
      ])
    ]),
    _: 1
  }))
}