import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "ls-form ls-form--space_none ls-form--modal" }
const _hoisted_2 = { class: "ls-form__title ls-form__title--space-bottom_sm" }
const _hoisted_3 = {
  key: 0,
  class: "ds-caption ds-caption--size_lg ds-caption--medium"
}
const _hoisted_4 = {
  key: 1,
  class: "ds-caption ds-caption--size_lg ds-caption--medium"
}
const _hoisted_5 = { class: "ls-form__wrapper" }
const _hoisted_6 = { class: "g-row g-row--appearance_spaced g-row--space_sm g-row--space_lg-xl g-row--justify_end g-row--align_center" }
const _hoisted_7 = { class: "error-label" }
const _hoisted_8 = {
  key: 0,
  class: "g-cell g-cols g-cols--12"
}
const _hoisted_9 = { class: "g-cell g-cols g-cols--12" }
const _hoisted_10 = {
  class: "ls-button ls-button--main ls-button--green ls-button--full_width",
  type: "submit"
}
const _hoisted_11 = { class: "ls-button__icon" }
const _hoisted_12 = { class: "ls-button__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VeeError = _resolveComponent("VeeError")!
  const _component_d_control_input = _resolveComponent("d-control-input")!
  const _component_i_save = _resolveComponent("i-save")!
  const _component_VeeForm = _resolveComponent("VeeForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!$setup.props.object)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString($setup.$t('Создание действия')), 1))
        : (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString($setup.$t('Редактирование действия')), 1))
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_VeeForm, { onSubmit: $setup.request }, {
        default: _withCtx(({ errors }) => [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_VeeError, { name: "nonFieldErrors" }, {
              default: _withCtx(({ message }) => [
                _createElementVNode("div", _hoisted_7, _toDisplayString(message), 1)
              ]),
              _: 1
            }),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.fields, (field) => {
              return (_openBlock(), _createElementBlock(_Fragment, {
                key: field.name
              }, [
                (field.condition ? field.condition() : true)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createVNode(_component_d_control_input, _mergeProps({
                        "input-label": field.label,
                        ref_for: true
                      }, field, {
                        modelValue: $setup.form[field.name],
                        "onUpdate:modelValue": ($event: any) => (($setup.form[field.name]) = $event),
                        placeholder: field.placeholder || '',
                        onOpen: ($event: any) => ($setup.optionsGetter('external_api_action', field, null, field.name == 'selectedFields' ? `${$setup.form.objectType.value}_fields` : null))
                      }), {
                        selection: _withCtx(({ values, search, isOpen }) => [
                          (values.length)
                            ? _withDirectives((_openBlock(), _createElementBlock("span", {
                                key: 0,
                                class: "multiselect__single"
                              }, _toDisplayString(values.length) + " options selected", 513)), [
                                [_vShow, !isOpen]
                              ])
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1040, ["input-label", "modelValue", "onUpdate:modelValue", "placeholder", "onOpen"])
                    ]))
                  : _createCommentVNode("", true)
              ], 64))
            }), 128)),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("button", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_i_save)
                ]),
                _createElementVNode("span", _hoisted_12, _toDisplayString($setup.$t('Сохранить')), 1)
              ])
            ])
          ])
        ]),
        _: 1
      })
    ])
  ]))
}