import { RouteRecordRaw } from 'vue-router';
import { route } from '@/router/utils'
import { allPermissions } from '@access/index'
import { accessPermission } from '@access/permissions'

const appsList = () => import('./views/List.vue')
const appView = () => import('./views/Details.vue')
// const rentUnitEdit = () => import('./views/RentUnitEdit.vue')
// const storageFeeEdit = () => import('./views/StorageFeeEdit.vue')

// NAMES
export const APPS_LIST_PAGE_NAME = 'cabinet:apps-list'
export const APP_VIEW_PAGE_NAME = 'cabinet:app'

// URLs
export const APPS_LIST_PAGE_URL = ':date?/list/'
export const APP_CREATE_PAGE_URL = 'create/'
export const APP_UPDATE_PAGE_URL = 'edit/:id?/'
export const APP_VIEW_PAGE_URL = 'view/:id/'

const {
  STAFF: {
    EXPORT_REQUEST: {
      VIEW,
      VIEW_LIST,
      CREATE,
      EDIT
    },
  },
} = allPermissions

export function createRoutes(): RouteRecordRaw[] {
  return [
    route(APPS_LIST_PAGE_URL, appsList, APPS_LIST_PAGE_NAME, {
      meta: {
        breadcrumb: 'apps',
        ...accessPermission(VIEW_LIST)
      }
    }),
    route(APP_VIEW_PAGE_URL, appView, APP_VIEW_PAGE_NAME, {
      meta: {
        editable: true,
        breadcrumb: 'app',
        redirect: APPS_LIST_PAGE_NAME,
        ...accessPermission(VIEW, EDIT)
      },
    }),
  ]
}
