import { useModalOpener } from '@/composables/useModalOpener'
import { usePromise } from '@/composables/usePromise'

export const useConfirm = (question: '', promise?: any) => {

  return new Promise((resolve, reject) => {
    const toPromise = (value: any) => usePromise(value)
      .then(a => resolve(a))
      .catch((e) => { console.log(e);
      })
    useModalOpener({
      component: 'UiConfirmModal',
      promise: toPromise,
      initial: { title: question },
    })
  })
}

