import {
  createSender,
} from '@/resource/resource'

import {
  prefixAPI,
} from '@/resource/api'

const DEMO_REQUEST_LINK = prefixAPI('partnership-request/create/')

export const getDemoRequestResource = createSender(DEMO_REQUEST_LINK)
