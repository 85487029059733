<template>
  <div>
    <input type="file" @change="uploadFile" />
    <button @click="submitFile">Submit</button>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
// import axios from "axios";

const file = ref(null);

const fileName = computed(() => file.value?.name);
const fileExtension = computed(() => fileName.value?.substr(fileName.value?.lastIndexOf(".") + 1));
const fileMimeType = computed(() => file.value?.type);

const uploadFile = (event) => {
  file.value = event.target.files[0];
};

const submitFile = async () => {
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
};
};
</script>