import { createApp, App, onMounted, provide, ref } from "vue"
import { createPinia } from "pinia"
import { createRouter, createWebHistory } from "vue-router"
import Components from "@/components"
import Modules from "@/modules"
// import Plugins from "@/plugins"
import createAppRouter from "@/router"
import i18n from "./i18n"

import definePlugin from '@/modules/refresher/provider'
// import install from '@/install'


type CreateApplication = {
  createApp: typeof createApp
  createRouter: typeof createRouter
  createWebHistory: typeof createWebHistory
  createPinia: typeof createPinia
}

export function createApplication({
  createApp,
  createRouter: createRouterInstance,
  createWebHistory,
  createPinia,
}: CreateApplication): App {
  const app = createApp({})

  app.config.globalProperties.$log = console.log
  app.config.compilerOptions.delimiters = ['[[', ']]']

  const pinia = createPinia()
  
  const router = createAppRouter({
    app,
    createInstance: createRouterInstance,
    createWebHistory,
    pinia,
  })

  // console.log(router);

  definePlugin(app)
  
  app
    .use(pinia)
    .use(i18n)
    .use(router)
    .use(Modules)
    // .use(Plugins)
    .use(Components)
    // .use(install)
    .mount('#app')

  return app
}
