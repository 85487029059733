import type { Router } from 'vue-router'

import { defineStore } from '@/modules/pinia-hot'
import { storage } from '@/utils/session'
import {
  setColors
} from '@/utils/helpers'
import {
  LS_ACCESS_TOKEN_STORAGE_NAME,
  LS_REFRESH_TOKEN_STORAGE_NAME,
} from '@/const'
import {
  getCompanyDetail,
} from '@/services/company.service'

import executeWithRefresh from '@/modules/refresher'

const id = 'company'

export const useCompany = defineStore(id, {
  state: () => ({
    is_authenticated: !!storage.getItem(LS_ACCESS_TOKEN_STORAGE_NAME),
    accessToken: storage.getItem(LS_ACCESS_TOKEN_STORAGE_NAME),
    refreshToken: storage.getItem(LS_REFRESH_TOKEN_STORAGE_NAME),
    company: {
      addPlaceInExportRequest: '',
      buttonsColorAdditional: '',
      buttonsColorMain: '',
      cancelExportRequest: '',
      changeDepartureDate: '',
      logo: '',
      paginateBy: '',
      removePlaceInExportRequest: '',
      sidebarColor: '',
      tariffPlan: '',
    },
  }),

  getters: {
  },

  actions: {
    saveToken(token: string, refresh?: string ) {
      storage.setItem(LS_ACCESS_TOKEN_STORAGE_NAME, token)
      this.accessToken = token
      if (refresh) {
        storage.setItem(LS_REFRESH_TOKEN_STORAGE_NAME, refresh)
        this.refreshToken = refresh
      }
    },
    deleteTokens() {
      storage.removeItem(LS_ACCESS_TOKEN_STORAGE_NAME)
      storage.removeItem(LS_REFRESH_TOKEN_STORAGE_NAME)
    },
    async receive(router: Router) {
      const { data: { item } } = (await executeWithRefresh(getCompanyDetail))
      this.company = item
      setColors(item.buttonsColorMain, item.buttonsColorAdditional, item.sidebarColor )
      // console.log(this.company);
    },
  },
})
