import {
  setCookie,
} from '@/utils/cookies'
import {
  LS_ACCESS_TOKEN_STORAGE_NAME,
  LS_REFRESH_TOKEN_STORAGE_NAME,
  LS_IS_AUTH_STORAGE_NAME,
  LS_WAREHOUSE_ID_STORAGE_NAME,
} from '@/const'

import {
  logoutResource,
} from '@/services/auth.service'

const currentURL = new URL(window.location.href)
const parts = currentURL.pathname.split("/")
const companyPart = parts[1]

export default async function useLogout() {
  try {
    await logoutResource.execute()
    return new Promise(resolve => {
      setCookie(LS_ACCESS_TOKEN_STORAGE_NAME, '')
      setCookie(LS_REFRESH_TOKEN_STORAGE_NAME, '')
      setCookie(LS_IS_AUTH_STORAGE_NAME, '')
      setCookie(LS_WAREHOUSE_ID_STORAGE_NAME, '')
      resolve(true)
      window.location.href = `/${companyPart}/login`
    })
  } catch (e) {
    // e.json().then(body => {
    //   console.log(e);
    //   Object.keys(body).forEach(key => {
    //     control.setFieldError(key, body[key][0])
    //   })
    // })
    return Promise.reject(e)
  }
}