<script lang="ts">
import { defineComponent, SetupContext, onBeforeMount } from 'vue'
import { useRouter } from 'vue-router'
import type { Router } from 'vue-router'
import { useCompany } from '@/stores/company'

export default defineComponent({
  setup(props: unknown, { slots }: SetupContext) {
    onBeforeMount(async () => {
      const companyStore = useCompany()
      const { receive } = companyStore
      const router: Router = useRouter()
      await receive(router)
    })
    return () => slots.default?.()
  },
})
</script>
