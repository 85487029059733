import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "ls-form ls-form--space_none ls-form--modal" }
const _hoisted_2 = { class: "ls-form__title ls-form__title--space-bottom_sm" }
const _hoisted_3 = { class: "ds-caption ds-caption--size_lg ds-caption--medium" }
const _hoisted_4 = { class: "error-label" }
const _hoisted_5 = { class: "ls-form__wrapper" }
const _hoisted_6 = { class: "g-row g-row--appearance_spaced g-row--space_2xl-lg g-row--justify_center g-row--justify_between-sm" }
const _hoisted_7 = { class: "g-cell g-cols g-cols--6" }
const _hoisted_8 = { class: "ls-button__text" }
const _hoisted_9 = { class: "g-cell g-cols g-cols--6" }
const _hoisted_10 = {
  class: "ls-button ls-button--main ls-button--green ls-button--full_width",
  type: "submit"
}
const _hoisted_11 = { class: "ls-button__icon" }
const _hoisted_12 = { class: "ls-button__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VeeError = _resolveComponent("VeeError")!
  const _component_d_control_input = _resolveComponent("d-control-input")!
  const _component_i_save = _resolveComponent("i-save")!
  const _component_VeeForm = _resolveComponent("VeeForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString($setup.$t('Company status')), 1)
    ]),
    _createVNode(_component_VeeForm, { onSubmit: $setup.request }, {
      default: _withCtx(({ errors, validate }) => [
        _createVNode(_component_VeeError, { name: "nonFieldErrors" }, {
          default: _withCtx(({ message }) => [
            _createElementVNode("div", _hoisted_4, _toDisplayString(message), 1)
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.fields, (field) => {
              return (_openBlock(), _createElementBlock("div", {
                key: field.name,
                class: "g-cell g-cols g-cols--12"
              }, [
                _createVNode(_component_d_control_input, _mergeProps({
                  class: field.classes || '',
                  component: field.component,
                  "input-label": field.label,
                  ref_for: true
                }, field, {
                  ref_for: true,
                  ref: field.name,
                  modelValue: $setup.form[field.name],
                  "onUpdate:modelValue": ($event: any) => (($setup.form[field.name]) = $event),
                  placeholder: field.placeholder || '',
                  onOpen: ($event: any) => ($setup.optionsGetter('company', field))
                }), null, 16, ["class", "component", "input-label", "modelValue", "onUpdate:modelValue", "placeholder", "onOpen"])
              ]))
            }), 128)),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("button", {
                class: "ls-button ls-button--main ls-button--grey-trans ls-button--full_width",
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => ($setup.props.close()), ["prevent"]))
              }, [
                _createElementVNode("span", _hoisted_8, _toDisplayString($setup.$t('Отмена')), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("button", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_i_save)
                ]),
                _createElementVNode("span", _hoisted_12, _toDisplayString($setup.$t('Ок')), 1)
              ])
            ])
          ])
        ])
      ]),
      _: 1
    })
  ]))
}