import {
  createUpdater,
  createReceiver,
} from '@/resource/resource'

import {
  prefixAPI,
} from '@/resource/api'

const MODEL = 'profile'

// const AUTHENTICATE_LINK = prefixAPI('/login/', MODEL)

const PROFILE_DETAIL_LINK = prefixAPI('/detail/', MODEL)
const PROFILE_UPDATE_WHIT_PASS_LINK = prefixAPI('/update-with-pass/', MODEL)
export const PROFILE_UPDATE_LINK = prefixAPI('/update/', MODEL)

export const profileDetailResource = createReceiver(PROFILE_DETAIL_LINK)
export const profileUpdateWhitPass = createUpdater(PROFILE_UPDATE_WHIT_PASS_LINK)
export const profileUpdateResource = createUpdater(PROFILE_UPDATE_LINK)
