/* eslint-disable prefer-object-spread */

import { withDefaultProps } from '@aspectus/vue-utils';
import { PermissionsAvailable } from '@aspectus/vue-permissions';

import RouteAccessState from './RouteAccessState';

export default Object.assign(
  withDefaultProps({ checker: () => RouteAccessState }, PermissionsAvailable),
  { name: 'route-available' }
);
