import { RouteRecordRaw } from 'vue-router';
import { route } from '@/router/utils'

import { allPermissions } from '@access/index'
import { isPermitted } from '@access/permissions'

const appsList = () => import('../applications/views/List.vue')
const appView = () => import('../applications/views/Details.vue')
import { accessPermission } from '@access/permissions'
// const rentUnitEdit = () => import('./views/RentUnitEdit.vue')
// const storageFeeEdit = () => import('./views/StorageFeeEdit.vue')

// NAMES
export const CLIENT_APPS_LIST_PAGE_NAME = 'cabinet:client-apps-list'
export const CLIENT_APP_VIEW_PAGE_NAME = 'cabinet:client-app'

// URLs
export const CLIENT_APPS_LIST_PAGE_URL = ':date?/list/'
export const CLIENT_APP_CREATE_PAGE_URL = 'create/'
export const CLIENT_APP_UPDATE_PAGE_URL = 'edit/:id?'
export const CLIENT_APP_VIEW_PAGE_URL = 'view/:id'

const {
  STAFF: {
    EXPORT_REQUEST: {
      VIEW,
      CREATE,
      VIEW_LIST,
    },
  },
} = allPermissions


export function createRoutes(): RouteRecordRaw[] {
  return [
    route(CLIENT_APPS_LIST_PAGE_URL, appsList, CLIENT_APPS_LIST_PAGE_NAME, {
      meta: {
        breadcrumb: 'apps',
        ...accessPermission(VIEW_LIST),
      }
    }),
    route(CLIENT_APP_VIEW_PAGE_URL, appView, CLIENT_APP_VIEW_PAGE_NAME, {
      meta: {
        editable: true,
        breadcrumb: 'app',
        redirect: CLIENT_APPS_LIST_PAGE_NAME,
        ...accessPermission(VIEW),
      },
    }),
  ]
}
