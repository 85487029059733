import { RouteRecordRaw } from 'vue-router';
import { route } from '@/router/utils'
import { allPermissions } from '@access/index'
import { accessPermission } from '@access/permissions'

const StoragesTypes = () => import('./storages-types/List.vue')
const Categories = () => import('./Categories.vue')
const RentalObjectTypes = () => import('./rental-object-types/List.vue')
const RentalObjectTypesCreate = () => import('./rental-object-types/Create.vue')
const Api = () => import('./Api.vue')
const ExternalApi = () => import('./external-api/List.vue')
const CommonSettings = () => import('./common/CommonSettings.vue')
const Template = () => import('./common/views/Template.vue')
const Prices = () => import('./prices/List.vue')
const PricesEdit = () => import('./prices/Edit.vue')
const EmployeesList = () => import('./employees/List.vue')
const EmployeesCreate = () => import('./employees/Create.vue')
const FeeList = () => import('./fee/List.vue')
const FeeCreate = () => import('./fee/Create.vue')
const InsuranceList = () => import('./insurance/List.vue')
const InsuranceCreate = () => import('./insurance/Create.vue')
const RentalPeriodsList = () => import('./rental-periods/List.vue')
const RolesList = () => import('./roles/List.vue')
const RolesCreate = () => import('./roles/Create.vue')
const DocumetsList = () => import('./documents/List.vue')
const DocumetCreate = () => import('./documents/Create.vue')

// NAMES
export const ROLES_PAGE_NAME = 'cabinet:settings-roles'
export const ROLES_CREATE_PAGE_NAME = 'cabinet:settings-roles-create'
export const ROLES_UPDATE_PAGE_NAME = 'cabinet:settings-roles-update'
export const RENTAL_PERIODS_PAGE_NAME = 'cabinet:settings-rental-periods'
export const INSURANCE_PAGE_NAME = 'cabinet:settings-insurance'
export const INSURANCE_CREATE_PAGE_NAME = 'cabinet:settings-insurance-create'
export const INSURANCE_UPDATE_PAGE_NAME = 'cabinet:settings-insurance-update'
export const FEE_PAGE_NAME = 'cabinet:settings-free'
export const FEE_CREATE_PAGE_NAME = 'cabinet:settings-free-create'
export const FEE_UPDATE_PAGE_NAME = 'cabinet:settings-free-update'
export const EMPLOYEES_LIST_PAGE_NAME = 'cabinet:settings-employees'
export const EMPLOYEE_CREATE_PAGE_NAME = 'cabinet:settings-employee-create'
export const EMPLOYEE_UPDATE_PAGE_NAME = 'cabinet:settings-employee-update'
export const PRICES_PAGE_NAME = 'cabinet:settings-prices'
export const PRICES_EDIT_PAGE_NAME = 'cabinet:settings-prices-edit'
export const COMMON_PAGE_NAME = 'cabinet:settings-common'
export const TEMPLATE_EDIT_PAGE_NAME = 'cabinet:settings-template-update'
export const API_PAGE_NAME = 'cabinet:settings-api'
export const DOCUMENTS_PAGE_NAME = 'cabinet:documents'
export const DOCUMENT_CREATE_PAGE_NAME = 'cabinet:documents-create'
export const DOCUMENT_EDIT_PAGE_NAME = 'cabinet:documents-edit'

export const RENTAL_OBJECTS_TYPES_PAGE_NAME = 'cabinet:settings-rental-object-types'
export const STORAGES_TYPES_PAGE_NAME = 'cabinet:settings-storages-types'
export const RENTAL_OBJECTS_CREATE_PAGE_NAME = 'cabinet:settings-rental-object-create'
export const RENTAL_OBJECTS_EDIT_PAGE_NAME = 'cabinet:settings-rental-object-edit'
export const CATEGORIES_PAGE_NAME = 'cabinet:settings-categories'
// URLs
export const ROLES_PAGE_URL = 'roles/'
export const ROLES_CREATE_PAGE_URL = 'roles/create/'
export const ROLES_UPDATE_PAGE_URL = 'roles/:id/update/'
export const RENTAL_PERIODS_PAGE_URL = 'rental-periods/'
export const INSURANCE_PAGE_URL = 'insurance/'
export const INSURANCE_CREATE_PAGE_URL = 'insurance/create/'
export const INSURANCE_UPDATE_PAGE_URL = 'insurance/:id/update/'
export const FEE_PAGE_URL = 'free/'
export const FEE_CREATE_PAGE_URL = 'free/create/'
export const FEE_UPDATE_PAGE_URL = 'free/:id/update/'
export const EMPLOYEES_PAGE_URL = 'employees/'
export const EMPLOYEE_CREATE_PAGE_URL = 'employee/create/'
export const EMPLOYEE_UPDATE_PAGE_URL = 'employee/:id/update/'
export const PRICES_PAGE_URL = 'prices/'
export const PRICES_EDIT_PAGE_URL = 'prices/warehouse/:id/edit/'
export const COMMON_PAGE_URL = 'common/'
export const TEMPLATE_EDIT_PAGE_URL = 'common/templates/:id/edit'
export const API_PAGE_URL = 'external-api/'
export const DOCUMENTS_PAGE_URL = 'documents/'
export const DOCUMENT_CREATE_PAGE_URL = 'documents/create'
export const DOCUMENT_EDIT_PAGE_URL = 'documents/:id/edit/'

export const RENTAL_OBJECTS_TYPES_URL = 'rental-object-types/'
export const STORAGES_TYPES_PAGE_URL = 'storages-types/'
export const RENTAL_OBJECTS_CREATE_PAGE_URL = 'rental-object-types/create/'
export const RENTAL_OBJECTS_EDIT_PAGE_URL = 'rental-object-types/:id/edit/'
export const CATEGORIES_PAGE_PAGE_URL = ''

const {
  STAFF: {
    FEE: { EDIT: FEE_EDIT, CREATE: FEE_CREATE },
    SETTINGS_FEE: { VIEW_LIST: FEE_VIEW_LIST },
    ROLE: { VIEW: ROLE_VIEW, CREATE: ROLE_CREATE, EDIT: ROLE_EDIT },
    SETTINGS_ROLE: { VIEW_LIST: ROLE_VIEW_LIST },
    SETTINGS_RENT_PERIOD: { VIEW_LIST: RENT_PERIOD_VIEW_LIST },
    INSURANCE: { EDIT: INSURANCE_EDIT, CREATE: INSURANCE_CREATE },
    SETTINGS_INSURANCE: { VIEW_LIST: INSURANCE_VIEW_LIST },
    EMPLOYEE: { EDIT: EMPLOYEE_EDIT, CREATE: EMPLOYEE_CREATE },
    SETTINGS_EMPLOYEE: { VIEW_LIST: EMPLOYEE_VIEW_LIST },
    RENT_OBJECT_TYPE: { CREATE: RENT_OBJECT_TYPE_CREATE },
    SETTINGS_RENT_OBJECT_TYPE: { VIEW_LIST: RENT_OBJECT_TYPE_VIEW_LIST },
    SETTINGS_WAREHOUSE_TYPE: { VIEW_LIST: WAREHOUSE_TYPE_VIEW_LIST },
    SETTINGS: { VIEW_LIST: SETTINGS_VIEW_LIST },
    SETTINGS_API: { VIEW_LIST: API_VIEW_LIST },
    SETTINGS_COMMON: { VIEW_LIST: SETTINGS_COMMON_VIEW_LIST },
    SETTINGS_PRICE: { VIEW_LIST: PRICE_VIEW_LIST },
  }
} = allPermissions

export function createRoutes(): RouteRecordRaw[] {
  return [
    route(ROLES_PAGE_URL, RolesList, ROLES_PAGE_NAME, {
      meta: {
        breadcrumb: 'roles',
        ...accessPermission(ROLE_VIEW_LIST)
      },
    }),
    route(ROLES_CREATE_PAGE_URL, RolesCreate, ROLES_CREATE_PAGE_NAME, {
      meta: {
        breadcrumb: 'roles-create',
        isNew: true,
        redirect: ROLES_PAGE_NAME,
        ...accessPermission(ROLE_CREATE)
      },
    }),
    route(ROLES_UPDATE_PAGE_URL, RolesCreate, ROLES_UPDATE_PAGE_NAME, {
      meta: {
        breadcrumb: 'roles-update',
        isNew: false,
        redirect: ROLES_PAGE_NAME,
        ...accessPermission(ROLE_EDIT),
      },
    }),
    route(RENTAL_PERIODS_PAGE_URL, RentalPeriodsList, RENTAL_PERIODS_PAGE_NAME, {
      meta: {
        breadcrumb: 'rentalPeriods',
        ...accessPermission(RENT_PERIOD_VIEW_LIST)
      },
    }),
    route(INSURANCE_PAGE_URL, InsuranceList, INSURANCE_PAGE_NAME, {
      meta: {
        breadcrumb: 'insurance',
        ...accessPermission(INSURANCE_VIEW_LIST)
      },
    }),
    route(INSURANCE_CREATE_PAGE_URL, InsuranceCreate, INSURANCE_CREATE_PAGE_NAME, {
      meta: {
        breadcrumb: 'insurance-create',
        redirect: INSURANCE_PAGE_NAME,
        isNew: true,
        ...accessPermission(INSURANCE_CREATE)
      },
    }),
    route(INSURANCE_UPDATE_PAGE_URL, InsuranceCreate, INSURANCE_UPDATE_PAGE_NAME, {
      meta: {
        editable: true,
        isNew: false,
        breadcrumb: 'insurance-update',
        redirect: INSURANCE_PAGE_NAME,
        ...accessPermission(INSURANCE_EDIT)
      },
    }),
    route(FEE_PAGE_URL, FeeList, FEE_PAGE_NAME, {
      meta: {
        breadcrumb: 'fee',
        ...accessPermission(FEE_VIEW_LIST)
      },
    }),
    route(FEE_CREATE_PAGE_URL, FeeCreate, FEE_CREATE_PAGE_NAME, {
      meta: {
        breadcrumb: 'fee-create',
        isNew: true,
        redirect: FEE_PAGE_NAME,
        ...accessPermission(FEE_CREATE)
      },
    }),
    route(FEE_UPDATE_PAGE_URL, FeeCreate, FEE_UPDATE_PAGE_NAME, {
      meta: {
        editable: true,
        isNew: false,
        breadcrumb: 'fee-update',
        redirect: FEE_PAGE_NAME,
        ...accessPermission(FEE_EDIT)
      },
    }),
    route(EMPLOYEES_PAGE_URL, EmployeesList, EMPLOYEES_LIST_PAGE_NAME, {
      meta: {
        breadcrumb: 'employees',
        ...accessPermission(EMPLOYEE_VIEW_LIST)
      },
    }),
    route(EMPLOYEE_UPDATE_PAGE_URL, EmployeesCreate, EMPLOYEE_UPDATE_PAGE_NAME, {
      meta: {
        breadcrumb: 'employee-update',
        isNew: false,
        redirect: EMPLOYEES_LIST_PAGE_NAME,
        ...accessPermission(EMPLOYEE_EDIT)
      },
    }),
    route(EMPLOYEE_CREATE_PAGE_URL, EmployeesCreate, EMPLOYEE_CREATE_PAGE_NAME, {
      meta: {
        breadcrumb: 'employee-create',
        redirect: EMPLOYEES_LIST_PAGE_NAME,
        isNew: true,
        ...accessPermission(EMPLOYEE_CREATE),
      },
    }),
    route(PRICES_PAGE_URL, Prices, PRICES_PAGE_NAME, {
      meta: {
        breadcrumb: 'prices',
        ...accessPermission(PRICE_VIEW_LIST)
      },
    }),
    route(PRICES_EDIT_PAGE_URL, PricesEdit, PRICES_EDIT_PAGE_NAME, {
      meta: {
        breadcrumb: 'prices-update',
        redirect: PRICES_PAGE_NAME,
        ...accessPermission(PRICE_VIEW_LIST)
      },
    }),
    route(COMMON_PAGE_URL, CommonSettings, COMMON_PAGE_NAME, {
      meta: {
        breadcrumb: 'common-settings',
        ...accessPermission(SETTINGS_COMMON_VIEW_LIST)
      },
    }),
    route(TEMPLATE_EDIT_PAGE_URL, Template, TEMPLATE_EDIT_PAGE_NAME, {
      meta: {
        breadcrumb: 'common-settings-template',
        ...accessPermission(SETTINGS_COMMON_VIEW_LIST),
        redirect: COMMON_PAGE_NAME,
      },
    }),
    route(API_PAGE_URL, ExternalApi, API_PAGE_NAME, {
      meta: {
        breadcrumb: 'api-settings',
        ...accessPermission(API_VIEW_LIST)
      },
    }),

    route(RENTAL_OBJECTS_TYPES_URL, RentalObjectTypes, RENTAL_OBJECTS_TYPES_PAGE_NAME, {
      meta: {
        breadcrumb: 'rental-object-types',
        ...accessPermission(RENT_OBJECT_TYPE_VIEW_LIST)
      },
    }),
    route(RENTAL_OBJECTS_CREATE_PAGE_URL, RentalObjectTypesCreate, RENTAL_OBJECTS_CREATE_PAGE_NAME, {
      meta: {
        breadcrumb: 'rental-object-types-create',
        isNew: true,
        redirect: RENTAL_OBJECTS_TYPES_PAGE_NAME,
        ...accessPermission(RENT_OBJECT_TYPE_CREATE)
      },
    }),
    route(RENTAL_OBJECTS_EDIT_PAGE_URL, RentalObjectTypesCreate, RENTAL_OBJECTS_EDIT_PAGE_NAME, {
      meta: {
        breadcrumb: 'rental-object-types-edit',
        isNew: false,
        redirect: RENTAL_OBJECTS_TYPES_PAGE_NAME,
        ...accessPermission(RENT_OBJECT_TYPE_CREATE)
      },
    }),
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    route(DOCUMENTS_PAGE_URL, DocumetsList, DOCUMENTS_PAGE_NAME, {
      meta: {
        breadcrumb: 'documents',
        ...accessPermission(RENT_OBJECT_TYPE_VIEW_LIST)
      },
    }),
    route(DOCUMENT_CREATE_PAGE_URL, DocumetCreate, DOCUMENT_CREATE_PAGE_NAME, {
      meta: {
        breadcrumb: 'document-create',
        isNew: true,
        redirect: DOCUMENTS_PAGE_NAME,
        ...accessPermission(RENT_OBJECT_TYPE_CREATE)
      },
    }),
    route(DOCUMENT_EDIT_PAGE_URL, DocumetCreate, DOCUMENT_EDIT_PAGE_NAME, {
      meta: {
        breadcrumb: 'document-edit',
        isNew: false,
        redirect: DOCUMENTS_PAGE_NAME,
        ...accessPermission(RENT_OBJECT_TYPE_CREATE)
      },
    }),
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

    route(STORAGES_TYPES_PAGE_URL, StoragesTypes, STORAGES_TYPES_PAGE_NAME, {
      meta: {
        breadcrumb: 'storages-types',
        ...accessPermission(WAREHOUSE_TYPE_VIEW_LIST)
      },
    }),
    route(CATEGORIES_PAGE_PAGE_URL, Categories, CATEGORIES_PAGE_NAME, {
      meta: {
        ...accessPermission(SETTINGS_VIEW_LIST)
      },
    }),
  ]
}
