import { RouteRecordRaw } from 'vue-router';
import { route } from '@/router/utils'
import { allPermissions } from '@access/index'
import { accessPermission } from '@access/permissions'

const appsList = () => import('./views/List.vue')
const appView = () => import('./views/Details.vue')
// const rentUnitEdit = () => import('./views/RentUnitEdit.vue')
// const storageFeeEdit = () => import('./views/StorageFeeEdit.vue')

// NAMES
export const PARNTNERSHIP_APPS_LIST_PAGE_NAME = 'cabinet:partnership-apps-list'
export const PARNTNERSHIP_APP_VIEW_PAGE_NAME = 'cabinet:partnership-app'

// URLs
export const PARNTNERSHIP_APPS_LIST_PAGE_URL = ':date?/list/'
// export const PARNTNERSHIP_APP_CREATE_PAGE_URL = 'create/'
// export const PARNTNERSHIP_APP_UPDATE_PAGE_URL = 'edit/:id?/'
export const PARNTNERSHIP_APP_VIEW_PAGE_URL = 'view/:id/'

const {
  STAFF: {
    PARTNERSHIP_REQUEST: {
      VIEW,
      VIEW_LIST,
      CREATE,
      EDIT
    },
  },
} = allPermissions

export function createRoutes(): RouteRecordRaw[] {
  return [
    route(PARNTNERSHIP_APPS_LIST_PAGE_URL, appsList, PARNTNERSHIP_APPS_LIST_PAGE_NAME, {
      meta: {
        breadcrumb: 'partnership-apps',
        ...accessPermission(VIEW_LIST)
      }
    }),
    route(PARNTNERSHIP_APP_VIEW_PAGE_URL, appView, PARNTNERSHIP_APP_VIEW_PAGE_NAME, {
      meta: {
        editable: false,
        breadcrumb: 'partnership-app',
        redirect: PARNTNERSHIP_APPS_LIST_PAGE_NAME,
        ...accessPermission(VIEW, EDIT)
      },
    }),
  ]
}
