import {
  createSender,
  createOptionsGetter,
  createReceiver,
} from '@/resource/resource'

import {
  prefixAPI,
} from '@/resource/api'

const MODEL = 'transaction'

const TRANSACTIONS_LIST_LINK = prefixAPI('/list/{?limit,offset,payment_method,id,type,warehouse,created_at_after,created_at_before,status,search,client,company}', MODEL)
const TRANSACTION_CREATE_LINK = prefixAPI('/create/', MODEL)
const TRANSACTION_TOTAL_AMOUNT_LINK = prefixAPI('/get-total-cash/', MODEL)


export const transactionsListResource = createReceiver(TRANSACTIONS_LIST_LINK)
export const transactionsOptionsResource = createOptionsGetter(TRANSACTIONS_LIST_LINK)
export const transactionsCreateResource = createSender(TRANSACTION_CREATE_LINK)
export const transactionsTotalCashResource = createSender(TRANSACTION_TOTAL_AMOUNT_LINK)
export const transactionsCreateOptionsResource = createOptionsGetter(TRANSACTION_CREATE_LINK)
// export const rentPeriodDeleteResource = createSender(RENT_PERIOD_DELETE_LINK)
