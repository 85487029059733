import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, normalizeClass as _normalizeClass, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ls-form ls-form--space_none ls-form--modal" }
const _hoisted_2 = { class: "ls-form__title ls-form__title--space-bottom_sm" }
const _hoisted_3 = {
  key: 0,
  class: "ds-caption ds-caption--size_lg ds-caption--medium"
}
const _hoisted_4 = {
  key: 1,
  class: "ds-caption ds-caption--size_lg ds-caption--medium"
}
const _hoisted_5 = { class: "ls-form__wrapper" }
const _hoisted_6 = { class: "error-label" }
const _hoisted_7 = { class: "g-row g-row--appearance_spaced g-row--space_sm g-row--space_xl-lg" }
const _hoisted_8 = { class: "g-cell g-cols g-cols--12" }
const _hoisted_9 = { class: "g-row g-row--appearance_spaced g-row--space_sm g-row--space_sm-xl g-row--align_end" }
const _hoisted_10 = {
  key: 0,
  class: "error-label"
}
const _hoisted_11 = {
  key: 1,
  class: "error-label"
}
const _hoisted_12 = {
  key: 0,
  class: "multiselect__single"
}
const _hoisted_13 = { class: "ls-button__icon" }
const _hoisted_14 = {
  key: 0,
  class: "g-cell g-cols g-cols--12"
}
const _hoisted_15 = { class: "order-slots-list order-slots-list--modal" }
const _hoisted_16 = { class: "order-slots-list__title" }
const _hoisted_17 = { class: "order-slots-list__input order-slots-list__input--full_width" }
const _hoisted_18 = { class: "order-slots-list__element-action" }
const _hoisted_19 = { class: "g-cell g-cols g-cols--12" }
const _hoisted_20 = {
  class: "ls-button ls-button--main ls-button--green ls-button--full_width",
  type: "submit"
}
const _hoisted_21 = { class: "ls-button__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VeeError = _resolveComponent("VeeError")!
  const _component_d_control_input = _resolveComponent("d-control-input")!
  const _component_i_add = _resolveComponent("i-add")!
  const _component_i_x_circle = _resolveComponent("i-x-circle")!
  const _component_VeeForm = _resolveComponent("VeeForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      ($setup.props.slot)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString($setup.$t('Заявка на вывоз')), 1))
        : (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString($setup.$t('Добавление мест к заявке ')) + " " + _toDisplayString($setup.props.id), 1))
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_VeeForm, { onSubmit: $setup.request }, {
        default: _withCtx(({ errors, validate }) => [
          _createVNode(_component_VeeError, { name: "nonFieldErrors" }, {
            default: _withCtx(({ message }) => [
              _createElementVNode("div", _hoisted_6, _toDisplayString(message), 1)
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", {
                  class: _normalizeClass(["g-cell g-cols g-cols--auto relative", $setup.slotInfoField.cols])
                }, [
                  ($setup.slotsHasMatch)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString($setup.$t('Already added.')), 1))
                    : _createCommentVNode("", true),
                  ($setup.isEmptySlotsList)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString($setup.$t('Slots not selected.')), 1))
                    : _createCommentVNode("", true),
                  _createVNode(_component_d_control_input, _mergeProps({
                    class: $setup.slotInfoField.classes || '',
                    component: $setup.slotInfoField.component,
                    "input-label": $setup.slotInfoField.label
                  }, $setup.slotInfoField, {
                    ref: $setup.slotInfoField.name,
                    modelValue: $setup.selectedSlot,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.selectedSlot) = $event)),
                    placeholder: $setup.slotInfoField.placeholder || ''
                  }), {
                    selection: _withCtx(({ values }) => [
                      (values.length)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(values.length) + " " + _toDisplayString($setup.$t('options selected')), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 16, ["class", "component", "input-label", "modelValue", "placeholder"])
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(["g-cell g-cell--narrow-sm", {'disabled' : !$setup.selectedSlot || $setup.slotsHasMatch}])
                }, [
                  _createElementVNode("button", {
                    class: "ls-button ls-button--main ls-button--green-trans ls-button--small ls-button--trans",
                    onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => ($setup.addSlotToList()), ["prevent"]))
                  }, [
                    _createElementVNode("div", _hoisted_13, [
                      _createVNode(_component_i_add)
                    ])
                  ])
                ], 2),
                ($setup.form.slots.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                      _createElementVNode("div", _hoisted_15, [
                        _createElementVNode("div", _hoisted_16, _toDisplayString($setup.$t('Список мест')), 1),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.form.slots, (slot, index) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: slot.id,
                            class: "order-slots-list__element"
                          }, [
                            _createElementVNode("div", _hoisted_17, [
                              (_openBlock(), _createBlock(_component_d_control_input, _mergeProps({
                                component: "d-control-input",
                                ref_for: true
                              }, $setup.slotPriceField, {
                                key: `slot_${slot.id}`,
                                ref_for: true,
                                ref: `slot_${slot.id}`,
                                name: `slot_${slot.id}`,
                                modelValue: slot.title,
                                "onUpdate:modelValue": ($event: any) => ((slot.title) = $event),
                                readonly: ""
                              }), null, 16, ["name", "modelValue", "onUpdate:modelValue"]))
                            ]),
                            _createElementVNode("div", _hoisted_18, [
                              _createVNode(_component_i_x_circle, {
                                class: "pointer",
                                onClick: _withModifiers(($event: any) => ($setup.removeItem(index, $setup.form.slots)), ["prevent"])
                              }, null, 8, ["onClick"])
                            ])
                          ]))
                        }), 128))
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            (!$setup.props.list)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList($setup.exportField, (field, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: _normalizeClass(["g-cell g-cols g-cols--12", field.cols]),
                    key: `${field.name}_of_row_${index}`
                  }, [
                    (_openBlock(), _createBlock(_component_d_control_input, _mergeProps({
                      class: field.class || '',
                      "input-label": field.label,
                      modelValue: $setup.form[field.name],
                      "onUpdate:modelValue": ($event: any) => (($setup.form[field.name]) = $event),
                      ref_for: true
                    }, field, {
                      key: `${field.name}_${index}`,
                      ref_for: true,
                      ref: `${field.name}_${index}`,
                      name: `${field.name}_${index}`
                    }), null, 16, ["class", "input-label", "modelValue", "onUpdate:modelValue", "name"]))
                  ], 2))
                }), 128))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("button", _hoisted_20, [
                _createElementVNode("span", _hoisted_21, _toDisplayString($setup.$t('Создать')), 1)
              ])
            ])
          ])
        ]),
        _: 1
      })
    ])
  ]))
}