import { mergeProps as _mergeProps, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.as), {
    class: _normalizeClass(_ctx.classes.root.value)
  }, {
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.gui), _mergeProps({
        class: _ctx.classes.gui.value,
        onSave: _ctx.onSave
      }, _ctx.guiAttrs), _createSlots({ _: 2 }, [
        _renderList(_ctx.slots, (_, sn) => {
          return {
            name: sn,
            fn: _withCtx((sd) => [
              _renderSlot(_ctx.$slots, sn, _mergeProps({ sn: sn }, sd))
            ])
          }
        })
      ]), 1040, ["class", "onSave"]))
    ]),
    _: 3
  }, 8, ["class"]))
}