import { RouteRecordRaw } from 'vue-router';
import { route } from '@/router/utils'
import { allPermissions } from '@access/index'
import { accessPermission } from '@access/permissions'

const usersList = () => import('./views/List.vue')
const userDetail = () => import('./views/details/Details.vue')
const userCreate = () => import('./views/Create.vue')


// NAMES
export const USERS_LIST_PAGE_NAME = 'cabinet:users-list'
export const USER_CREATE_PAGE_NAME = 'cabinet:users-create'
export const USER_UPDATE_PAGE_NAME = 'cabinet:users-update'
export const USER_VIEW_PAGE_NAME = 'cabinet:user-detail'

// URLs
export const USERS_UPDATE_PAGE_URL = 'edit/:id/'
export const USERS_LIST_PAGE_URL = 'list/'
export const USER_CREATE_PAGE_URL = 'create/'
export const USER_VIEW_PAGE_URL = 'view/:id/'

const {
  STAFF: {
    USER: {
      VIEW,
      CREATE,
      VIEW_LIST,
      EDIT,
    },
  },
} = allPermissions

export function createRoutes(): RouteRecordRaw[] {
  return [
    route(USERS_LIST_PAGE_URL, usersList, USERS_LIST_PAGE_NAME, {
      meta: {
        breadcrumb: 'users',
        ...accessPermission(VIEW_LIST)
      }
    }),
    route(USER_CREATE_PAGE_URL, userCreate, USER_CREATE_PAGE_NAME, {
      meta: {
        editable: true,
        isNew: true,
        breadcrumb: 'user-create',
        ...accessPermission(CREATE)
      },
    }),
    route(USERS_UPDATE_PAGE_URL, userCreate, USER_UPDATE_PAGE_NAME, {
      meta: {
        editable: true,
        isNew: false,
        breadcrumb: 'user-update',
        ...accessPermission(EDIT)
      },
    }),
    route(USER_VIEW_PAGE_URL, userDetail, USER_VIEW_PAGE_NAME, {
      meta: {
        breadcrumb: 'users',
        redirect: USERS_LIST_PAGE_NAME,
        ...accessPermission(VIEW)
      },
    }),
  ]
}
