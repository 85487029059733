export type UploadOptions = {
  url?: string
  method?: string
  field?: string
  fields?: object
  acl?: string
  policy?: string
  type?: string
  success?: (xhr: XMLHttpRequest) => boolean
  data?: any,
  initialHeaders?: [string, string][],
}

function checkSuccess(xhr: XMLHttpRequest) {
  return xhr.status < 400
}

function applyHeaders(xhr: XMLHttpRequest, headers: [string, string][]) {
  headers.forEach(([name, value]) => {
    xhr.setRequestHeader(name, value)
  })
}

export type Upload = {
  progress: () => any,
  abort: () => any,
  header: () => any,
  append: () => any,
  promise: () => any,
}

export interface FileWithName extends File {
  name: string,
}
export interface BlobWithName extends Blob {
  name: string,
}

export function upload(
  {
    url,
    method = 'POST',
    field = 'file',
    acl = 'public-read',
    policy,
    type,
    success = checkSuccess,
    initialHeaders = [],
  }: UploadOptions = {},
  file: FileWithName | BlobWithName,
): any {
  const form = new FormData
  const xhr = new XMLHttpRequest
  const headers: [string, string][] = initialHeaders

  form.append(field, file, file.name)
  if (policy) headers.push(['X-Policy', policy])
  headers.push(['X-Acl', acl || 'public-read'])
  headers.push(['X-Content-Type', type || file.type || 'application/octet-stream'])

  return {
    progress(callback: (e: ProgressEvent) => any) {
      xhr.upload.addEventListener('progress', callback, false)
      return this
    },
    abort() {
      xhr.abort()
      return this
    },
    header(name: string, value: string) {
      headers.push([name, value])
      return this
    },
    append(name: string, value: string | Blob, filename?: string) {
      form.append(name, value, filename)
      return this
    },
    promise<T extends XMLHttpRequest = XMLHttpRequest>(): Promise<T> {
      return new Promise((resolve: (value: T | PromiseLike<T> | any) => void, reject: (reason?: any) => void) => {
        xhr.onreadystatechange = function () {
          if (4 != xhr.readyState) return
          if (success(xhr)) return resolve(xhr)
          reject(xhr)
        }

        xhr.open(method, url || '', true)
        applyHeaders(xhr, headers)
        console.log("🚀 ~ returnnewPromise ~ form:", form)
        xhr.send(form)
      })
    }
  }.progress(() => false)
}
