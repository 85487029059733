import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "ls-notify-bar has-new" }
const _hoisted_2 = { class: "ls-notify-bar__icons-wrapper" }
const _hoisted_3 = { class: "ls-notify-bar__notify-icon" }
const _hoisted_4 = {
  key: 0,
  class: "ls-notify-bar__notify-quantity"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: "notify-click-wrapper",
    ref: $setup.ref
  }, [
    _createVNode(_component_router_link, {
      class: "ds-link ds-link--full_width",
      to: { name: $setup.NOTIFICATIONS_PAGE_NAME }
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              (_openBlock(), _createBlock(_resolveDynamicComponent('i-notify')))
            ]),
            ($setup.unreadCount)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("span", null, _toDisplayString($setup.unreadCount), 1)
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _: 1
    }, 8, ["to"])
  ]))
}