/* eslint-disable */

import { addHours } from 'date-fns'

/**
 * get cookie
 * @param {*} name
 */
export function getCookie(name) {
  const matches = document.cookie.match(
    new RegExp(
      `(?:^|; )${name.replace(
        /([\.$?*|{}\(\)\[\]\\\/\+^])/g,
        '\\$1'
      )}=([^;]*)`
    )
  )
  return matches ? decodeURIComponent(matches[1]) : undefined
}

const stringifyValue = val => typeof val === 'string' ? val : JSON.stringify(val)

const bakeSessionCookie = (name, value, resolve) => {
  const cookie = [name, '=', stringifyValue(value), ';expires=Session', '; path=/;'].join('')
  document.cookie = cookie
  resolve(true)
}

const ONE_MONTH_HOURS_COUNT = 30 * 1 * 24

const bakeCookie = (name, value, resolve, hours=ONE_MONTH_HOURS_COUNT) => {
  let date = new Date()
  date = addHours(date, hours)
  const cookie = [name, '=', stringifyValue(value), ';expires', '=', date.toUTCString(), '; path=/;'].join('')
  document.cookie = cookie
  resolve(true)
}

/**
 * set cookie without expired date
 * @param {*} name
 * @param {*} val
 */
export function setCookie(name, val) {
  // if (this.getCookie(name) ) return
  return new Promise((resolve, reject) => {
    bakeCookie(name, val, resolve)
  })
}
// export function setCookie(name, val, hours = ONE_MONTH_HOURS_COUNT) {
//   // if (this.getCookie(name) ) return
//   bakeCookie(name, val, hours)
// }
