import { App } from 'vue'

import CompanyProvider from './components/CompanyProvider.vue'

export default {
  install(app: App<Element>): void {
    app
      .component('CompanyProvider', CompanyProvider)
  }
}
