import { PropType } from 'vue-demi'
import { ClassValue } from 'clsx'
import { ModifierValue, ModifiersDefinition } from '@aspectus/bem'
import { BemElement, OptionalStyleRules } from '@aspectus/bem-classes'

export type ModifierPropType = PropType<ModifierValue>
export type ModifierProp = {
  type: ModifierPropType
}
export type ClassesPropType<Element extends string = string> = PropType<OptionalStyleRules<BemElement | ClassValue, Element>>
export type ClassesPropDefinition<Element extends string = string> = {
  classes: { type: ClassesPropType<Element | 'root'> },
  m: { type: PropType<ModifiersDefinition> },
  s: { type: PropType<ModifiersDefinition> },
}
export type PropsRules<PropKey extends string = string> = Record<PropKey, ModifierProp>
export type MadeProps<Element extends string, Specifier extends string> = ClassesPropDefinition<Element> & PropsRules<Specifier>

/**
 * Creates Vue props definition object.
 *
 * ```js
 * import { defineComponent } from 'vue'
 * import { makeProps } from '@aspectus/vue-bem-classes'
 *
 * export default defineComponent({
 *   props: makeProps(['header', 'footer'], ['disabled', 'focused']),
 * })
 * ```
 *
 * @category Functions
 * @export
 * @template Element - Element names type definition.
 * @template Specifier - Specifier names type definition.
 * @param elements - List ob all possible elements.
 * @param specifiers - List of all modifiers and states props that component could have. <br />
 *   Additionally to `specifiers` there also will be defined 3 generic prop: <br />
 *   <ul>
 *   <li> `classes` - Object prop for all mixes(see [`makeClasses` factory](./bem-classes.html#makeclasses) result from `@aspectus/bem-classes`). </li>
 *   <li> `m` - Prop for custom modifiers object. Optional. </li>
 *   <li> `s` - Prop for custom states object. Optional. </li>
 *   </ul>
 * @returns Vue props definition object.
 */
export function makeProps<Element extends string, Specifier extends string>(
  elements: Element[], specifiers: Specifier[]
): MadeProps<Element, Specifier> {
  return specifiers.reduce(
    (acc: any, specifierName: Specifier) => {
      acc[specifierName] = {
        type: <ModifierPropType>[Boolean, Number, String, Object, Array],
        required: false,
      }

      return acc
    },
    {
      classes: { type: Object as ClassesPropType<Element>, required: false },
      m: { type: Object as unknown as ModifiersDefinition, required: false },
      s: { type: Object as unknown as ModifiersDefinition, required: false },
    }
  )
}
