import { App } from 'vue'
import { createI18n } from "vue-i18n"
declare global {
  interface Window {
    language: string
    django: {
      catalog: {
        [key: string]: string
      }
    }
  }
}

const messages = {
  [window.language]: window.django && window.django.catalog || {},
}
type compileMessages = {
  locale: string,
  messages: typeof messages
}

export const compileMessages = (): compileMessages => ({
  locale: window.language,
  messages,
})

// const datetimeFormats = {
//   [window.language]: {
//     shortDate: SHORT_DATE_CONFIG,
//     date: DATE_CONFIG,
//     time: TIME_CONFIG,
//     month: { year: NUMERIC, month: LONG },
//     datetime: DATETIME_CONFIG,
//   },
// };

const i18nOptions = {
  ...compileMessages(),
  silentTranslationWarn: true,
  missingWarn: false,
  fallbackWarn: false,
  legacy: false,
  // datetimeFormats,
}

export const i18n = createI18n(i18nOptions)


export default {
  install(app: App<Element>): void {

    app.use(i18n)
  }
}
