import {
  createSender,
  createOptionsGetter,
  createReceiver,
  createUpdater,
  createRemover,
} from '@/resource/resource'

import {
  prefixAPI,
} from '@/resource/api'

const MAIN_MODEL = 'warehouse'
const TYPE_MODEL = 'warehouse-type'
const SCHEME_MODEL = 'warehouse-scheme'

// const AUTHENTICATE_LINK = prefixAPI('/login/', MODEL)

const WAREHOUSE_CREATE_LINK = prefixAPI('/create/', MAIN_MODEL)
const WAREHOUSE_DETAILS_LINK = prefixAPI('/{id}/', MAIN_MODEL)
const WAREHOUSE_LOAD_LINK = prefixAPI('/get-load/{id}/', MAIN_MODEL)
const WAREHOUSE_LOAD_DAY_LINK = prefixAPI('/get-load-day/{id}/', MAIN_MODEL)
const WAREHOUSE_UPDATE_LINK = prefixAPI('/update/{id}/', MAIN_MODEL)
const WAREHOUSE_LIST_LINK = prefixAPI('/list/{?limit,offset,search,type,id,full,warehouse}', MAIN_MODEL)
const WAREHOUSES_TYPES_LIST_LINK = prefixAPI('/list/{?limit,offset}', TYPE_MODEL)
const WAREHOUSE_TYPE_CREATE_LINK = prefixAPI('/create/', TYPE_MODEL)
const WAREHOUSE_TYPE_UPDATE_LINK = prefixAPI('/update/{id}/', TYPE_MODEL)
const WAREHOUSE_TYPE_DELETE_LINK = prefixAPI('/delete/{id}/', TYPE_MODEL)
const WAREHOUSE_SCHEME_UPDATE_LINK = prefixAPI('/update/{id}/', SCHEME_MODEL)
const WAREHOUSE_SCHEME_LIST_LINK = prefixAPI('/list/{?limit,offset,id}', SCHEME_MODEL)
const WAREHOUSE_DETAILS_LIST_LINK = prefixAPI('/{id}/', SCHEME_MODEL)

export const warehouseGetLoadResource = createSender(WAREHOUSE_LOAD_LINK)
export const warehouseGetLoadDayResource = createSender(WAREHOUSE_LOAD_DAY_LINK)
export const warehouseCreateResource = createSender(WAREHOUSE_CREATE_LINK)
export const warehouseUpdateResource = createUpdater(WAREHOUSE_UPDATE_LINK)
export const warehouseTypeCreateResource = createSender(WAREHOUSE_TYPE_CREATE_LINK)
export const warehouseTypeUpdateResource = createUpdater(WAREHOUSE_TYPE_UPDATE_LINK)
export const warehouseTypeDeleteResource = createRemover(WAREHOUSE_TYPE_DELETE_LINK)
export const warehousesTypesListResource = createReceiver(WAREHOUSES_TYPES_LIST_LINK)
export const warehousesListResource = createReceiver(WAREHOUSE_LIST_LINK)
export const warehousesDetailsResource = createReceiver(WAREHOUSE_DETAILS_LINK)
export const warehouseTypeOptionsResource = createOptionsGetter(WAREHOUSE_TYPE_CREATE_LINK)
export const warehouseOptionsResource = createOptionsGetter(WAREHOUSE_CREATE_LINK)
export const warehouseListOptionsResource = createOptionsGetter(WAREHOUSE_LIST_LINK)
export const warehouseSchemeUpdateResource = createUpdater(WAREHOUSE_SCHEME_UPDATE_LINK)
export const warehouseSchemeListResource = createReceiver(WAREHOUSE_SCHEME_LIST_LINK)
export const warehouseDetailsListResource = createReceiver(WAREHOUSE_DETAILS_LIST_LINK)
