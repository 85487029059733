/* eslint-disable */
const zero = 0
const one = 1
export class LocalStorageInterface {
  get length() { return zero }

  // clear() {}

  getItem(key, dflt = null) {}

  key() {}

  removeItem() {}

  setItem() {}
}

class DummyLocalStorage extends LocalStorageInterface {
  get length() { return one }

  getItem(key, dflt = null) { return dflt }

  key() { return '' }
}

export class ServerLocalStorage extends DummyLocalStorage {}

export class ClientLocalStorage extends LocalStorageInterface {
  constructor() {
    super()
    this.storage = window.localStorage
  }

  get length() {
    return this.storage.length
  }

  getItem(key, dflt = null, setIfDefault = false) {
    let data = this.storage.getItem(key)

    try {
      data = JSON.parse(data)
    } catch (e) {
      console.error('get', key, e, data)
    }

    if (!data && setIfDefault) {
      this.setItem(key, dflt)
    }

    return data || dflt
  }

  removeItem(key) {
    return this.storage.removeItem(key)
  }

  setItem(key, value) {
    let data = value

    try {
      data = JSON.stringify(data)
    } catch (e) {
      console.error('set', key, e, data)
    }

    return this.storage.setItem(key, data)
  }

  clear() {
    return this.storage.clear()
  }

  key(index) {
    return this.storage.key(index)
  }
}

export const LocalStorage = ClientLocalStorage

/**
 * Function creates a local storage for a global use.
 *
 * On server - ist's only a dummy local storage, to not brake api.
 * On client - it mimics global LocalStorage object.
 *
 * DO NOT use it on server in an objects, that will be used in future.
 *
 * @export
 */
export function getGlobal() {
  return new ClientLocalStorage()

  // return new DummyLocalStorage()
}

export const storage = getGlobal()
