import { RouteRecordRaw } from 'vue-router';
import { route, prefixRoutes } from '@/router/utils'
import { allPermissions } from '@access/index'
import { accessPermission } from '@access/permissions'
import {
  createRoutes as createSettingsCategoriesRoutes,
  CATEGORIES_PAGE_NAME,
} from './views/router'

const Settings = () => import('./views/Settings.vue')

export const SETTINGS_PAGE_NAME = 'cabinet:settings'

export const SETTINGS_PAGE_URL = ''

const { STAFF: { SETTINGS: { VIEW_LIST } } } = allPermissions

export function createRoutes(): RouteRecordRaw[] {
  return [
    route(SETTINGS_PAGE_URL, Settings, SETTINGS_PAGE_NAME, {
      children: [
        // prefixRoutes('/login/', createAuthRoutes()),
        {
          ...prefixRoutes('', createSettingsCategoriesRoutes()),
          path: ''
        },
        // {
        //   path: '',
        //   redirect: (to: RouteLocationNormalized) => to.fullPath + 'design/',
        // },
      ],
      redirect: { name: CATEGORIES_PAGE_NAME },
      // meta: accessMeta(toBeAuthenticated, { name: 'auth:login' }, options),
      meta: {
        breadcrumb: 'settings',
        ...accessPermission(VIEW_LIST)
      }
    }),
  ]
}
