import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-control-input d-control-input--appearance_bordered input-main-gray auth-style" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", _mergeProps({ class: "d-control-input__element" }, _ctx.$attrs, {
      autocomplete: "off",
      type: "email",
      onBlur: $setup.checkEmail
    }), null, 16)
  ]))
}