import {
  Ref, unref, watchEffect, shallowRef,
} from 'vue-demi'
import {
  makeClasses as base,
  StyleRules,
  BemElement,
  BemClassesConfig,
  BemClassesMixFunction,
} from '@aspectus/bem-classes'
import { makeProps, MadeProps } from './props'
import { ModifiersDefinition } from '@aspectus/bem'

const CLASSES_KEY = 'classes'
const MODIFIERS_PROP = 'm'
const STATES_PROPS = 's'

export type BemReactiveClassesMixFunction<
  ClassDefinition extends any = BemElement,
  ClassKey extends string = string
> = (props: any, context?: any) => Ref<StyleRules<ClassDefinition, ClassKey>>

/**
 * Creates classes generator(`makeClasses` from [bem-classes](./bem-classes.md)) and props definition(`makeProps`).
 *
 * Parameters `config`, `blockName`, `elements` is the same as for `makeClasses` from [bem-classes](./bem-classes.md#makeclassname)
 *
 * ```js
 * import { makeClasses } from '@aspectus/vue-bem-classes'
 *
 * const CONFIG = { n: '', e: '__', m: '--', v: '_', sp: 'is-', sv: '_' }
 * const [useClasses, propsDefinition] = makeClasses(
 *   CONFIG, 'block', ['inner'], ['modifier'], ['disabled', 'focused']
 * )
 * ```
 *
 * - `useClasses` - Here is the result of `bem-classes` `makeClasses` call. See [here](./bem-classes.md#makeclassname).
 * - `propsDefinition` - Is the result of `makeProps`, described above.
 *
 * @category General
 * @export
 * @template Element - String type for element names.
 * @template Modifier - String type for modifier names.
 * @template State - String type for state names.
 * @param config - Name prefixes/delimiters.
 * @param blockName - Block name.
 * @param elements - List ob all possible elements.
 * @param modifiers - List of all modifiers props that component could have.
 * @param states - List of all states props that component could have.
 * @returns Classes factory function and vue props definition.
 */
export function makeClasses<
  Element extends string,
  Modifier extends string,
  State extends string
>(
  config: BemClassesConfig,
  blockName: string,
  elements: Element[] = [],
  modifiers: Modifier[] = [],
  states: State[] = []
): [
  BemClassesMixFunction<BemElement, Element | 'root'>,
  MadeProps<Element, State & Modifier>
] {
  return [
    base(config, blockName, elements),
    makeProps(elements, <(State & Modifier)[]>[...modifiers, ...states])
  ]
}

function collectValuesFromContext(
  modifiers: string[],
  states: string[],
  context: any = {},
): [ModifiersDefinition, ModifiersDefinition] {
  const resolveReducer = (acc: any, name: string) => {
    acc[name] = unref(context[name])
    return acc
  }

  return [
    modifiers.reduce(resolveReducer, unref(context[MODIFIERS_PROP]) || {}),
    states.reduce(resolveReducer, unref(context[STATES_PROPS]) || {})
  ]
}

/**
 * Same as `makeClasses`, but it creates reactive classes resolver.
 * Parameters are also same as `makeClasses`.
 *
 * Classes factory produces `ref` to make `classes` reactive and updates
 * on props or provided context change.
 *
 * ```js
 * // Container.js
 * import { defineComponent, ref } from 'vue'
 * import { makeReactiveClasses } from '@aspectus/vue-bem-classes'
 * import multiproxy from '@aspectus/multiproxy'
 * import omit from 'ramda/src/omit'
 *
 * const CONFIG = { n: '', e: '__', m: '--', v: '_', sp: 'is-', sv: '_' }
 * const [useClasses, propsDefinition] = makeReactiveClasses(
 *   CONFIG, 'block', ['inner'], ['modifier'], ['disabled', 'focused']
 * )
 *
 * export default defineComponent({
 *   // We doesn't need `focused` property, it will be calculated internally.
 *   props: omit(['focused'], propsDefinition),
 *   setup(props) {
 *     const focused = ref(false)
 *     // We should use multiproxy here to save `props` object reactivity.
 *     const classes = useClasses(multiproxy(props, { focused }))
 *
 *     // NOTE: WORKAROUND:
 *     // You may consider to use `toRefs` for the same effect.
 *     // But you shouldn't.
 *     // `props` is a reactive object, so it's properties may be added
 *     // or removed.
 *     // During `setup` there might be the case when not all properties exists
 *     // in props, for example. And so on and so forth.
 *     // So this:
 *     // const classes = useClasses({ ...toRefs(props), focused })
 *     // Is not the case.
 *
 *     return { classes, focused }
 *   },
 *   template: `
 *     <div :class="classes.root.value">
 *       <div :class="classes.inner.value">
 *         <slot />
 *       </div>
 *     </div>
 *   `
 * })
 * ```
 *
 * Generator `useClasses` accepts 1 parameter:
 *
 * - `context?` - Optional context. It might be `props` or you may additionally
 *   pass object with modifier or state values that, for example are
 *   `computed`s or calculates in some other way. This context must be
 *   either a `reactive`, or object with `ref`/`reactive` values.
 *
 * It returns a `ref` with classes value inside. On every change of
 * modifiers or states it will be respectively changed.
 *
 * @category General
 * @export
 * @template Element - String type for element names.
 * @template Modifier - String type for modifier names.
 * @template State - String type for state names.
 * @param config - Name prefixes/delimiters.
 * @param blockName - Block name.
 * @param elements - List ob all possible elements.
 * @param modifiers - List of all modifiers props that component could have.
 * @param states - List of all states props that component could have.
 * @returns Reactive classes factory function and vue props definition.
 */
export function makeReactiveClasses<
  Element extends string,
  Modifier extends string,
  State extends string
>(
  config: BemClassesConfig,
  blockName: string,
  elements: Element[] = [],
  modifiers: Modifier[] = [],
  states: State[] = []
): [
  BemReactiveClassesMixFunction<BemElement, Element | 'root'>,
  MadeProps<Element, State & Modifier>
] {
  const [useClassesBase, propsDefinition] = makeClasses(
    config, blockName, elements, modifiers, states
  )

  function useClasses(context: any = {}) {
    const classesResolver = () => {
      const initial = useClassesBase(unref(context[CLASSES_KEY]))
      const [m, s] = collectValuesFromContext(modifiers, states, context)

      return { ...initial, root: initial.root.m(m).s(s) }
    }
    const classesRef = shallowRef() as Ref<ReturnType<typeof classesResolver>>
    watchEffect(() => {
      classesRef.value = classesResolver()
    })

    return classesRef
  }

  return [useClasses, propsDefinition]
}
