import { App } from 'vue'
import lunarCalendar from 'vue-lunar-calendar-fork'


/* eslint-disable */
export default {
  install(app: App<Element>) {
    app.component("lunarCalendar", lunarCalendar)
  }
}
