import { route } from '@/router/utils'
import { RouteRecordRaw } from 'vue-router'

const Login = () => import('./Auth.vue')
// const PasswordReset = () => import('./views/PasswordReset.vue')
// // const PasswordConfirm = () => import('./views/PasswordConfirm');
// const PasswordSet = () => import('./views/PasswordSet.vue')

export const AUTH_LOGIN_NAME = 'auth:login'
export const AUTH_LOGIN_URL = ''
// export const AUTH_PASSWORD_RESET = 'auth:password-reset'
// export const AUTH_PASSWORD_SET = 'auth:password-set'

export function createRoutes(): RouteRecordRaw[] {
  return [
    route(AUTH_LOGIN_URL, Login, AUTH_LOGIN_NAME),
    // route('password/reset/', PasswordReset, AUTH_PASSWORD_RESET),
    // route('password/set/:token/', PasswordSet, AUTH_PASSWORD_SET),
    // // route('password/confirm/', PasswordConfirm, 'auth:password-confirm'),

    // { path: '', redirect: { name: 'auth:login' } },
  ]
}