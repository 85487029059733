import { RouteRecordRaw } from 'vue-router';
import { route } from '@/router/utils'
import { allPermissions } from '@access/index'
import { accessPermission } from '@access/permissions'

const tariffsList = () => import('./views/List.vue')
const tariffCreate = () => import('./views/Create.vue')
const tariffDetail = () => import('./views/details/Details.vue')
// const rentUnitEdit = () => import('./views/RentUnitEdit.vue')
// const storageFeeEdit = () => import('./views/StorageFeeEdit.vue')

// NAMES
export const TARIFFS_LIST_PAGE_NAME = 'cabinet:tariffs-list'
export const TARIFF_CREATE_PAGE_NAME = 'cabinet:tariff-create'
export const TARIFF_UPDATE_PAGE_NAME = 'cabinet:tariff-update'
export const TARIFF_DETAILS_PAGE_NAME = 'cabinet:tariff-details'

// URLs
export const TARIFFS_LIST_PAGE_URL = 'list/'
export const TARIFF_CREATE_PAGE_URL = 'create/'
export const TARIFF_UPDATE_PAGE_URL = 'edit/:id/'
export const TARIFF_DETAILS_PAGE_URL = 'view/:id/'
// export const PARNTNERSHIP_APP_CREATE_PAGE_URL = 'create/'
// export const PARNTNERSHIP_APP_UPDATE_PAGE_URL = 'edit/:id?/'

const {
  STAFF: {
    TARIFF_PLAN: {
      VIEW,
      VIEW_LIST,
      CREATE,
      EDIT,
      DELETE
    },
  },
} = allPermissions

export function createRoutes(): RouteRecordRaw[] {
  return [
    route(TARIFFS_LIST_PAGE_URL, tariffsList, TARIFFS_LIST_PAGE_NAME, {
      meta: {
        breadcrumb: 'tariffs',
        ...accessPermission(VIEW_LIST)
      }
    }),
    route(TARIFF_CREATE_PAGE_URL, tariffCreate, TARIFF_CREATE_PAGE_NAME, {
      meta: {
        editable: true,
        isNew: true,
        breadcrumb: 'tariffs',
        redirect: TARIFFS_LIST_PAGE_NAME,
        ...accessPermission(CREATE, EDIT)
      },
    }),
    route(TARIFF_UPDATE_PAGE_URL, tariffCreate, TARIFF_UPDATE_PAGE_NAME, {
      meta: {
        editable: true,
        isNew: false,
        breadcrumb: 'tariffs',
        redirect: TARIFFS_LIST_PAGE_NAME,
        ...accessPermission(VIEW, EDIT)
      },
    }),
    route(TARIFF_DETAILS_PAGE_URL, tariffDetail, TARIFF_DETAILS_PAGE_NAME, {
      meta: {
        breadcrumb: 'tariffs',
        redirect: TARIFFS_LIST_PAGE_NAME,
        ...accessPermission(VIEW)
      },
    }),
  ]
}
