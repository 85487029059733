<template>

<component :is="as" :class="classes.root.value">
  <component :is="gui" :class="classes.gui.value" @save="onSave" v-bind="guiAttrs">
    <!-- Passing slots inside. -->
    <template v-for="(_, sn) in slots" v-slot:[sn]="sd">
      <slot :sn="sn" :name="sn" v-bind="sd" />
    </template>
  </component>

  <!-- <div :class="classes.overlay.value" v-if="load">
    <div :class="classes.controls.value">
      <div :class="classes.control.m({ 'contains': 'progress' }).value">
        <up-progress :value="progress + progressShift" :class="classes.progress.value" />
      </div>
      <div :class="classes.control.value">
        <ControlButton @click="cancel()" appearance="square" type="button" :class="classes.cancel.value">
          <i-cancel></i-cancel>
        </ControlButton>
      </div>
    </div>
  </div> -->
</component>

</template>

<script lang="ts">

import { ref, computed, watch }  from 'vue'
import type { SetupContext }  from 'vue'

import multiproxy from '@aspectus/multiproxy'
import { extendComponent } from '@aspectus/vue-bem-classes'

import { makeLoading } from '@aspectus/vue-loading'
import { upload } from '../upload'
import type { UploadOptions, BlobWithName } from '../upload'

import ControlButton from './Button.vue'

interface Props {
  gui?: string,
  url?: string,
  uploadOptions: UploadOptions,
  progressShift: number,
}

const [useClasses, Component]: any  = extendComponent('up-uploader', {
  emits: ['save', 'file', 'progress', 'error', 'update:loading'],
  props: {
    gui: { default: () => 'up-image' },
    url: { type: String, default: '' },
    uploadOptions: { type: Object, default: () => ({}) },
    // This used to display user that some amount of file already uploaded.
    // Even if it's not.
    progressShift: { type: Number, default: 0.01 },
  },
  components: { ControlButton },
  setup(props: Props, {slots, emit, attrs }: SetupContext) {

    const proxiedProps = multiproxy(props)
    const classes = useClasses(proxiedProps)
    const progress = ref(0)
    const [load, loading] = makeLoading(0)
    const cancel = ref(() => ({}))

    // console.log(load, loading)
    const setPogress = (v: number) => {
      progress.value = v
      emit('progress', progress.value)
    }

    function onSave(result: BlobWithName | null) {
      emit('file', {result, url: proxiedProps.url, options: proxiedProps.uploadOptions, upload: upload, loading: loading})
      console.log("🚀 ~ file: Uploader.vue:75 ~ onSave ~ result:", result.size / 1024000)

      if (!result || !proxiedProps.url) return emit('save', null)
      const uploader = upload({ url: proxiedProps.url, ...proxiedProps.uploadOptions }, result)
        .progress((e: ProgressEvent) => setPogress(e.loaded / e.total * (1 - proxiedProps.progressShift)))

      cancel.value = uploader.abort.bind(uploader)

      loading(uploader.promise()
        .then((xhr: any) => {
          emit('save', xhr)
        })
        .catch((xhr: any) => {
          emit('error', xhr)
        })
        .finally(() => setPogress(0))
      )
    }

    watch([load], (value, old) => {
      if (value !== old) {
        emit('update:loading', value)
      }
    })

    const guiAttrs = computed(() => ({ ...attrs, class: undefined, style: undefined }))

    return {
      classes,
      loading,
      onSave,
      cancel,
      progress,
      guiAttrs,
      slots,
      load,
    }
  }
}, [
  'gui',
  'overlay',
  'controls', 'control',
  'progress',
  'cancel',
])

export default Component

</script>

<style lang="sass">

.up-uploader
  position: relative
  display: flex

  &__
    &gui
      flex-grow: 1
      width: 100%

    &overlay
      position: absolute
      top: -4px
      left: -4px
      right: -4px
      bottom: -4px
      border-radius: var(--b-size)
      backdrop-filter: blur(2px)

    &controls
      position: absolute
      left: 50%
      top: 50%
      transform: translateX(-50%) translateY(-50%)
      display: flex
      flex-wrap: no-wrap
      width: 90%

    &control
      margin: 0

      &--
        &contains_
          &progress
            flex-grow: 1

            position: relative
            user-select: none

            background: rgba(black, 0.4)
            color: white
            display: flex
            align-items: center
            justify-content: center
            height: 36px
            padding: 0 var(--space-3)
    // &cancel
    //   color: var(--theme-accent-red)

    &progress
      flex-grow: 1

</style>
