import { RouteRecordRaw } from 'vue-router';
import { route } from '@/router/utils'
import { allPermissions } from '@access/index'
import { accessPermission } from '@access/permissions'

const Profile = () => import('./views/Profile.vue')

export const PROFILE_PAGE_NAME = 'cabinet:profile'

export const PROFILE_PAGE_URL = ''


const {
  STAFF: {
    PROFILE: {
      VIEW: PROFILE_VIEW,
    },
  },
} = allPermissions

export function createRoutes(): RouteRecordRaw[] {
  return [
    route(PROFILE_PAGE_URL, Profile, PROFILE_PAGE_NAME, {
      meta: {
        breadcrumb: 'profile',
        ...accessPermission(PROFILE_VIEW)
      },
    }),
  ]
}
