import { App } from 'vue'
import IEye from './i-eye.vue'
import IEyeOff from './i-eye-off.vue'
import iConfig from './i-config.vue'
import iArrowRight from './i-arrow-right.vue'
import iFullArrowRight from './i-full-arrow-right.vue'
import iAdd from './i-add.vue'
import iExtend from './i-extend.vue'
import iMove from './i-move.vue'
import iPut from './i-put.vue'
import iExport from './i-export.vue'
import iQr from './i-qr.vue'
import iCancel from './i-cancel.vue'
import iCircle from './i-circle.vue'
import iClose from './i-close.vue'
import iUser from './i-user.vue'
import iLogout from './i-logout.vue'
import iNotify from './i-notify.vue'
import iDot from './i-dot.vue'
import iSave from './i-save.vue'
import iStorage from './i-storage.vue'
import iStorageBig from './i-storage-big.vue'
import iSidebarApplications from './i-sidebar-applications.vue'
import iSidebarPartnershipApps from './i-sidebar-partnership-apps.vue'
import iSidebarClients from './i-sidebar-clients.vue'
import iSidebarUsers from './i-sidebar-users.vue'
import iSidebarFinancial from './i-sidebar-financial.vue'
import iSidebarOrders from './i-sidebar-orders.vue'
import iSidebarStatistics from './i-sidebar-statistics.vue'
import iSidebarStorages from './i-sidebar-storages.vue'
import iSidebarTransactions from './i-sidebar-transactions.vue'
import iSidebarSettings from './i-sidebar-settings.vue'
import iSidebarCompanies from './i-sidebar-companies.vue'
import iSidebarInvoice from './i-sidebar-invoice.vue'
import iSidebarTariffs from './i-sidebar-tariffs.vue'
import iCardVisa from './i-card-visa.vue'
import iCardMastercard from './i-card-mastercard.vue'
import iTrash from './i-trash.vue'
import iAddSimple from './i-add-simple.vue'
import iCheck from './i-check.vue'
import iList from './i-list.vue'
import iClipboard from './i-clipboard.vue'
import iWallet from './i-wallet.vue'
import iEdit from './i-edit.vue'
import iBlocksList from './i-blocks-list.vue'
import iScheme from './i-scheme.vue'
import iChevronLeft from './i-chevron-left.vue'
import iChevronRight from './i-chevron-right.vue'
import iXCircle from './i-x-circle.vue'
import iXCircleBold from './i-x-circle-bold.vue'
import iLayout from './i-layout.vue'
import iArrowLeft from './i-arrow-left.vue'
import iFolderClock from './i-folder-clock.vue'
import iHistory from './i-history.vue'
import iCloseLarge from './i-close-large.vue'
import iFilters from './i-filters.vue'
import iPlace from './i-place.vue'
import iTransactions from './i-transactions.vue'
import iViewAll from './i-view-all.vue'
import iHistoryFolder from './i-history-folder.vue'
import iPin from './i-pin.vue'
import iCalendar from './i-calendar.vue'
import iDate from './i-date.vue'
import iSearch from './i-search.vue'
import iImplement from './i-implement.vue'
import iUnread from './i-unread.vue'
import iReload from './i-reload.vue'
import iLetter from './i-letter.vue'
import iInfo from './i-info.vue'
import iCopy from './i-copy.vue'
import iActivity from './i-activity.vue'
import iSliderArrow from './i-slider-arrow.vue'
import iLogoSimple from './i-logo-simple.vue'
import iRectangleRight from './i-rectangle-right.vue'
import iQuotes from './i-quotes.vue'
import iPlus from './i-plus.vue'
import iMinus from './i-minus.vue'
import iCompany from './i-company.vue'
import iDocs from './i-docs.vue'
import iDisplay from './i-display.vue'

const register = (app: App<Element>): void => {
  app
  .component('iFullArrowRight', iFullArrowRight)
  .component('iDisplay', iDisplay)
  .component('iSidebarTariffs', iSidebarTariffs)
  .component('iSidebarInvoice', iSidebarInvoice)
  .component('iSidebarCompanies', iSidebarCompanies)
  .component('iSidebarPartnershipApps', iSidebarPartnershipApps)
  .component('iDocs', iDocs)
  .component('iCompany', iCompany)
  .component('iPlus', iPlus)
  .component('iMinus', iMinus)
  .component('iQuotes', iQuotes)
  .component('iRectangleRight', iRectangleRight)
  .component('iLogoSimple', iLogoSimple)
  .component('iSliderArrow', iSliderArrow)
  .component('iActivity', iActivity)
  .component('iCopy', iCopy)
  .component('iInfo', iInfo)
  .component('iLetter', iLetter)
  .component('iImplement', iImplement)
  .component('iReload', iReload)
  .component('iUnread', iUnread)
  .component('iSearch', iSearch)
  .component('iCalendar', iCalendar)
  .component('iDate', iDate)
  .component('iPin', iPin)
  .component('iViewAll', iViewAll)
  .component('iTransactions', iTransactions)
  .component('iPlace', iPlace)
  .component('iFilters', iFilters)
  .component('iCloseLarge', iCloseLarge)
  .component('iHistory', iHistory)
  .component('iHistoryFolder', iHistoryFolder)
  .component('iFolderClock', iFolderClock)
  .component('iArrowLeft', iArrowLeft)
  .component('iLayout', iLayout)
  .component('iXCircle', iXCircle)
  .component('iXCircleBold', iXCircleBold)
  .component('iChevronLeft', iChevronLeft)
  .component('iChevronRight', iChevronRight)
  .component('iScheme', iScheme)
  .component('iBlocksList', iBlocksList)
  .component('iEdit', iEdit)
  .component('iWallet', iWallet)
  .component('iClipboard', iClipboard)
  .component('iList', iList)
  .component('iCheck', iCheck)
  .component('iAddSimple', iAddSimple)
  .component('iTrash', iTrash)
  .component('iCardMastercard', iCardMastercard)
  .component('iCardVisa', iCardVisa)
  .component('iSidebarApplications', iSidebarApplications)
  .component('iSidebarClients', iSidebarClients)
  .component('iSidebarUsers', iSidebarUsers)
  .component('iSidebarFinancial', iSidebarFinancial)
  .component('iSidebarOrders', iSidebarOrders)
  .component('iSidebarStatistics', iSidebarStatistics)
  .component('iSidebarStorages', iSidebarStorages)
  .component('iSidebarTransactions', iSidebarTransactions)
  .component('iSidebarSettings', iSidebarSettings)
  .component('IEye', IEye)
  .component('IEyeOff', IEyeOff)
  .component('iConfig', iConfig)
  .component('iArrowRight', iArrowRight)
  .component('iAdd', iAdd)
  .component('iExtend', iExtend)
  .component('iMove', iMove)
  .component('iPut', iPut)
  .component('iExport', iExport)
  .component('iQr', iQr)
  .component('iCancel', iCancel)
  .component('iCircle', iCircle)
  .component('iClose', iClose)
  .component('iUser', iUser)
  .component('iLogout', iLogout)
  .component('iNotify', iNotify)
  .component('iDot', iDot)
  .component('iStorage', iStorage)
  .component('iStorageBig', iStorageBig)
  .component('iSave', iSave)
}

export default {
  register,
}
