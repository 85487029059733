import { App } from 'vue'
import Tabs from './tabs/Tabs.vue'
import Tab from './tabs/Tab.vue'
import UiLimitPagination from './pagination/UiLimitPagination.vue'
import UiModalsContainer from './modals/Container.vue'
import UiMessageModal from './modals/UiMessageModal.vue'
import UiConfirmModal from './modals/UiConfirmModal.vue'
import ModalTrigger from './ModalTrigger.vue'
import SidebarTrigger from './SidebarTrigger.vue'
import UiClickOutside from './UiClickOutside.vue'
import Table from './Table.vue'
import Loader from './loader.vue'
import UiAccordion from './parts/UiAccordion.vue'
import HiJack from './HiJack.vue'

const register = (app: App<Element>): void => {
  app
  .component('HiJack', HiJack)
  .component('Tab', Tab)
  .component('Tabs', Tabs)
  .component('UiTable', Table)
  .component('UiLimitPagination', UiLimitPagination)
  .component('ModalTrigger', ModalTrigger)
  .component('SidebarTrigger', SidebarTrigger)
  .component('UiClickOutside', UiClickOutside)
  .component('UiModalsContainer', UiModalsContainer)
  .component('UiMessageModal', UiMessageModal)
  .component('UiConfirmModal', UiConfirmModal)
  .component('Loader', Loader)
  .component('UiAccordion', UiAccordion)
  // .component('Sidebar', Sidebar)
}

export default {
  register,
}
