<template>
<component :is="as" :class="classes.root.value" v-bind="$attrs">
  <div :class="classes.bar.value" :style="{ width: percentage }" />
</component>
</template>

<script lang="ts">
import { computed } from 'vue'
import { extendComponent } from '@aspectus/vue-bem-classes'
import multiproxy from '@aspectus/multiproxy'

// eslint-disable-next-line
const [useClasses, Component] = extendComponent('up-progress', {
  props: { value: { type: Number, default: 0 } },
  setup(props: any) {
    const proxiedProps = multiproxy(props)
    const classes = useClasses(proxiedProps)
    const percentage = computed(() => `${(proxiedProps.value * 100) | 0}%`)
    const as = "up-progress"

    return { classes, percentage }
  }
}, ['bar'])
export default Component

</script>

<style lang="sass">

.up-progress
  --height: 8px

  position: relative
  user-select: none

  background: transparent
  display: block
  height: var(--height)
  display: flex
  align-items: center
  justify-content: flex-start

  &__
    &bar
      height: var(--height)
      background: var(--theme-primary-green)

</style>