import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ls-form ls-form--space_none ls-form--modal" }
const _hoisted_2 = { class: "ls-form__title ls-form__title--space-bottom_sm" }
const _hoisted_3 = { class: "ds-caption ds-caption--size_lg ds-caption--medium" }
const _hoisted_4 = { class: "error-label" }
const _hoisted_5 = { class: "ls-form__wrapper" }
const _hoisted_6 = { class: "g-row g-row--appearance_spaced g-row--space_2xl-lg g-row--justify_center g-row--justify_between-sm" }
const _hoisted_7 = { class: "g-cell g-cols g-cols--12" }
const _hoisted_8 = { class: "quill-wrapper quill-wrapper--readonly" }
const _hoisted_9 = { class: "quill-wrapper__label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VeeError = _resolveComponent("VeeError")!
  const _component_quill_editor = _resolveComponent("quill-editor")!
  const _component_VeeForm = _resolveComponent("VeeForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString($setup.form.title), 1)
    ]),
    _createVNode(_component_VeeForm, { onSubmit: $setup.request }, {
      default: _withCtx(({ errors, validate }) => [
        _createVNode(_component_VeeError, { name: "nonFieldErrors" }, {
          default: _withCtx(({ message }) => [
            _createElementVNode("div", _hoisted_4, _toDisplayString(message), 1)
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("span", null, _toDisplayString($setup.$t('Description')), 1)
                ]),
                _createVNode(_component_quill_editor, {
                  theme: "snow",
                  class: _normalizeClass('ls-quill-editor'),
                  "content-type": "html",
                  "read-only": true,
                  content: $setup.form.description,
                  "onUpdate:content": _cache[0] || (_cache[0] = ($event: any) => (($setup.form.description) = $event)),
                  toolbar: $setup.toolbarOptions
                }, null, 8, ["content"])
              ])
            ])
          ])
        ])
      ]),
      _: 1
    })
  ]))
}