/* eslint-disable */
export function isNullOrUndefined(value: unknown): value is undefined | null {
  return value === null || value === undefined;
}

export function isEmptyArray(arr: unknown): boolean {
  return Array.isArray(arr) && arr.length === 0;
}

export function getSingleParam<TParam = unknown>(params: [TParam] | Record<string, TParam>, paramName: string) {
  return Array.isArray(params) ? params[0] : params[paramName]
}

export function isEmpty(value: unknown): boolean {
  if (value === null || value === undefined || value === '') {
    return true
  }

  if (Array.isArray(value) && value.length === 0) {
    return true
  }

  return false
}

function required(this: any, value: any) {
  if (isNullOrUndefined(value) || isEmptyArray(value) || value === false || !value) {
    return this.config.globalProperties.$t('This field is required')
  }
  return true
}

function requiredCustom(this: any, value: any) {
  if (value === 0) return true
  if (isNullOrUndefined(value) || isEmptyArray(value) || value === false || !value) {
    return this.config.globalProperties.$t('This field is required')
  }
  return true
}

function email(this:any, value: any) {
  if (!value || !value.length) {
    return true
  }

  const EMAILREG = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (!EMAILREG.test(value)) {
    return this.config.globalProperties.$t('This field must be a valid email')
  }
  return true
}

function phone(this:any, value: any) {
  if (!value || !value.length) {
    return true
  }

  const PHONEREG = /^[0-9]+$/
  if (!PHONEREG.test(value)) {
    return this.config.globalProperties.$t('This field should only contain numbers')
  }
  return true
}

function monthYear(this:any, value: any) {
  if(value.length < 5 ) return this.config.globalProperties.$t('The date is incomplete')
  if (!value || !value.length) {
    return true
  }
  const DATEREG = /^(0[1-9]|1[0-2])\/\d{2}$/
  if (!DATEREG.test(value)) {
    return this.config.globalProperties.$t('This field must be a valid date')
  }
  return true
}


function confirmed(this: any, value: any, [target]: string)  {
  if (value === target) {
    return true
  }
  return this.config.globalProperties.$t('Password did not match')
}

function min(this: any, value: unknown, params: [string | number] | { length: string | number }): boolean | string  {
  if (isEmpty(value)) {
    return true
  }

  const length = getSingleParam(params, 'length')
  if (Array.isArray(value)) {
    return value.every(val => min(val, { length }))
  }

  if (String(value).length >= Number(length)) {
    return String(value).length >= Number(length)
  } else {
    return `${this.config.globalProperties.$t('Min')}:${length}`
  }
}

function maxValue(this: any, value: unknown, params: [string | number] | { length: string | number }): boolean | string  {
  if (!value) {
    return true
  }
  const length = getSingleParam(params, 'length')
  if (value <= length) {
    return Number(value) <= Number(length)
  } else {
    return `${this.config.globalProperties.$t('Max')}: ${length}`
  }
}

function minValue(this: any, value: unknown, params: [string | number] | { length: string | number }): boolean | string  {
  if (!value) {
    return true
  }
  const length = getSingleParam(params, 'length')
  if (value >= length) {
    return value >= length
  } else {
    return `${this.config.globalProperties.$t('Min')}: ${length}`
  }
}


function postBackUrl(this:any, value: any) {
  if (!value || !value.length) {
    return true
  }
  const URLREG = /^(https?|chrome):\/\/[^\s$.?#].[^\s].+\?*={click_id}.*$/
  if (!URLREG.test(value.replace('%7B', '{').replace('%7D', '}'))) {
    return this.config.globalProperties.$t('This field must be a valid url')
  }

  return true
}
export {
  required,
  email,
  confirmed,
  min,
  maxValue,
  minValue,
  postBackUrl,
  monthYear,
  phone,
  requiredCustom
}
