import { renderSlim } from '@aspectus/vue-utils';
import { hasAccess } from '@aspectus/permissions';
import { Tag } from '@aspectus/vue-tag';

export default {
  name: 'permissions-access-state',
  props: { permissions: Array },

  data() {
    return {
      hasAccess: false,
    };
  },

  watch: {
    permissions: { immediate: true, handler: 'setAccess' },
  },

  methods: {
    setAccess(permissions = this.permissions) {
      hasAccess(permissions || [], this)
        .then(() => this.hasAccess = true) // eslint-disable-line no-return-assign
        .catch(() => this.hasAccess = false); // eslint-disable-line no-return-assign
    },
  },

  render(h) {
    return renderSlim(
      this.$scopedSlots.default({ hasAccess: this.hasAccess }) || [], h, Tag, {
        attrs: this.$attrs,
        on: this.$listeners,
      }
    );
  },
};
