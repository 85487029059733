import {
  createSender,
  createOptionsGetter,
  createReceiver,
  createRemover,
  createUpdater,
} from '@/resource/resource'

import {
  prefixAPI,
} from '@/resource/api'

const MODEL = 'rent-object-type'

const RENT_OBJECT_TYPE_CREATE_LINK = prefixAPI('/create/', MODEL)
const RENT_OBJECT_TYPE_UPDATE_LINK = prefixAPI('/bulk-update/', MODEL)
const RENT_OBJECT_TYPE_DELETE_LINK = prefixAPI('/delete/{id}/', MODEL)
const RENT_OBJECT_TYPE_EDIT_LINK = prefixAPI('/update/{id}/', MODEL)
const RENT_OBJECT_TYPE_DETAIL_LINK = prefixAPI('/{id}/', MODEL)
const RENT_OBJECTS_TYPES_LIST_LINK = prefixAPI('/list/{?limit,offset,mechanics,warehouses_types,warehouses,with_prices}', MODEL)

export const rentObjectTypeCreateResource = createSender(RENT_OBJECT_TYPE_CREATE_LINK)
export const rentObjectTypeEditResource = createUpdater(RENT_OBJECT_TYPE_EDIT_LINK)
export const rentObjectTypeUpdateResource = createSender(RENT_OBJECT_TYPE_UPDATE_LINK)
export const rentObjectTypeDeleteResource = createRemover(RENT_OBJECT_TYPE_DELETE_LINK)
export const rentObjectTypesListResource = createReceiver(RENT_OBJECTS_TYPES_LIST_LINK)
export const rentObjectTypesDetailResource = createReceiver(RENT_OBJECT_TYPE_DETAIL_LINK)
export const rentObjectTypeOptionsResource = createOptionsGetter(RENT_OBJECT_TYPE_CREATE_LINK)
