import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "ls-header" }
const _hoisted_2 = { class: "ls-header__element ls-header__element--auto" }
const _hoisted_3 = { class: "ls-header__logo" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "ls-header__element ls-header__element--narrow" }
const _hoisted_6 = {
  key: 0,
  class: "ls-header__storage-wrapper"
}
const _hoisted_7 = { class: "ls-header__notify-wrapper" }
const _hoisted_8 = { class: "ls-header__userbar-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_storage_bar = _resolveComponent("storage-bar")!
  const _component_notify_bar = _resolveComponent("notify-bar")!
  const _component_user_bar = _resolveComponent("user-bar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        ($setup.company.logo)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "ls-header__image",
              src: $setup.company.logo || '/static/app/ls-logo.png',
              alt: "ls-logo-image"
            }, null, 8, _hoisted_4))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      (!$setup.isAdmin)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_storage_bar)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_notify_bar)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_user_bar)
      ])
    ])
  ]))
}