import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "ls-button" }
const _hoisted_2 = {
  key: 0,
  class: "ls-button__icon"
}
const _hoisted_3 = { class: "ls-button__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.iconName)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(), _createBlock(_resolveDynamicComponent($setup.iconName), { class: "button_img" }))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("span", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}