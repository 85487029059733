import { RouteRecordRaw } from 'vue-router';
import { route } from '@/router/utils'
import { allPermissions } from '@access/index'
import { accessPermission } from '@access/permissions'

const ordersList = () => import('./views/List.vue')
const orderDetails = () => import('./views/Details.vue')
const orderCreate = () => import('./views/Create.vue')
// const rentUnitEdit = () => import('./views/RentUnitEdit.vue')
// const storageFeeEdit = () => import('./views/StorageFeeEdit.vue')

// NAMES
export const ORDERS_LIST_PAGE_NAME = 'cabinet:orders-list'
export const ORDER_CREATE_PAGE_NAME = 'cabinet:order-create'
export const ORDER_UPDATE_PAGE_NAME = 'cabinet:order-update'
export const ORDER_VIEW_PAGE_NAME = 'cabinet:order'
export const ORDER_PAYMENT_PAGE_NAME = 'cabinet:order-payment'
// export const RENT_UNIT_EDIT_PAGE_NAME = 'cabinet:storage-rent-unit-update'
// export const STORAGE_FEE_EDIT_PAGE_NAME = 'cabinet:storage-storage-fee-update'

// URLs
export const ORDERS_LIST_PAGE_URL = ':date?/list/'
export const ORDER_CREATE_PAGE_URL = 'create/'
export const ORDER_UPDATE_PAGE_URL = 'edit/:id?/'
export const ORDER_VIEW_PAGE_URL = 'view/:id/'
// export const RENT_UNIT_EDIT_PAGE_URL = 'edit/:id?/rent-unit/:scheme_id?'
// export const STORAGE_FEE_EDIT_PAGE_URL = 'edit/:id/storage-fee/:scheme_id'

const {
  STAFF: {
    ORDER: {
      VIEW: ORDER_VIEW,
      EDIT: ORDER_EDIT,
      CREATE: ORDER_CREATE,
      DELETE: ORDER_DELETE,
      VIEW_LIST: ORDER_VIEW_LIST,
    },
  },
} = allPermissions

export function createRoutes(): RouteRecordRaw[] {
  return [
    route(ORDERS_LIST_PAGE_URL, ordersList, ORDERS_LIST_PAGE_NAME, {
      meta: {
        breadcrumb: 'orders',
        ...accessPermission(ORDER_VIEW_LIST)
      }
    }),
    route(ORDER_CREATE_PAGE_URL, orderCreate, ORDER_CREATE_PAGE_NAME, {
      meta: {
        editable: true,
        isNew: true,
        breadcrumb: 'order-create',
        redirect: ORDERS_LIST_PAGE_NAME,
        ...accessPermission(ORDER_CREATE)
      },
    }),
    route(ORDER_UPDATE_PAGE_URL, orderCreate, ORDER_UPDATE_PAGE_NAME, {
      meta: {
        editable: true,
        isNew: false,
        breadcrumb: 'order-update',
        redirect: ORDERS_LIST_PAGE_NAME,
        ...accessPermission(ORDER_EDIT)
      },
    }),
    route(ORDER_VIEW_PAGE_URL, orderDetails, ORDER_VIEW_PAGE_NAME, {
      meta: {
        editable: false,
        isNew: false,
        toPay: false,
        breadcrumb: 'order',
        redirect: ORDERS_LIST_PAGE_NAME,
        ...accessPermission(ORDER_VIEW)
      },
    }),
    route(ORDER_VIEW_PAGE_URL, orderDetails, ORDER_PAYMENT_PAGE_NAME, {
      meta: {
        editable: false,
        isNew: false,
        toPay: true,
        breadcrumb: 'order',
        redirect: ORDERS_LIST_PAGE_NAME,
        ...accessPermission(ORDER_VIEW)
      },
    }),

    // route(RENT_UNIT_EDIT_PAGE_URL, rentUnitEdit, RENT_UNIT_EDIT_PAGE_NAME, {
    //   props: true,
    //   meta: {
    //     breadcrumb: 'storages'
    //   },
    // }),
    // route(STORAGE_FEE_EDIT_PAGE_URL, storageFeeEdit, STORAGE_FEE_EDIT_PAGE_NAME),
  ]
}
