import { App } from 'vue'
// import { toBeAuthenticated } from '@/modules/users/permissions'
// import { accessMeta } from '@aspectus/vue-router-permissions'
import { route, prefixRoutes } from '@/router/utils'
import { optionsT } from '@/router'
import { RouteRecordRaw } from 'vue-router'

import { createRoutes as createSettingsRoutes } from './modules/settings/router'
import { createRoutes as createStatisticsRoutes } from './modules/statistics/router'
import { createRoutes as createTariffsStatisticsRoutes } from './modules/tariffs-statistics/router'
import { createRoutes as createApplicationsRoutes } from './modules/applications/router'
import { createRoutes as createPartnershipAppsRoutes, PARNTNERSHIP_APPS_LIST_PAGE_NAME } from './modules/partnership-apps/router'
import { createRoutes as createClientApplicationsRoutes } from './modules/client-applications/router'
import { createRoutes as createStoragesRoutes } from './modules/storages/router'
import { createRoutes as createClientsRoutes } from './modules/clients/router'
import { createRoutes as createUsersRoutes } from './modules/users/router'
import { createRoutes as createInvoicesRoutes } from './modules/invoice-templates/router'
import { createRoutes as createTariffsRoutes } from './modules/tariffs/router'
import { createRoutes as createUserTariffRoutes } from './modules/user-tariff/router'
import { createRoutes as createCompaniesRoutes } from './modules/companies/router'
import { createRoutes as createOrdersRoutes, ORDERS_LIST_PAGE_NAME } from './modules/orders/router'
import { createRoutes as createClientOrdersRoutes, CLIENT_ORDERS_LIST_PAGE_NAME } from './modules/client-orders/router'
import { createRoutes as createProfileRoutes, PROFILE_PAGE_NAME } from './modules/profile/router'
import { createRoutes as createTransactionsRoutes } from './modules/transactions/router'
import { createRoutes as createClientTransactionsRoutes } from './modules/client-transactions/router'
import { createRoutes as createNotificationsRoutes } from './modules/notifications/router'
import { createRoutes as createAccessDeniedRoutes } from './modules/403/router'
import { createRoutes as createPageNotFoundRoutes } from './modules/404/router'
import { createRoutes as createTextPageRoutes } from './modules/text-page/router'
// import { createRoutes as createAuthRoutes } from '@/components/pages/auth/router';

// import Comp from './views/Cabinet.vue'
import { isClient, isAdmin } from '@access/permissions'

const Cabinet = () => import('./views/Cabinet.vue')

export const BASE_PAGE_NAME = 'cabinet:page'
export const BASE_PAGE_URL = '/:company/cabinet/'

const redirect = isClient ? CLIENT_ORDERS_LIST_PAGE_NAME : isAdmin ? PARNTNERSHIP_APPS_LIST_PAGE_NAME : ORDERS_LIST_PAGE_NAME
// function routeInstaller(
//   View: any,
//   // View: () => Promise<typeof import("*.vue")>,
//   Installer: () => Promise<any>,
//   app: App<Element>
// ) {
//   return (): Promise<any> => Promise.all([View(), Installer()])
//     .then((result: [any, { default: Plugin }]): DefineComponent => {
//       const ViewComponent = result[0];
//       const installer = result[1];
//       app.use(installer.default);
//       app.component(ViewComponent.default.__name, ViewComponent.default);

//       return ViewComponent.default;
//     });
// }

export function createRoutes(): RouteRecordRaw[]  {
  return [
    route(BASE_PAGE_URL, Cabinet, BASE_PAGE_NAME, {
      children: [
        // prefixRoutes('/login/', createAuthRoutes()),
        prefixRoutes('tariffs/', createTariffsRoutes()),
        prefixRoutes('tariff/', createUserTariffRoutes()),
        prefixRoutes('settings/list/', createSettingsRoutes()),
        prefixRoutes('notifications/list/', createNotificationsRoutes()),
        // prefixRoutes('createNotificationsRoutes/', createSettingsRoutes()),
        prefixRoutes('statistics/', createStatisticsRoutes()),
        prefixRoutes('tariffs-statistics/', createTariffsStatisticsRoutes()),
        prefixRoutes('applications/', createApplicationsRoutes()),
        prefixRoutes('partnership-applications/', createPartnershipAppsRoutes()),
        prefixRoutes('my-applications/', createClientApplicationsRoutes()),
        prefixRoutes('invoice-templates/', createInvoicesRoutes()),
        prefixRoutes('profile/', createProfileRoutes()),
        prefixRoutes('storages/list/', createStoragesRoutes()),
        prefixRoutes('clients/list/', createClientsRoutes()),
        prefixRoutes('users/', createUsersRoutes()),
        prefixRoutes('companies/', createCompaniesRoutes()),
        prefixRoutes('orders/', createOrdersRoutes()),
        prefixRoutes('my-orders/', createClientOrdersRoutes()),
        prefixRoutes('transactions/list/', createTransactionsRoutes()),
        prefixRoutes('my-transactions/list/', createClientTransactionsRoutes()),
        prefixRoutes('access-is-denied/', createAccessDeniedRoutes()),
        prefixRoutes('not-found/', createPageNotFoundRoutes()),
        prefixRoutes('terms-and-conditions/', createTextPageRoutes()),
        // {
          //   path: '',
          //   redirect: (to: RouteLocationNormalized) => to.fullPath + 'design/',
          // },
        ],
        // meta: accessMeta(toBeAuthenticated, { name: 'auth:login' }, options),
        redirect: { name: redirect },
    }),
  ]
}