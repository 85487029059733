import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = {
  key: 0,
  class: "is-required u-sel-none"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.inputLabel)
    ? (_openBlock(), _createElementBlock("label", {
        key: 0,
        class: _normalizeClass(["d-control-input__label u-sel-none", {'d-control-input__label--active': $setup.isValue }]),
        for: $props.id
      }, [
        _createTextVNode(_toDisplayString($props.inputLabel), 1),
        ($props.isRequired)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, "*"))
          : _createCommentVNode("", true)
      ], 10, _hoisted_1))
    : _createCommentVNode("", true)
}