import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = {
  class: "tab-container",
  id: "tabs-container",
  ref: "tabContainer"
}
const _hoisted_2 = { class: "tab-headers" }
const _hoisted_3 = {
  class: "tab-headers__list",
  ref: 'tabHeadersList'
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "tab-headers__title" }
const _hoisted_6 = { class: "tab-headers__icon" }
const _hoisted_7 = { class: "tab-headers__text" }
const _hoisted_8 = {
  key: 0,
  class: "tab-container__mobile-title"
}
const _hoisted_9 = { class: "ds-caption ds-caption--bold" }
const _hoisted_10 = {
  class: "tab-container__active-tab",
  id: "active-tab"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.tabs, (tab, index) => {
          return (_openBlock(), _createElementBlock("li", {
            class: _normalizeClass(["tab-headers__list-element tab-header", $setup.activeTabIndex == index ? 'active' : '']),
            key: index,
            onClick: ($event: any) => ($setup.changeTab(index))
          }, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(tab.dataset.icon), { class: "button_img" }))
              ]),
              _createElementVNode("span", _hoisted_7, _toDisplayString(tab.dataset.title), 1)
            ])
          ], 10, _hoisted_4))
        }), 128))
      ])
    ]),
    ($setup.tabs)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, _toDisplayString($setup.tabs[$setup.activeTabIndex].dataset.title), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_10, [
      _renderSlot(_ctx.$slots, "default", {
        test: 777,
        change: $setup.changeTab
      })
    ])
  ], 512))
}