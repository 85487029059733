/* eslint-disable class-methods-use-this, no-unused-vars, prefer-spread, prefer-rest-params */

export default class Permission {
  /**
   * Permission checker function.
   */
  hasAccess() {
    return true;
  }

  /**
   * Promise resolvable checker.
   *
   * Same as `hasAccess` method, but returns a promise instead of bare
   * boolean. This done, because user will be available only after
   * fetching it from api. Or there might be some other asynchronous checker.
   */
  onHasAccess() {
    const args = arguments;

    return new Promise((resolve, reject) => (
      this.hasAccess.apply(this, args)
        ? resolve()
        : reject()
    ));
  }
}
