import type { Router } from 'vue-router'

import { defineStore } from '@/modules/pinia-hot'
import { storage } from '@/utils/session'
import {
  setColors
} from '@/utils/helpers'
import {
  LS_WAREHOUSE_ID_STORAGE_NAME,
  LS_WAREHOUSE_TYPE_STORAGE_NAME,
} from '@/const'
import {
  getCompanyDetail,
} from '@/services/company.service'

import executeWithRefresh from '@/modules/refresher'

const id = 'warehouse'

export const useWarehouse = defineStore(id, {
  state: () => ({
    warehouseId: storage.getItem(LS_WAREHOUSE_ID_STORAGE_NAME),
    warehouseType: storage.getItem(LS_WAREHOUSE_TYPE_STORAGE_NAME)
  }),
  getters: {
  },
  actions: {
    async setWarehouse(id, type) {
      console.log("🚀 ~ file: warehouse.ts:29 ~ setWarehouse ~ type:", type)
      this.warehouseId = id
      this.warehouseType = type
      storage.setItem(LS_WAREHOUSE_ID_STORAGE_NAME, id)
      storage.setItem(LS_WAREHOUSE_TYPE_STORAGE_NAME, type)
      console.log('WarehouseId -->', storage.getItem(LS_WAREHOUSE_ID_STORAGE_NAME))
      console.log('WarehouseType -->', storage.getItem(LS_WAREHOUSE_TYPE_STORAGE_NAME))
    },
  },
})
