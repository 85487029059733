import { defineStore } from '@/modules/pinia-hot'
import {
  profileDetailResource,
} from '@/services/profile.service'
import { User as BaseUser } from './base/user'
import executeWithRefresh from '@/modules/refresher'
/* eslint-disable */
const id = 'user'

export const useUserStore = defineStore(id, {
  state: () => ({
    user: new BaseUser({}, false),
    profile: '',
  }),
  getters: {
    getUserInfo: (state) => state.user,
  },
  actions: {
    async getUserData() {
      try {
        const { data: { item } } = (await executeWithRefresh(profileDetailResource))
        this.user = new BaseUser(item)
        this.profile = item
      } catch (e) {
      }
    },
  },
})