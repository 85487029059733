// import { flatPickr } from 'vue-flatpickr-component';
import { App } from 'vue'

import Controller from '@/components/control/ControlDescriptor.vue'
import InputLabel from './Label.vue'
import InputTextArea from './TextArea.vue'
import SimpleInput from './SimpleInput.vue'
import SimpleChecker from './SimpleChecker.vue'
import Multiselect from './Multiselect.vue'
import EmailInput from './EmailInput.vue'
import Image from './Image.vue'
import Autocomplete from './Autocomplete.vue'
import Flatpickr from './Flatpickr.vue'

const register = (app: App<Element>): void => {
  const prefix = 'DControl'
  app
    .component(`${prefix}Autocomplete`, Autocomplete)
    .component(`${prefix}Input`, Controller)
    .component(`${prefix}Label`, InputLabel)
    .component(`${prefix}Textarea`, InputTextArea)
    .component(`${prefix}Checker`, SimpleChecker)
    .component(`${prefix}SimpleInput`, SimpleInput)
    .component(`${prefix}Multiselect`, Multiselect)
    .component(`${prefix}EmailInput`, EmailInput)
    .component(`${prefix}Image`, Image)
    .component(`${prefix}Flatpickr`, Flatpickr)
}

export default {
  register,
}
