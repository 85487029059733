import {
  createUpdater,
  createReceiver,
  createSender
} from '@/resource/resource'

import {
  prefixAPI,
} from '@/resource/api'

const LINK_MODEL = 'send-payment-link'
const DEPOSIT_LINK_MODEL = 'send-prepayment-payment-link'
const CASH_MODEL = 'order-pay-by-cash'
const CARD_MODEL = 'order-pay-by-card'
const DEPOSIT_CASH_MODEL = 'order-prepay-by-cash'
const DEPOSIT_CARD_MODEL = 'order-prepay-by-card'
const TARIFF_CASH_MODEL = 'tariff-plan-pay-by-cash'
const TARIFF_CARD_MODEL = 'tariff-plan-pay-by-card'
const TARIFF_LINK_MODEL = 'tariff-plan-send-payment-link'

const METHODS_MODEL = 'payment-method'

const METHODS_LIST_LINK = prefixAPI('/list/{?client}', METHODS_MODEL)
const USER_METHODS_LIST_LINK = prefixAPI('/list/{?user}', METHODS_MODEL)
const METHOD_CREATE_LINK = prefixAPI('/create/', METHODS_MODEL)
const METHOD_DELETE_LINK = prefixAPI('/delete/', METHODS_MODEL)
const SET_DEFAULT_METHOD_LINK = prefixAPI('/add-default/', METHODS_MODEL)
const SEND_PAYMENT_LINK = prefixAPI('/', LINK_MODEL)
const SEND_DEPOSIT_PAYMENT_LINK = prefixAPI('/', DEPOSIT_LINK_MODEL)
const PAY_BY_CASH_LINK = prefixAPI('/', CASH_MODEL)
const PAY_BY_CARD_LINK = prefixAPI('/', CARD_MODEL)
const DEPOSIT_BY_CASH_LINK = prefixAPI('/', DEPOSIT_CASH_MODEL)
const DEPOSIT_BY_CARD_LINK = prefixAPI('/', DEPOSIT_CARD_MODEL)
const TARIFF_PAY_BY_CASH_LINK = prefixAPI('/', TARIFF_CASH_MODEL)
const TARIFF_PAY_BY_CARD_LINK = prefixAPI('/', TARIFF_CARD_MODEL)
const TARIFF_PAYMENT_LINK = prefixAPI('/', TARIFF_LINK_MODEL)

export const getMethodsListLink = createReceiver(METHODS_LIST_LINK)
export const getUserMethodsListLink = createReceiver(USER_METHODS_LIST_LINK)
export const createMethodLink = createSender(METHOD_CREATE_LINK)
export const setDefaultMethodLink = createSender(SET_DEFAULT_METHOD_LINK)
export const deleteMethodLink = createSender(METHOD_DELETE_LINK)
export const sendPaymentLink = createSender(SEND_PAYMENT_LINK)
export const sendDepositPaymentLink = createSender(SEND_DEPOSIT_PAYMENT_LINK)
export const payByCashLink = createSender(PAY_BY_CASH_LINK)
export const payByCardLink = createSender(PAY_BY_CARD_LINK)
export const depositByCashLink = createSender(DEPOSIT_BY_CASH_LINK)
export const depositByCardLink = createSender(DEPOSIT_BY_CARD_LINK)
export const tariffPayByCashLink = createSender(TARIFF_PAY_BY_CASH_LINK)
export const tariffPayByCardLink = createSender(TARIFF_PAY_BY_CARD_LINK)
export const tariffPaymentLink = createSender(TARIFF_PAYMENT_LINK)