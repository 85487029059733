import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "d-control-input d-control-input--appearance_bordered" }
const _hoisted_2 = {
  key: 0,
  class: "d-control-input__description"
}
const _hoisted_3 = ["type"]
const _hoisted_4 = {
  key: 2,
  class: "input-icon-element"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i_search = _resolveComponent("i-search")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.props.description)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($setup.props.description), 1))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("input", _mergeProps({ class: "d-control-input__element" }, _ctx.$attrs, {
      autocomplete: "off",
      type: $setup.inputType,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.val) = $event)),
      onInput: $setup.setValue
    }), null, 16, _hoisted_3), [
      [_vModelDynamic, $setup.val]
    ]),
    ($setup.isPasswordField)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "change-type pointer",
          onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.toggleType()))
        }, [
          (_openBlock(), _createBlock(_resolveDynamicComponent($setup.inputType === $setup.p ? 'IEye' : 'IEyeOff')))
        ]))
      : _createCommentVNode("", true),
    ($setup.inputType === $setup.s)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_i_search)
        ]))
      : _createCommentVNode("", true)
  ]))
}