import { App } from 'vue'
// import Swiper JS
import { Swiper, SwiperSlide } from 'swiper/vue'
// import Swiper styles
import 'swiper/css';



/* eslint-disable */
export default {
  install(app: App<Element>) {
    app.component("swiper", Swiper)
    app.component("swiperSlide", SwiperSlide)
  }
}
