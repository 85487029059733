import { PermissionsAccessState, PermissionsAvailable } from './lib';

function install(Vue, {
  stateName = PermissionsAccessState.name,
  availableName = PermissionsAvailable.name,
} = {}) {
  Vue.component(stateName, PermissionsAccessState);
  Vue.component(availableName, PermissionsAvailable);
}

export default { install };
export * from './lib';
