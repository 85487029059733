/* eslint-disable */
// import Confirm from '@app/UiElements/Confirm';
// import ModalBaseTemplate from '@app/UiElements/Modals/ModalTemplate.vue';
import * as QRCode from 'qrcode'
import is from 'ramda/src/is'
import executeWithRefresh from '@/modules/refresher'
import { receiveResource } from '../resource/resource'

import {
  getOptionsResource,
} from '@/services/options.service'

export function modalOpener({
  modal = ModalBaseTemplate,
  props = {},
  actor = () => { },
  value = undefined,
  classes = [],
  config = {},
}) {
  const promise = arg => new Promise((resolve, reject) => {
    if (arg) {
      resolve(arg);
      return;
    }
    reject();
  }).then(result => {
    actor(value || result);
  });

  this.$modal.show(
    modal,
    { ...props, promise }, {
      classes,
      ...config,
    }
  );
}

export function confirmWithPromise(question, $this) {
  return new Promise((res, rej) => {
    const pr = arg => new Promise((resolve, reject) => {
      if (true == arg) {
        resolve(arg)
      } else {
        reject(new Error('...'))
      }
    }).then(result => {
      res(result)
    }, err => {
      rej()
    })
    $this.$modal.show(
        Confirm,
        {
          question,
          promise: pr,
        }, {
          height: 'auto',
          width: '300px',
          adaptive: true,
        }
    )
  })
}

function invertColor(hexColor) {
  // Убедитесь, что переданный цвет в формате HEX
  hexColor = hexColor.replace(/^#/, '');

  // Преобразуйте HEX в RGB
  let r = parseInt(hexColor.substring(0, 2), 16);
  let g = parseInt(hexColor.substring(2, 4), 16);
  let b = parseInt(hexColor.substring(4, 6), 16);

  // Инвертируйте каждый компонент цвета
  r = 255 - r;
  g = 255 - g;
  b = 255 - b;

  // Преобразуйте обратно в HEX и верните результат
  return '#' + r.toString(16).padStart(2, '0') + g.toString(16).padStart(2, '0') + b.toString(16).padStart(2, '0');
}

export function setColors(buttonMain, buttonAdditional, sidebar) {
  const rootElement =  document.documentElement
  rootElement.style.setProperty('--theme-accent-main', buttonMain)
  // rootElement.style.setProperty('--theme-font-contrast', invertColor(buttonMain))
  // console.log("🚀 ~ file: helpers.js:88 ~ setColors ~ invertColor(buttonMain):", invertColor(buttonMain))
  rootElement.style.setProperty('--theme-primary-dark', buttonAdditional)
  rootElement.style.setProperty('--theme-sidebar-main', sidebar)
}

export function qrGenerator(objects, amount, clientData, originalNumber, orderId) {
  console.log("🚀 ~ qrGenerator ~ orderId:", orderId)
  console.log("🚀 ~ qrGenerator ~ order:", originalNumber)
  console.log("🚀 ~ qrGenerator ~ objects:", objects)
  console.log("🚀 ~ qrGenerator ~ amount:", amount)
  console.log("🚀 ~ qrGenerator ~ clientData:", clientData)
  const qrCodeOptions = {
    errorCorrectionLevel: 'M',
    type: 'svg',
    color: {
      dark: '#253E58',
      light: '#fff',
    },
    height: 800,
    width: 800
  };

  const qrUrlGetter = (target) => {
    const object = target?.orderObject.currentRentObject?.title || target?.orderObject.section?.title || null
    const qrData = {
      company: target?.company,
      order: target?.originalNumber,
    }
    console.log("🚀 ~ qrUrlGetter ~ target:", target)
    if(object) {
      qrData.warehouse = target?.warehouse,
      qrData.type = target?.orderObject?.type?.title,
      qrData.object = target?.orderObject.currentRentObject?.title || target?.orderObject.section?.title
    }
    const jsonObj = JSON.stringify(qrData)
    const img = { rsc: '' }
    const orderLink = `${window.location.protocol}//${window.location.host}/${userCompany}/cabinet/orders/view/${orderId}/`

    console.log("🚀 ~ qrUrlGetter ~ orderLink:", orderLink)
    QRCode.toDataURL(orderLink, qrCodeOptions, (err, url) => {
      img.src = url;
    })
    return img.src
  }
  const userCompany = window?.USER_COMPANY.replace(/\s+/g, "-")
  const printWindow = window.open('', '', 'width=635,height=800')
  printWindow.document.open()
  printWindow.document.write('<html><head><title>QR-codes</title></head><body>')
  printWindow.document.write('<div style="width: 816px; display: flex; flex-wrap: wrap">');
  console.log("🚀 ~ qrGenerator ~ originalNumber:", originalNumber)
  if(!amount) {
    objects.qr.forEach((element, index) => {
      const rightMargin = index % 2 != 1 ? 40 : 0
      printWindow.document.write(`<div style="margin-right: ${rightMargin}px; height: 192px; width: 384px; display: flex; align-items: center; margin-bottom: 24px">`);
      const qrCodeImg = `<img src="${qrUrlGetter(element)}"style="height: 172px; margin-right: 10px;">`
      const orderId = `<p style="color: #253E58; margin: 0"> Order ID: ${element?.order}`
      const client = `<p style="color: #253E58; margin: 0"> Client: ${`${clientData.firstName} ${clientData.lastName}`}`
      const phone = `<p style="color: #253E58; margin: 0"> Ph#: ${`${clientData.phone}`}`
      const pickupDate = `<p style="color: #253E58; margin: 0"> Date: ${originalNumber?.arrivalDate}`
      const warehouse = `<p style="color: #253E58; margin: 0"> Warehouse: ${element?.warehouse}`
      const objectType = `<p style="color: #253E58; margin: 0"> Space: ${element.orderObject.type.title}`
      const object = `<p style="color: #253E58; margin: 0"> Section: ${element?.orderObject?.currentRentObject?.title || element?.orderObject?.section?.title || ''}`
      printWindow.document.write(qrCodeImg)
      printWindow.document.write('<div>');
      printWindow.document.write(orderId);
      printWindow.document.write(client);
      printWindow.document.write(phone);
      printWindow.document.write(pickupDate);
      printWindow.document.write(warehouse);
      printWindow.document.write(objectType);
      printWindow.document.write(object);
      printWindow.document.write('</div>');
      printWindow.document.write('</div>');
    })
  } else {
    console.log("🚀 ~ qrUrlGetter ~ window.USER_COMPANY:", window.USER_COMPANY)
    const orderLink = `${window.location.protocol}//${window.location.host}/${userCompany}/cabinet/orders/view/${orderId}/`
    console.log("🚀 ~ qrGenerator ~ orderLink:", orderLink)
    const simpleQr = {
      company: objects.qr[0].company,
      client: `${clientData.firstName} ${clientData.lastName}`,
      phone: clientData.phone,
      // client: {
      //   firstName: clientData.firstName, 
      //   lastName: clientData.lastName, 
      //   email: clientData.email,
      //   phone: clientData.phone
      // },
      originalNumber: originalNumber
    }
    for (let i = 0; i < amount; i++) {
      const rightMargin = i % 2 != 1 ? 40 : 0
      printWindow.document.write(`<div style="margin-right: ${rightMargin}px; height: 192px; width: 384px; display: flex; align-items: center; margin-bottom: 24px">`);
      const img = { rsc: '' }
        QRCode.toDataURL(orderLink, qrCodeOptions, (err, url) => {
          img.src = url;
      })
      const qrCodeImg = `<img src="${img.src}"style="height: 172px; margin-right: 10px;">`
      printWindow.document.write(qrCodeImg)
      printWindow.document.write('<div>');
      const orderId = `<p style="color: #253E58; margin: 0"> Order ID: ${simpleQr?.originalNumber}`
      const client = `<p style="color: #253E58; margin: 0"> Client: ${simpleQr?.client}`
      const phone = `<p style="color: #253E58; margin: 0"> Ph#: ${simpleQr?.phone}`
      const warehouse = `<p style="color: #253E58; margin: 0"> Warehouse: ${objects?.warehouse?.title}`
      const pickupDate = `<p style="color: #253E58; margin: 0"> Date: ${objects?.pickupDate}`
      printWindow.document.write(orderId);
      printWindow.document.write(client);
      printWindow.document.write(phone);
      printWindow.document.write(warehouse);
      printWindow.document.write(pickupDate);
      printWindow.document.write('</div>');
      printWindow.document.write('</div>');
    }
  }
  printWindow.document.write('</div>');
  printWindow.document.write('</body></html>');
  printWindow.document.close();
  // printWindow.print();
  // printWindow.close();
}

export function createHashlink(linkId, targetId) {
  const links = [...document.getElementsByClassName(linkId)]
  const targetBlock = document.getElementById(targetId)
  const event = "click"
  if(links.length && targetBlock) {
    links.forEach(element => {
      element.addEventListener(event, () => {
        targetBlock.scrollIntoView({ behavior: 'smooth' })
      })
    });
  }
}

export function checkDescriptionBlockHeight () {
  const descriptionBlocks = document.getElementsByClassName('js-features-block')
  if(!descriptionBlocks.length) return
  const maxBlockHeight = 42
  for (var i = 0; i < descriptionBlocks.length; i++) {
    var element = descriptionBlocks[i]
    const description = element.querySelector('.js-tariff-description')
    const readMoreButton = element.querySelector('.js-tariff-more-block')
    if (description.offsetHeight > maxBlockHeight) {
      description.classList.add('line-clamped')
      readMoreButton.classList.remove('hidden')
    }
  }
}

export function changeAllArchorLinks () {
  const archorLinks = document.getElementsByClassName('archor-link')
  for (var i = 0; i < archorLinks.length; i++) {
    var anchor = archorLinks[i]
    anchor.addEventListener('click', function (e) {
      e.preventDefault();
      const targetId = this.getAttribute('href').substring(1);
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        window.scrollTo({
          top: targetElement.offsetTop,
          behavior: 'smooth'
        });
        // Update URL hash without jumping
        history.pushState(null, null, `#${targetId}`);
      }
    });

  }
}

export function addBlockWrapper (blockId, tagName, wrapperClass = 'wrapper', wrapperTag = 'div' ) {
  const targetBlock = document.getElementById(blockId)
  if(!targetBlock) return
  const elements = document.getElementsByTagName(tagName)
  const elementsArray = Array.from(elements)
  elementsArray.forEach(element => {
    const wrapper = document.createElement(wrapperTag);
    if (wrapperClass) {
      wrapper.classList.add(wrapperClass);
    }
    element.parentNode.insertBefore(wrapper, element);
    wrapper.appendChild(element);
  });
}

function snakeToCamel(str) {
  if (str && /_([a-z])/.test(str)) {
    return str.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
  }
  return str;
}

function camelToSnake(str) {
  if (str && /[A-Z]/.test(str)) {
    return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
  }
  return str;
}

const resource = receiveResource

export async function optionsGetterForAnonim(title, field, warehouse = null, option = null, company = null) {
  console.log("🚀 ~ optionsGetter ~ company:", company)
  const preparedData = { object_title: title, option: camelToSnake(option || field?.optionsType || field?.name), company: company || null}
  const additionalData = { warehouse }
  if('options' in field && !field.options.length) {
    try { const { data: { item } } = (await getOptionsResource.execute({...preparedData, ...additionalData}))
    field.options = item[snakeToCamel(option) || field?.optionsType || field?.name].choices
  } catch (e) {
    return Promise.reject(e)
  }
  }
}

export async function optionsGetter(title, field, warehouse = null, option = null, company = null) {
  console.log("🚀 ~ optionsGetter ~ company:", company)
  const preparedData = { object_title: title, option: camelToSnake(option || field?.optionsType || field?.name), company: company || null}
  const additionalData = { warehouse }
  if('options' in field && !field.options.length) {

  try { const { data: { item } } = (await executeWithRefresh(getOptionsResource, ({...preparedData, ...additionalData})))
    field.options = item[snakeToCamel(option) || field?.optionsType || field?.name].choices
  } catch (e) {
    return Promise.reject(e)
  }
  }
}

export function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

// export function toBase64(file) {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => resolve(reader.result);
//     reader.onerror = error => reject(error);
//   });
// }


/**
 * @param {string} path - name of key(
 *  f.e. Object is -{key: {child_key: 'test'}}, path - key, child_key
 * )
 */
export function pathGetter(path) {
  if (!Array.isArray(path)) {
    path = Array.from(arguments)
  }

  return item => path.reduce((acc, x) => (acc ? acc[x] : '-'), item)
}

export class Field {
  constructor(key, label = null, getter = null, parameters = {}) {
    if (null !== label && is(Function, label)) {
      label = null
      getter = label
      parameters = getter
    }

    if (null !== getter && !is(Function, getter)) {
      getter = null
      parameters = getter
    }

    this.key = key
    this.label = label || this.key.toTitle()
    this.getter = getter || pathGetter(this.key)
  }

  getValue(obj) {
    return this.getter(obj)
  }
}