/* eslint-disable */

import { App } from 'vue'

import {
  getCookie,
  setCookie,
} from '@/utils/cookies'

import {
  ACCESS_TOKEN_COOKIE, REFRESH_TOKEN_COOKIE, UNAUTHORIZED
} from '@/modules/refresher/const'

// import Plugin from './plugin'
// import { createClient, Client } from './client'
import { RetryerResource } from './retryer'


function getOptions() {
  return {
    headers: {
      'Accept-Language': window.language,
    }
  }
}

function getRetryer() {

  const accessToken: string | undefined = getCookie(ACCESS_TOKEN_COOKIE)
  const refreshToken: string | undefined = getCookie(REFRESH_TOKEN_COOKIE)
  
  const retryer = new RetryerResource(accessToken,
    {
      [accessToken || 0]: refreshToken,
    },
  ) 
  // console.log(retryer, 'RETRIER');
  return retryer
}

export const options = getOptions()
export const $retryer = getRetryer()

// export const $fetchClient = async () => await createClient(options)

export default async function definePlugin(app: App) {
  // const client = f
  app.provide('$retryer', $retryer)
  // app.provide('$fetchClient', client)

  // app.config.globalProperties.$fetchClient = client
  app.config.globalProperties.$retryer = $retryer

  // app.use(Plugin, { $fetchClient })

}


// declare module '#app' {
//   interface App {
//     fetchClient: Client
//   }
// }
