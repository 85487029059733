import {
  createSender,
  createOptionsGetter,
  createReceiver,
  createUpdater,
  createRemover,
} from '@/resource/resource'

import {
  prefixAPI,
} from '@/resource/api'

const MODEL = 'tariff-plan'
const TRANSACTIONS_MODEL = 'tariff-plan-transaction'

const TARIFF_CREATE_LINK = prefixAPI('/create/', MODEL)
const TARIFF_DETAILS_LINK = prefixAPI('/{id}/', MODEL)
const TARIFF_UPDATE_LINK = prefixAPI('/update/{id}/', MODEL)
const TARIFF_DELETE_LINK = prefixAPI('/delete/{id}/', MODEL)
const TARIFFS_LIST_LINK = prefixAPI('/list/{?limit,offset}', MODEL)
const TARIFFS_ACTIVE_LIST_LINK = prefixAPI('/list-active/{?limit,offset}', MODEL)

const TARIFFS_TRANSACTIONS_LIST_LINK = prefixAPI('/list/{?limit,offset,company,status,created_at_after,created_at_before}', TRANSACTIONS_MODEL)

export const tariffCreateResource = createSender(TARIFF_CREATE_LINK)
export const tariffUpdateResource = createUpdater(TARIFF_UPDATE_LINK)
export const tariffDeleteResource = createRemover(TARIFF_DELETE_LINK)
export const tariffsListResource = createReceiver(TARIFFS_LIST_LINK)
export const tariffsActiveListResource = createReceiver(TARIFFS_ACTIVE_LIST_LINK)
export const tariffsDetailsResource = createReceiver(TARIFF_DETAILS_LINK)
export const tariffOptionsResource = createOptionsGetter(TARIFF_CREATE_LINK)
export const tariffsListOptionsResource = createOptionsGetter(TARIFFS_LIST_LINK)

export const tariffTransactionsListResource = createReceiver(TARIFFS_TRANSACTIONS_LIST_LINK)