import { App } from 'vue'
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';

/* eslint-disable */
export default {
  install(app: App<Element>) {
    app.component("DatePicker", DatePicker)
  }
}
