<script>
import { ref, defineComponent } from 'vue'

const ACTIVE_ICON = 'i-minus'
const PASSIVE_ICON = 'i-plus'


export default defineComponent({
  name: 'UiAccordion',
  props: {
    init: {
      default: () => false,
    },
  },
  setup(props, { slots }) {
    const activeState = ref(props.init)
    const icon = ref(PASSIVE_ICON)
    const elementRef = ref(null);

    function setToggleActive() {
      activeState.value = !activeState.value
      icon.value = activeState.value ? ACTIVE_ICON : PASSIVE_ICON
    }
    
    return () => slots.default({
      activeState: activeState.value,
      toggleActive: setToggleActive,
      icon: icon.value,
      collapse: () => {
        activeState.value = false
        icon.value = PASSIVE_ICON
      },
    })
  },
})
</script>