import { App } from 'vue'

import AuthProvider from './AuthProvider.vue'

export default {
  install(app: App<Element>): void {
    app
      .component('AuthProvider', AuthProvider)
  }
}
