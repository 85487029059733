import { App } from 'vue'
import MainButton from './MainButton.vue'
import MainToggler from './MainToggler.vue'
// import UiKit from './UiKit.vue'
import Sidebar from './Sidebar.vue'
import MainHeader from './MainHeader.vue'
import ActionBar from './ActionBar.vue'
import StatusBar from './StatusBar.vue'
import UserBar from './UserBar.vue'
import NotifyBar from './NotifyBar.vue'
import StorageBar from './StorageBar.vue'
import Breadcrumbs from './Breadcrumbs.vue'
import Checker from './Checker.vue'
import InfoTooltip from './InfoTooltip.vue'
import SimpleOutput from './SimpleOutput.vue'
import CopyToClipBoard from './CopyToClipBoard.vue'
import PresentationsSlider from './PresentationsSlider.vue'
import ReviewsSlider from './ReviewsSlider.vue'

const register = (app: App<Element>): void => {
  app
  .component('ReviewsSlider', ReviewsSlider)
  .component('PresentationsSlider', PresentationsSlider)
  .component('CopyToClipBoard', CopyToClipBoard)
  .component('InfoTooltip', InfoTooltip)
  .component('SimpleOutput', SimpleOutput)
  .component('Checker', Checker)
  .component('Breadcrumbs', Breadcrumbs)
  .component('MainButton', MainButton)
  .component('MainToggler', MainToggler)
  // .component('UiKit', UiKit)
  .component('Sidebar', Sidebar)
  .component('ActionBar', ActionBar)
  .component('StatusBar', StatusBar)
  .component('MainHeader', MainHeader)
  .component('UserBar', UserBar)
  .component('NotifyBar', NotifyBar)
  .component('StorageBar', StorageBar)
}

export default {
  register,
}
