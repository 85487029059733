import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "ls-status-bar-wrapper" }
const _hoisted_2 = { class: "g-row g-row--space_xl g-row--apperance_spaced g-row--align_center g-row--appearance_nowrap-xl g-row--justify_center" }
const _hoisted_3 = { class: "g-cell g-cols g-cols--12 g-cols--narrow-xl" }
const _hoisted_4 = { class: "ls-status-bar-title" }
const _hoisted_5 = { class: "ds-caption ds-caption--size_xs ds-caption--bold" }
const _hoisted_6 = { class: "g-cell g-cols g-cols--12 g-cols--auto-xl g-cols--overflow_auto custom-width" }
const _hoisted_7 = { class: "g-row g-row--space_xl g-row--apperance_spaced g-row--align_center g-row--appearance_nowrap g-row--full_width" }
const _hoisted_8 = { class: "g-cell g-cols g-cols--narrow" }
const _hoisted_9 = ["onClick", "id"]
const _hoisted_10 = { class: "ls-status-bar-element__icon" }
const _hoisted_11 = {
  width: "10",
  height: "10",
  viewBox: "0 0 10 10",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_12 = ["stroke"]
const _hoisted_13 = { class: "ls-status-bar-element__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("p", _hoisted_5, _toDisplayString($props.title), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _renderSlot(_ctx.$slots, "default", { test: _ctx.test }),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.props.objects, (item) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("div", {
                class: _normalizeClass(["ls-status-bar-element", $setup.selected == item.id || $setup.selected == item.value? 'active' : '']),
                onClick: ($event: any) => ($setup.setStatus(item.id || item.value)),
                id: item.id || item.value
              }, [
                _createElementVNode("div", _hoisted_10, [
                  (_openBlock(), _createElementBlock("svg", _hoisted_11, [
                    _createElementVNode("circle", {
                      cx: "5",
                      cy: "5",
                      r: "3.5",
                      stroke: item.color,
                      "stroke-width": "3"
                    }, null, 8, _hoisted_12)
                  ]))
                ]),
                _createElementVNode("div", _hoisted_13, _toDisplayString(item.title || item.label), 1)
              ], 10, _hoisted_9)
            ]))
          }), 256))
        ])
      ])
    ])
  ]))
}