import {
  createSender,
  createRemover,
  createReceiver,
  createUpdater,
} from '@/resource/resource'

import {
  prefixAPI,
} from '@/resource/api'

const MODEL = 'notifications/notifications'

const NOTIFICATIONS_COUNT_LINK = prefixAPI('/stats/', MODEL)
const NOTIFICATIONS_LIST_LINK = prefixAPI('/list/{?limit,offset}', MODEL)
const NOTIFICATIONS_FLAGS_LIST_LINK = prefixAPI('/flags/list/', MODEL)
const NOTIFICATIONS_CHANGE_FLAG_LINK = prefixAPI('/change-flags/{?ids}', MODEL)

export const notificationsListResource = createReceiver(NOTIFICATIONS_LIST_LINK)
export const notificationsCountResource = createReceiver(NOTIFICATIONS_COUNT_LINK)
export const notificationFlagsListResource = createReceiver(NOTIFICATIONS_FLAGS_LIST_LINK)
export const notificationChangeFlagResource = createSender(NOTIFICATIONS_CHANGE_FLAG_LINK)

