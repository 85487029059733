import { RouteRecordRaw } from 'vue-router';
import { route } from '@/router/utils'
import { allPermissions } from '@access/index'
import { accessPermission } from '@access/permissions'

const tariffDetail = () => import('./views/details/Details.vue')
// const rentUnitEdit = () => import('./views/RentUnitEdit.vue')
// const storageFeeEdit = () => import('./views/StorageFeeEdit.vue')

// NAMES
export const USER_TARIFF_PAGE_NAME = 'cabinet:user-tariff-details'

// URLs
export const USER_TARIFF_PAGE_URL = ''
// export const PARNTNERSHIP_APP_CREATE_PAGE_URL = 'create/'
// export const PARNTNERSHIP_APP_UPDATE_PAGE_URL = 'edit/:id?/'

const {
  STAFF: {
    TARIFF_PLAN: {
      VIEW_LIST_ACTIVE,
    },
  },
} = allPermissions

export function createRoutes(): RouteRecordRaw[] {
  return [
    route(USER_TARIFF_PAGE_URL, tariffDetail, USER_TARIFF_PAGE_NAME, {
      meta: {
        breadcrumb: 'user-tariffs',
        // redirect: TARIFFS_LIST_PAGE_NAME,
        ...accessPermission(VIEW_LIST_ACTIVE)
      },
    }),
  ]
}
