/* eslint-disable max-classes-per-file, prefer-spread, prefer-rest-params */

import Permission from './Permission';

export class AllowAny extends Permission { }

export class PermissionsCollection extends Permission {
  constructor() {
    super();

    this.collection = Array.from(arguments);
  }

  executeCollection(args) {
    return this.collection.map(
      permission => permission.onHasAccess.apply(permission, args)
    );
  }
}

export class Or extends PermissionsCollection {
  onHasAccess() {
    return Promise.any(this.executeCollection(arguments));
  }
}

export class And extends PermissionsCollection {
  onHasAccess() {
    return Promise.all(this.executeCollection(arguments));
  }
}
