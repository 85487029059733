/* eslint-disable import/prefer-default-export, prefer-spread */

/**
 * Multiple permissions checker.
 *
 * @export
 * @param {any} permissions - List of permissions.
 * @returns {Promise} - It will be resolved only if all permissions will
 * be successful.
 */
export function hasAccess(permissions, ...args) {
  return Promise.all(permissions.map(x => x.onHasAccess.apply(x, args)));
}
