import { RouteRecordRaw } from 'vue-router';
import { route } from '@/router/utils'
import { allPermissions } from '@access/index'
import { accessPermission } from '@access/permissions'

const companiesList = () => import('./views/List.vue')
const companyDetail = () => import('./views/details/Details.vue')
const companyCreate = () => import('./views/Create.vue')


// NAMES
export const COMPANIES_LIST_PAGE_NAME = 'cabinet:companies-list'
export const COMPANY_CREATE_PAGE_NAME = 'cabinet:company-create'
export const COMPANY_UPDATE_PAGE_NAME = 'cabinet:company-update'
export const COMPANY_VIEW_PAGE_NAME = 'cabinet:company-detail'

// URLs
export const COMPANY_UPDATE_PAGE_URL = 'edit/:id/'
export const COMPANIES_LIST_PAGE_URL = 'list/'
export const COMPANY_CREATE_PAGE_URL = 'create/'
export const COMPANY_VIEW_PAGE_URL = 'view/:id/'

const {
  STAFF: {
    COMPANY: {
      VIEW,
      CREATE,
      VIEW_LIST,
      EDIT,
      VIEW_PERSONAL,
    },
  },
} = allPermissions

export function createRoutes(): RouteRecordRaw[] {
  return [
    route(COMPANIES_LIST_PAGE_URL, companiesList, COMPANIES_LIST_PAGE_NAME, {
      meta: {
        breadcrumb: 'companies',
        ...accessPermission(VIEW_LIST)
      }
    }),
    route(COMPANY_CREATE_PAGE_URL, companyCreate, COMPANY_CREATE_PAGE_NAME, {
      meta: {
        editable: true,
        isNew: true,
        breadcrumb: 'company-create',
        ...accessPermission(CREATE)
      },
    }),
    route(COMPANY_UPDATE_PAGE_URL, companyCreate, COMPANY_UPDATE_PAGE_NAME, {
      meta: {
        editable: true,
        isNew: false,
        breadcrumb: 'company-update',
        ...accessPermission(EDIT)
      },
    }),
    route(COMPANY_VIEW_PAGE_URL, companyDetail, COMPANY_VIEW_PAGE_NAME, {
      meta: {
        breadcrumb: 'companies',
        redirect: COMPANIES_LIST_PAGE_NAME,
        ...accessPermission(VIEW)
      },
    }),
  ]
}
