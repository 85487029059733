<script>
import { ref, onMounted, onBeforeUnmount, defineComponent } from 'vue'
import { getCookie } from '@utils/cookies'
import { HIJACK_LINK, hiJackResource } from '@/services/hijack.service'
import { PROFILE_PAGE_NAME } from '@app/pages/cabinet/routes'
import { modalOpener } from '@utils/helpers'
import { useRoute, useRouter } from 'vue-router'
import useGlobal from '@/composables/useGlobal'
import { useConfirm } from '@/composables/useConfirm'
import executeWithRefresh from '@/modules/refresher'
export default defineComponent({
  name: 'HiJack',
  
  setup(props, { slots }) {
    const { $t } = useGlobal()
    const router = useRouter()
    const route = useRoute()
    const question = $t(' Вы уверены?')

    const loginAsAnotherUser = async (id) => {
      const formdata = new FormData()
      const nextRoute = { name: PROFILE_PAGE_NAME }
      const next = router.resolve(nextRoute).href
      // const sendData = {
      //   user_pk: id,
      //   csrfmiddlewaretoken: getCookie('csrftoken'),
      //   next: next,
      // }
      formdata.append('user_pk', id)
      formdata.append('csrfmiddlewaretoken', getCookie('csrftoken'))
      formdata.append('next', next)
      const params = {
        method: 'POST',
        body: formdata,
      }
      try {
        await useConfirm(question)
        // const { data } = (await executeWithRefresh( hiJackResource, { id: id }, {
        //   ...sendData,
        // }))
        // console.log("🚀 ~ awaitfetch ~ e:", data)
        await fetch(`/hijack/${id}/`, params).then((e) => {
          console.log("🚀 ~ awaitfetch ~ e:", e)
          window.location = e.url
        })
      } catch (e) {
        return Promise.reject(e)
      }
    }

    return () => slots.default({ loginAsAnotherUser })
  },
})
</script>