import {
  refreshToken,
} from '@/services/auth.service'
import useAuthToken from '@/composables/useAuthToken'
import {
  getCookie,
  setCookie,
} from '@/utils/cookies'
import {
  LS_REFRESH_TOKEN_STORAGE_NAME,
  LS_ACCESS_TOKEN_STORAGE_NAME,
  LS_IS_AUTH_STORAGE_NAME,
} from '@/const'

import  { $retryer } from '.'

import useLogout from '@/composables/useLogout'

const UNAUTHORIZED = 401

export async function executeWithRefresh(resource: any, params?:Record<string, unknown>, payload?:Record<string, unknown>,): Promise<any> {
  const resourceWithMiddleware = (await useAuthToken(resource, $retryer.currentAccess))
  try {
    const result = (await resourceWithMiddleware.execute(params, payload || null))
    return result
  } catch (e: any) {
    if (e.status === UNAUTHORIZED) {
      try {
        await $retryer.retry()
        return executeWithRefresh(resource, params, payload)
      } catch (error) {
        // check redirect to login page!
        await useLogout()
      }
    }
    return Promise.reject(e)
  }
}
