import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ds-caption ds-caption--size_3xs ds-caption--color_blue ds-caption--appearance_center"
}
const _hoisted_2 = {
  key: 1,
  class: "ds-caption ds-caption--size_3xs ds-caption--color_blue ds-caption--appearance_center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["copy-wrapper", $props.text ? '' : 'copy-wrapper--disabled']),
    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => ($setup.copyToClipboard($props.text)), ["prevent"]))
  }, [
    ($setup.showMessage)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["copy-wrapper__message", $props.centered ? 'copy-wrapper__message--centered' : ''])
        }, [
          ($props.text)
            ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString($setup.$t('Copied')), 1))
            : (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString($setup.$t('Nothing to copy')), 1))
        ], 2))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}