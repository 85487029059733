import {loadStripe} from '@stripe/stripe-js';

export const publicKey = 'stripe_public_key';

export default class StripePayment {
  private summ: number;
  private stripeKey: string;
  private clientSecret: string;
  private stripe: any;
  private paymentRequest: any;
  private elements: any;
  private onLoad: any;

  constructor(key: string, clientSecret: string) {
    this.summ = 1;
    this.stripeKey = key;
    this.clientSecret = clientSecret;
    this.stripe = null;
    this.paymentRequest = null;
  }

  loadScript(promise): void {
    this.onLoad = promise
    const script: HTMLScriptElement = document.createElement('script');
    script.id = '_stripe-checkout-script';
    script.src = 'https://js.stripe.com/v3/';
    script.onload = this.setCheckout.bind(this);
    document.querySelector('head').append(script);
  }

/* eslint-disable */
  async setCheckout(): Promise<void> {
    console.log(this);
    this.stripe = await loadStripe(this.stripeKey, {
      apiVersion: '2022-08-01',
    });
    this.paymentRequest = this.stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: 'Demo total',
        amount: Number(this.summ),
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });
    await this.createElements();
  }
/* eslint-enable */
  async createElements(): Promise<void> {
    this.elements = this.stripe.elements({ clientSecret: this.clientSecret, locale: 'en' });
    const paymentElement = this.elements.create('payment');

    await paymentElement.mount('#payment-element');
    this.onLoad('loaded')
  }

  async complete(action: any): Promise<void> {
    const form = document.getElementById('payment-form');
    form.classList.add('form-loader');
    const { error, paymentIntent } = await this.stripe.confirmPayment({
      elements: this.elements,
      confirmParams: {
        return_url: window.location.href,
      },
      redirect: 'if_required',
    });
    if (error) {
      form.classList.remove('form-loader');
      const messageContainer: HTMLElement = document.querySelector('#error-message');
      messageContainer.textContent = error.message;
    } else {
      action(true, paymentIntent);
    }
  }
}