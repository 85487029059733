import {
  createSender,
  createOptionsGetter,
  createReceiver,
  createUpdater,
  createRemover,
} from '@/resource/resource'

import {
  prefixAPI,
} from '@/resource/api'

const MODEL = 'fee'

const FEE_CREATE_LINK = prefixAPI('/create/', MODEL)
const FEE_VIEW_LINK = prefixAPI('/{id}/', MODEL)
const FEE_UPDATE_LINK = prefixAPI('/update/{id}/', MODEL)
const FEE_DELETE_LINK = prefixAPI('/delete/{id}/', MODEL)
const FEE_LIST_LINK = prefixAPI('/list/{?limit,offset,id}', MODEL)

export const feeCreateResource = createSender(FEE_CREATE_LINK)
export const feeUpdateResource = createUpdater(FEE_UPDATE_LINK)
export const feeDeleteResource = createRemover(FEE_DELETE_LINK)
export const feeListResource = createReceiver(FEE_LIST_LINK)
export const feeDetailsResource = createReceiver(FEE_VIEW_LINK)
export const feeOptionsResource = createOptionsGetter(FEE_CREATE_LINK)
