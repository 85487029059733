// import { Response } from './types';
/* eslint-disable */
import {
  getCookie,
  setCookie,
} from '@/utils/cookies'
import {
  ACCESS_TOKEN_COOKIE, REFRESH_TOKEN_COOKIE, BAD_REQUEST, UNAUTHORIZED
} from '@/modules/refresher/const'

// import type { Response } from './types'


interface ResponseForRefresh {
  access: string,
  refresh?: string,
  code: number,
}

const ERROR_CODES = [UNAUTHORIZED, BAD_REQUEST]

export class RetryerResource {
  tokenMap: Record<string, string>;
  currentAccess: string|undefined;
  pending: boolean;
  currentPromise: Promise<string|void> | null;

  constructor(currentAccess: string|undefined, tokenMap = {},) {
    this.tokenMap = tokenMap
    this.currentAccess = currentAccess
    this.pending = false
    this.currentPromise = null
  }
  init(currentAccess: string, tokenMap = {}, absoluteUrl: string) {
    this.tokenMap = tokenMap
    this.currentAccess = currentAccess
    this.pending = false
    this.currentPromise = null
  }
  resolveToken(token: string) {
    const refresh: string = this.tokenMap[token]
    if (!refresh) return Promise.reject('No refresh token')

    if (!this.pending) {

      this.pending = true
      this.currentPromise = fetch(`/api/v1/auth/token/refresh/`, {
        body: JSON.stringify({ refresh, test: true }),
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'POST',
      })
        .then(async (response: Response) => {
          const data: ResponseForRefresh = (await response.json())
          if (ERROR_CODES.includes(data.code)) {
            this.pending = true
            return Promise.reject(data)
          }
          this.currentAccess = data.access
          this.tokenMap[this.currentAccess] = data.refresh || refresh
          this.pending = false
          this.setCookies()
          return this.currentAccess
        }, err => {
          this.pending = true
          return Promise.reject(err)
        })
      this.pending = true
    }

    return this.currentPromise?.then((token: string|unknown) => token, e => Promise.reject(e))
  }
  setCookies() {
    setCookie(ACCESS_TOKEN_COOKIE, this.currentAccess)
    setCookie(REFRESH_TOKEN_COOKIE, this.tokenMap[this.currentAccess || 0])
  }
  call(caller: any) {
    return caller(this.currentAccess)
      .then((response: Response) => {
        // if (response.code === UNAUTHORIZED) {
        //   return this.resolveToken(this.currentAccess)
        //     .then((token: string) => caller(token))
        // }
        return response
      })
  }
  retry() {
    return this.resolveToken(this.currentAccess)
  }
}
