import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ls-form ls-form--space_none ls-form--modal" }
const _hoisted_2 = { class: "ls-form__title ls-form__title--space-bottom_sm" }
const _hoisted_3 = { class: "ds-caption ds-caption--size_lg ds-caption--medium" }
const _hoisted_4 = { class: "ls-form__wrapper" }
const _hoisted_5 = { class: "ds-table-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_table = _resolveComponent("ui-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString($setup.$t('Список объектов аренды')), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        ($setup.props.objects)
          ? (_openBlock(), _createBlock(_component_ui_table, {
              key: 0,
              class: "ds-table--width_full",
              "table-fields": $setup.rentObjectsTableFields,
              items: $setup.props.objects
            }, {
              "table-body-cell-title": _withCtx(({ item }) => [
                _createElementVNode("span", null, _toDisplayString(item?.type?.title || '-'), 1)
              ]),
              "table-body-cell-rent_object": _withCtx(({ item }) => [
                _createElementVNode("span", null, _toDisplayString(item?.rentObject?.title || item?.section?.title || '-'), 1)
              ]),
              _: 1
            }, 8, ["items"]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}