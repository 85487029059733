import { App } from 'vue'
import RouteAccess from './RouteAccess.vue'

const register = (app: App<Element>): void => {
  app
  .component('RouteAccess', RouteAccess)
}

export default {
  register,
}
